import { Component, HostListener, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { CategoryModel } from 'src/app/models/pattern/category-model';
import { HttpService } from 'src/app/services/http-service';
import { Utility } from 'src/app/helpers/utility';
import {
  AddEditPatternModal,
  AddEditPatternModalMode,
} from '../add-edit-pattern-modal/add-edit-pattern-modal';
import { ConfirmModalData } from '../confirm-modal/confirm-modal';
import { PatternModel } from 'src/app/models/pattern/pattern-model';
import { DOCUMENT } from '@angular/common';

export interface PatternExplorerModalData {
  mode: PatternExplorerModalMode;
}

@Component({
  selector: 'pattern-explorer-modal',
  templateUrl: './pattern-explorer-modal.html',
  styles: ['./pattern-explorer-modal.css'],
})
export class PatternExplorerModal implements OnInit {
  loading: boolean;
  searchFilter: string;

  categories: CategoryModel[];
  selectedCategory: CategoryModel;

  patterns: PatternModel[];
  selectedPattern: PatternModel;

  windowWidth: number;
  windowHeight: number;

  modalHeight: number;

  constructor(
    public httpService: HttpService,
    @Inject(DOCUMENT) private document: Document,
    public dialogRef: MatDialogRef<PatternExplorerModal>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: PatternExplorerModalData
  ) {}

  async ngOnInit() {
    this.loading = true;

    try {
      this.refreshCategories();
      this.selectedCategory = this.httpService.getAllPatternsCategory();
      await this.onCategorySelected(this.selectedCategory);
      this.resize();
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }

    gtag('event', 'page_view', {
      page_title: 'Pattern Explorer (Modal)',
      page_location: `${window.location.origin}/pattern-explorer-modal`,
    });
  }

  resize() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;

    this.modalHeight = this.windowHeight * 0.9;

    if (this.modalHeight > 700) {
      this.modalHeight = 700;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resize();
  }

  public async refreshCategories(): Promise<void> {
    this.categories = [];
    this.categories.push(this.httpService.getAllPatternsCategory());
    this.categories.push(...(await this.httpService.getCategories()));
  }

  onPatternDoubleClick() {
    this.dialogRef.close(this.selectedPattern);
  }

  async onSearchDelayedChange(value: string): Promise<void> {
    await this.refreshPatterns();
  }

  async onEditPattern(pattern: PatternModel) {
    const dialogRef = this.dialog.open(AddEditPatternModal, {
      data: {
        mode: AddEditPatternModalMode.Edit,
        pattern: pattern,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      let pattern = <PatternModel>result;

      if (pattern) {
        console.log(pattern);
        let index = this.patterns.findIndex(
          (x) => x.patternId == pattern.patternId
        );
        this.patterns[index] = pattern;
        await this.httpService.updatePattern(pattern);
        await this.refreshCategories();
      }
    });
  }

  async onDeletePattern(pattern: PatternModel) {
    var deletePatternCallback = async (
      data: ConfirmModalData
    ): Promise<void> => {
      await this.httpService.deletePattern(pattern);
      this.refreshPatterns();
    };

    Utility.confirmMessage(
      this.dialog,
      this.httpService.getTransation().DeletePattern,
      Utility.formatString(
        this.httpService.getTransation().ConfirmDelete,
        pattern.name
      ),
      deletePatternCallback
    );
  }

  async onCategorySelected(category: CategoryModel) {
    // console.log(category);
    await this.refreshPatterns();
  }

  async refreshPatterns() {
    let categoryId = this.selectedCategory
      ? this.selectedCategory.categoryId
      : 0;
    this.patterns = await this.httpService.getPatterns(
      categoryId,
      this.searchFilter
    );
    // console.log(this.patterns);
  }

  getTitle() {
    if (this.data.mode == PatternExplorerModalMode.Open)
      return this.httpService.getTransation().OpenPattern;
    else return this.httpService.getTransation().SavePattern;
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}

export enum PatternExplorerModalMode {
  Open,
  Save,
}

import {
  Component,
  Inject,
  EventEmitter,
  Output,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';
import { PaletteDetailModel } from '../models/palettes/palette_detail_model';
import { Utility } from '../helpers/utility';
import { HttpService } from '../services/http-service';
import { PaletteModel } from '../models/palettes/palette_model';

@Component({
  selector: 'app-palettes-list',
  templateUrl: './palettes-list.component.html',
  styleUrls: ['./palettes-list.component.css'],
})
export class PalettesListComponent {
  @ViewChild('paletteList', { static: true })
  private paletteList: ElementRef<HTMLCanvasElement>;

  formatString = Utility.formatString;

  @Input()
  includePadding: boolean;
  @Input()
  height: number;

  @Input()
  palettesListType: PalettesListTypeEnum = PalettesListTypeEnum.SingleSelection;
  @Input()
  includeBuiltIn: boolean;
  @Input()
  includeCustom: boolean;
  @Input()
  paletteName: string = '';
  @Input()
  paletteToExclude: PaletteModel;

  @Output()
  selectedPaletteDetailChanged: EventEmitter<PaletteDetailModel> =
    new EventEmitter<PaletteDetailModel>();
  @Output()
  onAdd: EventEmitter<PaletteDetailModel> =
    new EventEmitter<PaletteDetailModel>();
  @Output()
  onRemove: EventEmitter<PaletteDetailModel> =
    new EventEmitter<PaletteDetailModel>();
  @Output()
  onPaletteNameChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  onPaletteDetailsLoaded: EventEmitter<void> = new EventEmitter<void>();

  palettes: PaletteModel[];
  selectedPalette: PaletteModel;
  paletteDetails: PaletteDetailModel[];
  detailsFilter: string = '';
  detailsPageSize: number = 50;
  selectedPaletteDetail: PaletteDetailModel;
  morePaletteDetails: boolean = false;
  palettesListTypes = PalettesListTypeEnum;

  constructor(
    public httpService: HttpService,
    @Inject('BASE_URL') private baseUrl: string
  ) {}

  refreshPalettes(palettes: PaletteModel[]) {
    if (palettes) {
      this.palettes = Utility.getPalettesList(
        palettes,
        this.includeBuiltIn,
        this.includeCustom
      );
      
      if (this.palettesListType != PalettesListTypeEnum.Edit) {
        if (this.palettes.length > 0) {
          this.selectedPalette = this.palettes[0];
          this.refreshPaletteDetails();
        }
      }
    }
  }

  async ngOnInit() {
    this.refreshPalettes(this.httpService.palettes);
    this.httpService.onPalettesRefreshed.subscribe((palettes) => {
      this.refreshPalettes(palettes);
    });

    this.resize();
  }

  private resize() {
    this.paletteList.nativeElement.style.height = this.height + 'px';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resize();
  }

  getBeadClasses(paletteTypeId: number): string {
    return Utility.getBeadClasses(paletteTypeId);
  }

  onChange() {
    this.selectedPaletteDetailChanged.emit(this.selectedPaletteDetail);
  }

  onAddPaletteDetail(paletteDetail: PaletteDetailModel): void {
    this.onAdd.emit(paletteDetail);
  }

  onRemovePaletteDetail(paletteDetail: PaletteDetailModel): void {
    this.onRemove.emit(paletteDetail);
  }

  onPaletteSelected(palette: PaletteModel): void {
    this.refreshPaletteDetails();
  }

  async onPaletteNameDelayedChange(value: string): Promise<void> {
    this.onPaletteNameChanged.emit(value);
  }

  async onDelayedChange(value: string): Promise<void> {
    await this.refreshPaletteDetails();
  }

  async refreshPaletteDetails(): Promise<void> {
    if (this.selectedPalette) {
      let paletteIdToExclude = 0;

      if (this.paletteToExclude)
        paletteIdToExclude = this.paletteToExclude.paletteId;

      // console.log(this.detailsFilter);
      let tempPaletteDetails = await this.httpService.getPaletteDetails(
        this.selectedPalette.paletteId,
        this.detailsFilter,
        this.detailsPageSize + 1,
        paletteIdToExclude
      );
      this.morePaletteDetails =
        tempPaletteDetails.length > this.detailsPageSize;

      if (tempPaletteDetails.length > this.detailsPageSize)
        tempPaletteDetails.pop();

      this.paletteDetails = tempPaletteDetails;

      if (this.onPaletteDetailsLoaded) {
        this.onPaletteDetailsLoaded.emit();
      }
    } else {
      this.paletteDetails = [];
    }
  }
}

export enum PalettesListTypeEnum {
  SingleSelection,
  AddItemSelection,
  Edit,
}

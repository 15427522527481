import { PaletteDetailModel } from "../palettes/palette_detail_model";
import { PatternBGImage } from "./pattern-bg-image";

export class PatternBead {
  stitchId: number;
  posX: number;
  posY: number;
  grid: PaletteDetailModel[][];
  beadWidth: number;
  beadHeight: number;
  image: PatternBGImage;
  rotation: number;
}

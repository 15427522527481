<div style="display: none">
  <img #beadEmptyImg src="assets/images/bead-empty.png" />
  <img #beadMetallicImg src="assets/images/bead-metallic.png" />
  <img #beadUnknownImg src="assets/images/bead-matte.png" />
  <img #beadCrystalImg src="assets/images/bead-crystal.png" />
  <img #beadTransparentImg src="assets/images/bead-transparent.png" />
  <img #beadOpaqueImg src="assets/images/bead-opaque.png" />
  <img #beadMatteImg src="assets/images/bead-matte.png" />
  <img #beadSelImg src="assets/images/beadSel.png" />
  <img #realBeadImagesImg src="assets/images/real-bead-images.png" />
</div>
<div
  #canvasContainer
  style="overflow: scroll"
  class="canvas-container"
  data-hint="Move and scale your image to overlap the pattern"
  data-hint-position="middle-middle"
>
  <canvas
    #canvas
    [class]="_bcanvas.canvasStyle"
    [width]="maxWindowSize"
    [height]="maxWindowSize"
    style="display: block"
  ></canvas>
</div>

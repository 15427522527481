import { EventEmitter } from '@angular/core';
import {
  PatternBeadModel,
  DefaultBeadPatternRows,
  DefaultBeadPatternCols,
} from './patternbeadmodel';
import { BeadPos } from './beadpos';
import { Point } from './point';
import { Size } from './size';
import { PatternImage, Rotation } from './patternimage';
import { PaletteDetailModel } from '../models/palettes/palette_detail_model';
import { EMPTY_BEAD } from '../helpers/constants';

export class PatternModelController {
  private model: PatternBeadModel;
  private undoList: Action[] = [];
  private redoList: Action[] = [];
  private onActionFired: EventEmitter<Action>;

  private compositeAction: CompositeAction = null;

  constructor(model: PatternBeadModel, onActionFired: EventEmitter<Action>) {
    this.model = model;
    this.onActionFired = onActionFired;
  }

  public getLastId = (): number => {
    if (this.canUndo()) return this.undoList[this.undoList.length - 1].getId();
    else return 0;
  };

  public startCompositeAction() {
    if (!this.compositeAction) {
      this.compositeAction = new CompositeAction(this.model);
    }
  }

  public endCompositeAction() {
    if (this.compositeAction && this.compositeAction.isNotEmpty()) {
      this.undoList.push(this.compositeAction);
      this.redoList = [];
      this.onActionFired.emit(this.compositeAction);
      this.compositeAction = null;
    }
  }

  public setBeadAt(bead: PaletteDetailModel, pos: BeadPos) {
    const beadAtPos = this.model.getBeadAt(pos.row, pos.col);

    if (!beadAtPos || !bead) {
      return;
    }

    if (beadAtPos.name == bead.name) {
      return;
    }
    this.executeAction(new SetBeadAction(this.model, pos, bead));
  }

  public resetImage(): void {
    this.model.setPatternImage(null);
  }

  public insertImage(image: HTMLImageElement): void {
    this.executeAction(new InsertPatternImageAction(this.model, image));
  }

  public moveBed(ds: Point): void {
    this.executeAction(new MoveBedAction(this.model, ds));
  }

  public movePatternImage(ds: Point): void {
    this.executeAction(new MovePatternImageAction(this.model, ds));
  }

  public scalePatternImage(dp: Point, ds: Size): void {
    this.executeAction(new ScalePatternImageAction(this.model, dp, ds));
  }

  public addRows(rn: number): void {
    this.executeAction(new AddRowsAction(this.model, rn));
  }

  public removeRows(rn: number): void {
    this.executeAction(new RemoveRowsAction(this.model, rn));
  }

  public addCols(cn: number): void {
    this.executeAction(new AddColsAction(this.model, cn));
  }

  public removeCols(cn: number): void {
    this.executeAction(new RemoveColsAction(this.model, cn));
  }

  public replaceBeads(from: PaletteDetailModel, to: PaletteDetailModel) {
    this.executeAction(new ReplaceBeadsAction(this.model, from, to));
  }

  public newPattern() {
    this.executeAction(new NewPatternAction(this.model));
  }

  public transferImage(transImage: PaletteDetailModel[][]) {
    this.executeAction(new ImageTransferAction(this.model, transImage));
  }

  public fill(bead: PaletteDetailModel, pos: BeadPos) {
    this.executeAction(new FillAction(this.model, pos, bead));
  }

  public changeStitch(toStichId: number) {
    this.executeAction(new ChangeStitchAction(this.model, toStichId));
  }

  public setPatterImageVisible(isVisible: boolean) {
    this.executeAction(new SetPatterImageVisibleAction(this.model, isVisible));
  }

  public shiftPattern(offsetX: number, offsetY: number) {
    this.executeAction(new ShiftPatternAction(this.model, offsetX, offsetY));
  }

  public rotatePattern(rotateDirection: RotateDirectionEnum) {
    this.executeAction(new RotatePatternAction(this.model, rotateDirection));
  }

  public rotateImage(rotateDirection: RotateDirectionEnum) {
    this.executeAction(new RotateImageAction(this.model, rotateDirection));
  }

  public flipPattern(flipDirection: FlipDirectionEnum) {
    this.executeAction(new FlipPatternAction(this.model, flipDirection));
  }

  public rotatedPattern(rotated: boolean) {
    this.executeAction(new RotateAction(this.model, rotated));
  }

  private executeAction(a: Action): void {
    if (this.compositeAction) {
      this.compositeAction.addAction(a);
    } else {
      this.undoList.push(a);
    }
    a.do();
    this.redoList = [];
    this.onActionFired.emit(a);
  }

  public resetUndoRedo() {
    this.undoList = [];
    this.redoList = [];
  }

  public undo(): boolean {
    if (!this.canUndo()) return false;

    let a: Action = this.undoList.pop();
    this.redoList.push(a);
    a.undo();

    return true;
  }

  public redo = (): boolean => {
    if (!this.canRedo()) return false;

    let a: Action = this.redoList.pop();
    this.undoList.push(a);
    a.do();

    return true;
  };

  public canUndo = (): boolean => {
    return this.undoList.length > 0;
  };

  public canRedo(): boolean {
    return this.redoList.length > 0;
  }

  public getModel(): PatternBeadModel {
    return this.model;
  }
}

export abstract class Action {
  protected model: PatternBeadModel;

  private static NUM_CREATED: number = 0;
  private id: number;

  constructor(model: PatternBeadModel) {
    this.model = model;
    this.id = ++Action.NUM_CREATED;
  }

  public do = (): void => {};
  public undo = (): void => {};

  public getId = (): number => {
    return this.id;
  };
}

export class SetBeadAction extends Action {
  private pos: BeadPos;
  private orgBead: PaletteDetailModel;
  private newBead: PaletteDetailModel;

  constructor(
    model: PatternBeadModel,
    pos: BeadPos,
    newBead: PaletteDetailModel,
  ) {
    super(model);
    this.pos = pos;
    this.orgBead = this.model.getBeadAt(pos.row, pos.col);
    this.newBead = newBead;
  }

  public do = (): void => {
    this.model.setBeadAt(this.newBead, this.pos.row, this.pos.col);
  };

  public undo = (): void => {
    this.model.setBeadAt(this.orgBead, this.pos.row, this.pos.col);
  };
}

export class InsertPatternImageAction extends Action {
  private image: HTMLImageElement;

  private oldPI: PatternImage;
  private newPI: PatternImage;

  constructor(model: PatternBeadModel, image: HTMLImageElement) {
    super(model);
    this.image = image;
  }

  public do = (): void => {
    if (!this.newPI) {
      this.createPatternImage();
    }

    this.oldPI = this.model.getPatternImage();

    this.model.setPatternImage(this.newPI);
  };

  private createPatternImage(): void {
    let gs: Size = this.getGridSize();
    let s: Size = this.calcInitialSize(gs);
    let p: Point = this.calcInitialPosition(s, gs);

    //        this.pixelToBeads(p, s);

    this.newPI = new PatternImage(this.image, p, s, true);
  }

  private calcInitialSize(gs: Size): Size {
    let factor = this.image.height / this.image.width;

    let nh = gs.width * factor;
    if (nh < gs.height) return new Size(gs.width, nh);

    return new Size(gs.height / factor, gs.height);
  }

  private calcInitialPosition(s: Size, gs: Size): Point {
    return new Point(
      this.model.getPosition().x + (gs.width - s.width) / 2,
      this.model.getPosition().y + (gs.height - s.height) / 2,
    );
  }

  private getGridSize(): Size {
    let gw = (this.model.getCols() + 0.5) * this.model.getBeadSize().width;
    let gh = this.model.getRows() * this.model.getBeadSize().height;

    return new Size(gw, gh);
  }

  public undo = (): void => {
    this.model.setPatternImage(this.oldPI);
  };
}

export class MoveBedAction extends Action {
  private orgPos: Point;
  private newPos: Point;

  constructor(model: PatternBeadModel, ds: Point) {
    super(model);
    this.orgPos = model.getPosition();
    this.newPos = new Point(this.orgPos.x + ds.x, this.orgPos.y + ds.y);
  }

  public do = (): void => {
    this.model.setPosition(this.newPos);
  };

  public undo = (): void => {
    this.model.setPosition(this.orgPos);
  };
}

export class MovePatternImageAction extends Action {
  private orgPos: Point;
  private newPos: Point;

  constructor(model: PatternBeadModel, ds: Point) {
    super(model);
    if (model.getPatternImage()) {
      this.orgPos = model.getPatternImage().pos;
    }
    this.newPos = new Point(this.orgPos.x + ds.x, this.orgPos.y + ds.y);
  }

  public do = (): void => {
    if (this.model.getPatternImage()) {
      this.model.getPatternImage().pos = this.newPos;
    }
  };

  public undo = (): void => {
    if (this.model.getPatternImage()) {
      this.model.getPatternImage().pos = this.orgPos;
    }
  };
}

export class ScalePatternImageAction extends Action {
  private orgPos: Point;
  private orgSize: Size;
  private newPos: Point;
  private newSize: Size;

  constructor(model: PatternBeadModel, dp: Point, ds: Size) {
    super(model);
    if (model.getPatternImage()) {
      this.orgPos = model.getPatternImage().pos;
      this.newPos = new Point(this.orgPos.x + dp.x, this.orgPos.y + dp.y);
      this.orgSize = model.getPatternImage().size;
      this.newSize = new Size(
        this.orgSize.width + ds.width,
        this.orgSize.height + ds.height,
      );
      if (this.newSize.width < this.model.getBeadSize().width)
        this.newSize.width = this.model.getBeadSize().width;
      if (this.newSize.height < this.model.getBeadSize().height)
        this.newSize.height = this.model.getBeadSize().height;
    }
  }

  public do = (): void => {
    if (this.model.getPatternImage()) {
      this.model.getPatternImage().pos = this.newPos;
      this.model.getPatternImage().size = this.newSize;
    }
  };

  public undo = (): void => {
    if (this.model.getPatternImage()) {
      this.model.getPatternImage().pos = this.orgPos;
      this.model.getPatternImage().size = this.orgSize;
    }
  };
}

export class AddRowsAction extends Action {
  private newRows: PaletteDetailModel[][];

  constructor(model: PatternBeadModel, rc: number) {
    super(model);
    this.newRows = new Array(rc);
    for (let i = 0; i < rc; i++)
      this.newRows[i] = new Array(this.model.getCols()).fill(EMPTY_BEAD);
  }

  public do = (): void => {
    this.model.addRows(this.newRows);
  };

  public undo = (): void => {
    this.model.removeRows(this.newRows.length);
  };
}

export class RemoveRowsAction extends Action {
  private num: number;
  private newRows: PaletteDetailModel[][];

  constructor(model: PatternBeadModel, rc: number) {
    super(model);
    this.num = rc;
  }

  public do = (): void => {
    this.newRows = this.model.removeRows(this.num);
  };

  public undo = (): void => {
    this.model.addRows(this.newRows);
  };
}

export class AddColsAction extends Action {
  private newCols: PaletteDetailModel[][];

  constructor(model: PatternBeadModel, nc: number) {
    super(model);
    this.newCols = new Array(this.model.getRows());
    for (let i = 0; i < this.newCols.length; i++)
      this.newCols[i] = new Array(nc).fill(EMPTY_BEAD);
  }

  public do = (): void => {
    this.model.addCols(this.newCols);
  };

  public undo = (): void => {
    this.model.removeCols(this.newCols[0].length);
  };
}

export class RemoveColsAction extends Action {
  private newCols: PaletteDetailModel[][];
  private num: number;

  constructor(model: PatternBeadModel, nc: number) {
    super(model);
    this.num = nc;
  }

  public do = (): void => {
    this.newCols = this.model.removeCols(this.num);
  };

  public undo = (): void => {
    this.model.addCols(this.newCols);
  };
}

export class ReplaceBeadsAction extends Action {
  private from: PaletteDetailModel;
  private to: PaletteDetailModel;
  private pos: number[][] = new Array();

  constructor(
    model: PatternBeadModel,
    from: PaletteDetailModel,
    to: PaletteDetailModel,
  ) {
    super(model);
    this.from = from;
    this.to = to;
    this.initPos();
  }

  private initPos() {
    for (let r = 0; r < this.model.getRows(); r++) {
      for (let c = 0; c < this.model.getCols(); c++) {
        if (
          this.model.getBeadAt(r, c).paletteDetailId ==
          this.from.paletteDetailId
        ) {
          this.pos.push([r, c]);
        }
      }
    }
  }

  public do = (): void => {
    for (let i = 0; i < this.pos.length; i++)
      this.model.setBeadAt(this.to, this.pos[i][0], this.pos[i][1]);
  };

  public undo = (): void => {
    for (let i = 0; i < this.pos.length; i++)
      this.model.setBeadAt(this.from, this.pos[i][0], this.pos[i][1]);
  };
}

export class NewPatternAction extends Action {
  private origGrid: PaletteDetailModel[][];

  constructor(model: PatternBeadModel) {
    super(model);
    this.initOrigGrid();
  }

  private initOrigGrid() {
    this.origGrid = new Array(this.model.getRows());

    for (let i = 0; i < this.model.getRows(); i++)
      this.origGrid[i] = new Array(this.model.getCols());

    for (let r = 0; r < this.model.getRows(); r++) {
      for (let c = 0; c < this.model.getCols(); c++) {
        this.origGrid[r][c] = this.model.getBeadAt(r, c);
      }
    }
  }

  public do = (): void => {
    this.model.initDefaultGrid();
  };

  public undo = (): void => {
    this.model.setGrid(this.origGrid);
  };
}

export class ImageTransferAction extends Action {
  private origGrid: PaletteDetailModel[][];
  private transGrid: PaletteDetailModel[][];

  constructor(model: PatternBeadModel, transImage: PaletteDetailModel[][]) {
    super(model);
    this.initGrid(transImage);
  }

  private initGrid(transImage: PaletteDetailModel[][]) {
    this.origGrid = this.model.getGrid();
    this.transGrid = new Array(this.model.getRows());

    for (let i = 0; i < this.model.getRows(); i++)
      this.transGrid[i] = new Array(this.model.getCols());

    for (let r = 0; r < this.model.getRows(); r++) {
      for (let c = 0; c < this.model.getCols(); c++) {
        // console.log("r : " + r + ", c : " + c);
        if (transImage[r][c].paletteTypeId == 0)
          this.transGrid[r][c] = this.model.getBeadAt(r, c);
        else this.transGrid[r][c] = transImage[r][c];
      }
    }
  }

  public do = (): void => {
    this.model.setGrid(this.transGrid);
  };

  public undo = (): void => {
    this.model.setGrid(this.origGrid);
  };
}

export class FillAction extends Action {
  private newBead: PaletteDetailModel;
  private orgBead: PaletteDetailModel;
  private startPos: BeadPos;
  private pos: BeadPos[] = null;

  constructor(model: PatternBeadModel, pos: BeadPos, bead: PaletteDetailModel) {
    super(model);
    this.newBead = bead;
    this.orgBead = this.model.getBeadAt(pos.row, pos.col);
    this.startPos = pos;
  }

  public do = (): void => {
    if (!this.pos) {
      this.pos = new Array();
      this.initBeadPos(this.startPos);
    } else this.setBeads(this.newBead);
  };

  private initBeadPos(p: BeadPos) {
    var b = this.model.getBeadAt(p.row, p.col);
    if (b.color != this.orgBead.color) return;
    this.model.setBeadAt(this.newBead, p.row, p.col);
    this.pos.push(p);
    if (p.col > 0) this.initBeadPos(new BeadPos(p.row, p.col - 1));
    if (p.col < this.model.getCols() - 1)
      this.initBeadPos(new BeadPos(p.row, p.col + 1));
    if (p.row > 0) this.initBeadPos(new BeadPos(p.row - 1, p.col));
    if (p.row < this.model.getRows() - 1)
      this.initBeadPos(new BeadPos(p.row + 1, p.col));
  }

  public undo = (): void => {
    this.setBeads(this.orgBead);
  };

  private setBeads(bead: PaletteDetailModel) {
    this.pos.forEach((p) => {
      this.model.setBeadAt(bead, p.row, p.col);
    });
  }
}

export class ChangeStitchAction extends Action {
  private newStitchId: number;
  private orgStitchId: number;

  constructor(model: PatternBeadModel, newStitchId: number) {
    super(model);
    this.newStitchId = newStitchId;
    this.orgStitchId = this.model.getStitchId();
  }

  public do = (): void => {
    this.model.setStitchId(this.newStitchId);
  };

  public undo = (): void => {
    this.model.setStitchId(this.orgStitchId);
  };
}

export class SetPatterImageVisibleAction extends Action {
  private newVisible: boolean;

  constructor(model: PatternBeadModel, newVisible: boolean) {
    super(model);
    this.newVisible = newVisible;
  }

  public do = (): void => {
    if (this.model.getPatternImage()) {
      this.model.getPatternImage().visible = this.newVisible;
    }
  };

  public undo = (): void => {
    if (this.model.getPatternImage()) {
      this.model.getPatternImage().visible = !this.newVisible;
    }
  };
}

export class ShiftPatternAction extends Action {
  private offsX: number;
  private offsY: number;

  constructor(model: PatternBeadModel, offsX: number, offsY: number) {
    super(model);
    this.offsX = offsX;
    this.offsY = offsY;
  }

  public do = (): void => {
    this.shiftPatternInternal(this.offsX, this.offsY);
  };

  public undo = (): void => {
    this.shiftPatternInternal(-this.offsX, -this.offsY);
  };

  private shiftPatternInternal(offsetX: number, offsetY: number) {
    let rows = this.model.getRows();
    let cols = this.model.getCols();
    let grid = this.model.getGrid();

    let newGrid = [];

    for (let y = 0; y < rows; y++) {
      let newRow = [];

      for (let x = 0; x < cols; x++) {
        let newY = y + offsetY;
        let newX = x + offsetX;

        if (newY < 0) newY = rows - 1;

        if (newY >= rows) newY = 0;

        if (newX < 0) newX = cols - 1;

        if (newX >= cols) newX = 0;

        let pd = grid[newY][newX];

        newRow[x] = pd;
      }

      newGrid.push(newRow);
    }

    this.model.setGrid(newGrid);
  }
}

export class RotatePatternAction extends Action {
  private rotateDirection: RotateDirectionEnum;

  constructor(model: PatternBeadModel, rotateDirection: RotateDirectionEnum) {
    super(model);
    this.rotateDirection = rotateDirection;
  }

  public do = (): void => {
    this.rotatePatternInternal(this.rotateDirection);
  };

  public undo = (): void => {
    if (this.rotateDirection == RotateDirectionEnum.Left)
      this.rotatePatternInternal(RotateDirectionEnum.Right);
    else this.rotatePatternInternal(RotateDirectionEnum.Left);
  };

  private rotatePatternInternal(rotateDirection: RotateDirectionEnum) {
    let rows = this.model.getRows();
    let cols = this.model.getCols();
    let grid = this.model.getGrid();

    let newGrid = [];

    if (rotateDirection == RotateDirectionEnum.Left) {
      for (let x = cols - 1; x >= 0; x--) {
        let newRow = [];

        for (let y = 0; y < rows; y++) {
          let pd = grid[y][x];

          newRow[y] = pd;
        }

        newGrid.push(newRow);
      }
    } else {
      for (let x = 0; x < cols; x++) {
        let newRow = [];

        for (let y = 0; y < rows; y++) {
          let pd = grid[y][x];

          newRow[rows - y - 1] = pd;
        }

        newGrid.push(newRow);
      }
    }

    this.model.setGrid(newGrid);
  }
}

export class FlipPatternAction extends Action {
  private flipDirection: FlipDirectionEnum;

  constructor(model: PatternBeadModel, flipDirection: FlipDirectionEnum) {
    super(model);
    this.flipDirection = flipDirection;
  }

  public do = (): void => {
    this.flipPatternInternal(this.flipDirection);
  };

  public undo = (): void => {
    this.flipPatternInternal(this.flipDirection);
  };

  private flipPatternInternal(flipDirection: FlipDirectionEnum) {
    let rows = this.model.getRows();
    let cols = this.model.getCols();
    let grid = this.model.getGrid();

    let newGrid = [];

    if (flipDirection == FlipDirectionEnum.Vertical) {
      for (let y = rows - 1; y >= 0; y--) {
        let newRow = [];

        for (let x = 0; x < cols; x++) {
          let pd = grid[y][x];

          newRow[x] = pd;
        }

        newGrid.push(newRow);
      }
    } else {
      for (let y = 0; y < rows; y++) {
        let newRow = [];

        for (let x = 0; x < cols; x++) {
          let pd = grid[y][x];

          newRow[cols - x - 1] = pd;
        }

        newGrid.push(newRow);
      }
    }

    this.model.setGrid(newGrid);
  }
}

export class RotateImageAction extends Action {
  private rotateDirection: RotateDirectionEnum;

  constructor(model: PatternBeadModel, rotateDirection: RotateDirectionEnum) {
    super(model);
    this.rotateDirection = rotateDirection;
  }

  public do = (): void => {
    if (this.model.getPatternImage()) {
      this.model.getPatternImage().rot =
        this.rotateDirection == RotateDirectionEnum.Right
          ? this.getNextRotation()
          : this.getPrevRotation();
      this.swapImageWidthAndHeight();
    }
  };

  public undo = (): void => {
    if (this.model.getPatternImage()) {
      this.model.getPatternImage().rot =
        this.rotateDirection == RotateDirectionEnum.Left
          ? this.getNextRotation()
          : this.getPrevRotation();
      this.swapImageWidthAndHeight();
    }
  };

  private getNextRotation(): Rotation {
    if (this.model.getPatternImage()) {
      switch (this.model.getPatternImage().rot) {
        case Rotation.Rot_0:
          return Rotation.Rot_90;
        case Rotation.Rot_90:
          return Rotation.Rot_180;
        case Rotation.Rot_180:
          return Rotation.Rot_270;
        case Rotation.Rot_270:
          return Rotation.Rot_0;
      }
    }
  }

  private getPrevRotation(): Rotation {
    if (this.model.getPatternImage()) {
      switch (this.model.getPatternImage().rot) {
        case Rotation.Rot_0:
          return Rotation.Rot_270;
        case Rotation.Rot_90:
          return Rotation.Rot_0;
        case Rotation.Rot_180:
          return Rotation.Rot_90;
        case Rotation.Rot_270:
          return Rotation.Rot_180;
      }
    }
  }

  private swapImageWidthAndHeight() {
    if (this.model.getPatternImage()) {
      let s = this.model.getPatternImage().size;
      this.model.getPatternImage().size = new Size(s.height, s.width);
    }
  }
}

export class RotateAction extends Action {
  private orgRot: number;
  private newRot: number;

  constructor(model: PatternBeadModel, rotated: boolean) {
    super(model);
    this.orgRot = this.model.getRotation();
    this.calcNewRot(rotated);
  }

  private calcNewRot(rotated: boolean) {
    if (rotated) {
      this.newRot = -90;
    } else {
      this.newRot = 0;
    }
  }

  public do = (): void => {
    this.model.setRotation(this.newRot);
    // console.log("rotate to " + this.newRot)
  };

  public undo = (): void => {
    this.model.setRotation(this.orgRot);
    // console.log("rotate back to " + this.orgRot)
  };
}

export class CompositeAction extends Action {
  private actions: Action[] = [];

  constructor(model: PatternBeadModel) {
    super(model);
  }

  public addAction = (a: Action): void => {
    this.actions.push(a);
  };

  public isNotEmpty = (): boolean => {
    return this.actions.length > 0;
  };

  public do = (): void => {
    this.actions.forEach((a) => {
      a.do();
    });
  };

  public undo = (): void => {
    this.actions.forEach((a) => {
      a.undo();
    });
  };
}

export enum FlipDirectionEnum {
  Vertical,
  Horizontal,
}

export enum RotateDirectionEnum {
  Left,
  Right,
}

import { Component, ViewChild, ElementRef } from '@angular/core';
import { Point } from './point';
import { Size } from './size';

export class PatternImage {
    public pos: Point;
    public size: Size;
    public visible: boolean;
    public rot: Rotation;

    public image; HTMLImageElement;

    constructor(_image: HTMLImageElement, _pos: Point = null, _size: Size = null, _visible: boolean = true, _rot: Rotation = Rotation.Rot_0) {
        this.pos = _pos ? _pos : new Point();
        this.size = _size ? _size : new Size();
        this.image = _image;
        this.visible = _visible;
        this.rot = _rot;
    }
}

export enum Rotation {
    Rot_0,
    Rot_90,
    Rot_180,
    Rot_270
}
<div class="loading-indicator" *ngIf="!providersReady">
  <mat-progress-spinner
    mode="indeterminate"
    color="primary"
  ></mat-progress-spinner>
</div>

<div
  mat-dialog-title
  class="modal-title"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <span class="modal-title-label">{{ httpService.getTransation().Login }}</span>
</div>
<div mat-dialog-content>
  <div class="login-buttons-container">
    <table>
      <tr>
        <td>
          <button
            mat-raised-button
            class="login-button"
            (click)="signInWithGoogle()"
          >
            <img class="login-icon" src="/assets/images/google.png" />
            <span class="login-text"
              >{{ httpService.getTransation().LoginWithGoogle }}</span
            >
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>

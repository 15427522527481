import {
  Component,
  Inject,
  ElementRef,
  ViewChild,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import html2pdf from 'html2pdf.js';
import { PatternBead } from 'src/app/models/pattern/pattern-bead';
import { Utility } from 'src/app/helpers/utility';
import {
  ChunkedPaletteDetailModel,
  PaletteDetailModel,
} from 'src/app/models/palettes/palette_detail_model';
import { StitchTypeEnum } from 'src/app/models/stitchs/stitch_model';
import { HttpService } from 'src/app/services/http-service';
import { DOCUMENT } from '@angular/common';
import { PDFDocument } from 'pdf-lib';
import { EMPTY_BEAD } from 'src/app/helpers/constants';

export interface PrintPreviewModalData {
  patternName: string;
  data: PatternBead;
  printContentWidth: number;
  printContentHeight: number;
}

const CHUNKED_ROWS = 80;
const CHUNKED_COLS = 50;

@Component({
  selector: 'app-print-preview',
  templateUrl: './print-preview-modal.html',
  styleUrls: ['./print-preview-modal.css'],
})
export class PrintPreviewModal implements OnInit {
  @ViewChild('beadGridContainer', { static: false })
  beadGridContainer: ElementRef;

  dBeadsCount: Map<string, LegendaBead>;
  beadsCount: LegendaBead[];
  beadsGrid: PaletteDetailModel[][];
  chunkedBeadsGrid: ChunkedPaletteDetailModel[];
  beadsGridList: BeadGridRow[];
  pleaseWaitLoad: boolean = false;
  pleaseWaitPdf: boolean = false;
  showPatternPreview: boolean = true;
  showWordChart: boolean = true;
  page: number = 1;
  pages: number = 1;

  width: number = 0;
  height: number = 0;

  constructor(
    public httpService: HttpService,
    @Inject(DOCUMENT) private document: Document,
    public dialogRef: MatDialogRef<PrintPreviewModal>,
    @Inject(MAT_DIALOG_DATA) public data: PrintPreviewModalData,
    public changeDetectorRef: ChangeDetectorRef,
  ) {}

  splitIntoGrid(
    source: PaletteDetailModel[][],
    rows: number,
    cols: number,
  ): ChunkedPaletteDetailModel[] {
    const result: ChunkedPaletteDetailModel[] = [];

    for (let i = 0; i < source.length; i += rows) {
      for (let j = 0; j < source[i].length; j += cols) {
        const grid: PaletteDetailModel[][] = [];
        let gridHasData = false;
        for (let k = i; k < i + rows; k++) {
          const row: PaletteDetailModel[] = [];
          for (let l = j; l < j + cols; l++) {
            if (source[k] && source[k][l]) {
              row.push(source[k][l]);
              gridHasData = true;
            }
          }
          if (row.length > 0) {
            grid.push(row);
          }
        }
        if (gridHasData) {
          result.push({
            startRow: i,
            endRow: Math.min(i + rows - 1, source.length - 1),
            startColumn: j,
            endColumn: Math.min(j + cols - 1, source[i].length - 1),
            details: grid,
          });
        }
      }
    }
    return result;
  }

  async ngOnInit() {
    this.pleaseWaitLoad = true;

    let chunkedRows = CHUNKED_ROWS;
    let chunkedCols = CHUNKED_COLS;

    if (this.getPageOrientation() === 'landscape') {
      chunkedRows = CHUNKED_COLS;
      chunkedCols = CHUNKED_ROWS;
    }

    this.chunkedBeadsGrid = this.splitIntoGrid(
      this.data.data.grid,
      chunkedRows,
      chunkedCols,
    );
    this.page = 1;
    this.pages = this.chunkedBeadsGrid.length;
    this.beadsGrid = this.data.data.grid;
    this.generateBeadCount();
    this.generateBeadsGridList();
    this.pleaseWaitLoad = false;

    gtag('event', 'page_view', {
      page_title: 'Print Preview (Modal)',
      page_location: `${window.location.origin}/print-preview-modal`,
    });
  }
  ngAfterViewInit() {
    setTimeout(() => {
      const availableWidth =
        this.beadGridContainer.nativeElement.offsetWidth - 60;
      const beadsPerRow =
        this.chunkedBeadsGrid[this.page - 1].details[0].length;

      this.width = availableWidth / (beadsPerRow + 1);

      if (this.width > 25) {
        this.width = 25;
      }

      this.height = (this.width * 12) / 16;

      if (
        this.data.data.stitchId === StitchTypeEnum.Loom ||
        this.data.data.stitchId === StitchTypeEnum.Peyote
      ) {
        const temp = this.width;
        this.width = this.height;
        this.height = temp;
      }

      this.changeDetectorRef.detectChanges();
    });
  }
  onPreviousPage() {
    if (this.page > 1) {
      this.page--;
    }
  }

  onNextPage() {
    if (this.page < this.pages) {
      this.page++;
    }
  }

  getBeadGridRowClass() {
    if (this.data.data.stitchId === StitchTypeEnum.Brick) {
      return 'bead-grid-row-brick';
    } else if (this.data.data.stitchId === StitchTypeEnum.Peyote) {
      return 'bead-grid-row-peyote';
    } else {
      return 'bead-grid-row-loom';
    }
  }

  getBeadGridCellClass() {
    if (this.data.data.stitchId === StitchTypeEnum.Brick) {
      return 'bead-grid-cell-brick';
    } else if (this.data.data.stitchId === StitchTypeEnum.Peyote) {
      if (this.beadsGrid[0].length % 2 === 0) {
        return 'bead-grid-cell-peyote';
      } else {
        return 'bead-grid-cell-peyote';
      }
    } else {
      return 'bead-grid-cell-loom';
    }
  }

  getCellTopMargin(nCol: number): number {
    if (this.data.data.stitchId === StitchTypeEnum.Peyote) {
      if (nCol % 2 !== 0) {
        return (this.height / 2) * -1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  getCellBottomMargin(nCol: number): number {
    if (this.data.data.stitchId === StitchTypeEnum.Peyote) {
      if (nCol % 2 !== 0) {
        return this.height / 2;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  getBeadGridCellStyle(nCol: number, c: PaletteDetailModel) {
    return {
      'background-color': c.paletteDetailId != -1 ? '#' + c.color : '#ffffffff',
      color: c.paletteDetailId != -1 ? this.getContrastHex(c.color) : '#000000',
      width: this.width + 'px',
      height: this.height + 'px',
      'line-height': this.height - 2 + 'px',
      'font-size': this.getFontSize() + 'px',
      'margin-top': this.getCellTopMargin(nCol) + 'px;',
      'margin-bottom': this.getCellBottomMargin(nCol) + 'px;',
    };
  }

  getFontSize(): number {
    if (this.data.data.stitchId === StitchTypeEnum.Brick) {
      return this.height - 2;
    } else {
      return this.width - 2;
    }
  }

  getRowLeftMargin(nRow: number): number {
    if (this.data.data.stitchId === StitchTypeEnum.Brick) {
      if (nRow % 2 == 0) {
        return this.width / 2;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  getBeadGridCell(nRow: number, nCol: number): PaletteDetailModel {
    const pd = this.beadsGrid[nRow][nCol];
    return pd || EMPTY_BEAD;
  }

  generateBeadCount(): void {
    this.dBeadsCount = new Map<string, LegendaBead>();

    let currentLetter = 'A';

    for (let nRow = 0; nRow < this.beadsGrid.length; nRow++) {
      for (let nCol = 0; nCol < this.beadsGrid[0].length; nCol++) {
        let pd = this.getBeadGridCell(nRow, nCol);

        if (pd && pd.paletteDetailId != -1) {
          let lb: LegendaBead = null;

          if (!this.dBeadsCount.has(pd.name)) {
            lb = new LegendaBead();
            lb.letter = currentLetter;
            lb.beadName = pd.name;
            lb.beadColor = pd.color;

            lb.count = 0;
            this.dBeadsCount.set(pd.name, lb);
            currentLetter = this.nextChar(currentLetter);
          } else {
            lb = this.dBeadsCount.get(pd.name);
          }

          lb.count++;
        }
      }
    }

    this.beadsCount = [...this.dBeadsCount.values()];

    // console.log(this.beadsCount);
  }

  generateBeadsGridList(): void {
    this.beadsGridList = [];

    let side = 'L';
    let lastBeadLetter = '';
    let countBeads = 0;

    for (let nRow = 0; nRow < this.beadsGrid.length; nRow++) {
      let bgRow = new BeadGridRow();

      bgRow.cells = [];

      if (this.data.data.stitchId === StitchTypeEnum.Peyote) {
        if (nRow === 0) {
          bgRow.rowHeader = 'Row 1&2 (' + side + '):';

          for (let nCol = 0; nCol < this.beadsGrid[0].length; nCol++) {
            let pd = null;

            if (side === 'L') {
              pd = this.getBeadGridCell(nRow, nCol);
            } else {
              pd = this.getBeadGridCell(
                nRow,
                this.beadsGrid[0].length - nCol - 1,
              );
            }

            let letter = '_';

            if (pd.paletteDetailId != -1) {
              letter = this.dBeadsCount.get(pd.name).letter;
            }

            if (lastBeadLetter != '' && lastBeadLetter !== letter) {
              bgRow.cells.push(<BeadGridCell>{
                letter: lastBeadLetter,
                count: countBeads,
              });
              countBeads = 1;
            } else {
              countBeads++;
            }

            lastBeadLetter = letter;
          }

          if (countBeads > 0) {
            bgRow.cells.push(<BeadGridCell>{
              letter: lastBeadLetter,
              count: countBeads,
            });
          }

          this.beadsGridList.push(bgRow);
        } else {
          bgRow.rowHeader = 'Row ' + (nRow * 2 + 1) + ' (' + side + '):';

          for (let nCol = 0; nCol < this.beadsGrid[0].length; nCol += 2) {
            let pd = null;

            if (side === 'L') {
              pd = this.getBeadGridCell(nRow, nCol);
            } else {
              pd = this.getBeadGridCell(
                nRow,
                this.beadsGrid[0].length - nCol - 1,
              );
            }

            let letter = '_';

            if (pd.paletteDetailId != -1) {
              letter = this.dBeadsCount.get(pd.name).letter;
            }

            if (lastBeadLetter != '' && lastBeadLetter !== letter) {
              bgRow.cells.push(<BeadGridCell>{
                letter: lastBeadLetter,
                count: countBeads,
              });
              countBeads = 1;
            } else {
              countBeads++;
            }

            lastBeadLetter = letter;
          }

          if (countBeads > 0) {
            bgRow.cells.push(<BeadGridCell>{
              letter: lastBeadLetter,
              count: countBeads,
            });
          }

          this.beadsGridList.push(bgRow);

          countBeads = 0;
          lastBeadLetter = '';

          if (side === 'L') side = 'R';
          else side = 'L';

          bgRow = new BeadGridRow();
          bgRow.rowHeader = 'Row ' + (nRow * 2 + 2) + ' (' + side + '):';
          bgRow.cells = [];

          let startNCol = 1;

          if (this.beadsGrid[0].length % 2 === 0) startNCol = 0;

          for (
            let nCol = startNCol;
            nCol < this.beadsGrid[0].length;
            nCol += 2
          ) {
            let pd = null;

            if (side === 'L') {
              pd = this.getBeadGridCell(nRow, nCol);
            } else {
              pd = this.getBeadGridCell(
                nRow,
                this.beadsGrid[0].length - nCol - 1,
              );
            }

            let letter = '_';

            if (pd.paletteDetailId != -1) {
              letter = this.dBeadsCount.get(pd.name).letter;
            }

            if (lastBeadLetter != '' && lastBeadLetter !== letter) {
              bgRow.cells.push(<BeadGridCell>{
                letter: lastBeadLetter,
                count: countBeads,
              });
              countBeads = 1;
            } else {
              countBeads++;
            }

            lastBeadLetter = letter;
          }

          if (countBeads > 0) {
            bgRow.cells.push(<BeadGridCell>{
              letter: lastBeadLetter,
              count: countBeads,
            });
          }

          this.beadsGridList.push(bgRow);
        }
      } else {
        bgRow.rowHeader = 'Row ' + (nRow + 1) + ' (' + side + '):';

        for (let nCol = 0; nCol < this.beadsGrid[0].length; nCol++) {
          let pd = null;

          if (side === 'L') {
            pd = this.getBeadGridCell(nRow, nCol);
          } else {
            pd = this.getBeadGridCell(
              nRow,
              this.beadsGrid[0].length - nCol - 1,
            );
          }

          let letter = '_';

          if (pd.paletteDetailId != -1) {
            letter = this.dBeadsCount.get(pd.name).letter;
          }

          if (lastBeadLetter != '' && lastBeadLetter !== letter) {
            bgRow.cells.push(<BeadGridCell>{
              letter: lastBeadLetter,
              count: countBeads,
            });
            countBeads = 1;
          } else {
            countBeads++;
          }

          lastBeadLetter = letter;
        }

        if (countBeads > 0) {
          bgRow.cells.push(<BeadGridCell>{
            letter: lastBeadLetter,
            count: countBeads,
          });
        }

        this.beadsGridList.push(bgRow);
      }

      countBeads = 0;
      lastBeadLetter = '';

      if (side === 'L') side = 'R';
      else side = 'L';

      //console.log(row);
    }
  }

  getContrastHex(hex: string): string {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }

    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  }

  getBeadClasses(paletteTypeId: number, appendClasses: string): string {
    return Utility.getBeadClasses(paletteTypeId) + ' ' + appendClasses;
  }

  getBeadLetter(pageIndex: number, rowIndex: number, colIndex: number) {
    let bead =
      this.chunkedBeadsGrid[pageIndex].details[rowIndex][colIndex] ||
      EMPTY_BEAD;

    let letter = '';

    if (this.dBeadsCount.has(bead.name))
      letter = this.dBeadsCount.get(bead.name).letter;

    return letter;
  }

  nextChar(c: string) {
    if (c.length === 1) {
      if (c === 'Z') {
        return 'A2';
      } else {
        return String.fromCharCode(c.charCodeAt(0) + 1);
      }
    } else {
      const number = parseInt(c[1]);
      if (c[0] === 'Z') {
        return `A${number + 1}`;
      } else {
        return String.fromCharCode(c.charCodeAt(0) + 1) + number;
      }
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  debugBase64(base64Url) {
    var win = window.open();
    win.document.write(
      '<iframe src="' +
        base64Url +
        '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>',
    );
  }

  getRows(): number {
    return this.beadsGrid?.length | 0;
  }

  getCols(): number {
    return this.beadsGrid?.[0].length | 0;
  }

  getPageOrientation(): 'landscape' | 'portrait' {
    return this.data.data.stitchId === StitchTypeEnum.Brick &&
      this.data.data.beadWidth > this.data.data.beadHeight
      ? 'landscape'
      : 'portrait';
  }

  async addContentToPdf(
    contentId: string,
    mergedPdfDoc: PDFDocument,
    margin: number,
    forceOrientation: 'landscape' | 'portrait' = null,
  ) {
    const content = document.getElementById(contentId);
    const options = {
      image: { type: 'jpeg' },
      jsPDF: {
        unit: 'pt',
        format: 'a4',
        orientation: forceOrientation || this.getPageOrientation(),
        putOnlyUsedFonts: true,
      },
      margin,
      pagebreak: { mode: 'avoid-all' },
    };

    const pdf = await html2pdf()
      .set(options)
      .from(content)
      .output('arraybuffer');
    const pdfDoc = await PDFDocument.load(pdf);
    const copiedPages = await mergedPdfDoc.copyPages(
      pdfDoc,
      pdfDoc.getPageIndices(),
    );
    copiedPages.forEach((page) => mergedPdfDoc.addPage(page));
  }

  async createPDF(): Promise<Uint8Array> {
    const mergedPdfDoc = await PDFDocument.create();

    await this.addContentToPdf('legendaContent', mergedPdfDoc, 30, 'landscape');

    for (let i = 0; i < this.pages; i++) {
      this.page = i + 1;
      this.changeDetectorRef.detectChanges();
      await Utility.delay(500);

      await this.addContentToPdf('beadsGridContent', mergedPdfDoc, 30);
    }

    if (this.showWordChart) {
      await this.addContentToPdf(
        'wordChartContent',
        mergedPdfDoc,
        30,
        'portrait',
      );
    }

    const pdfBytes = await mergedPdfDoc.save();
    return pdfBytes;
  }

  uint8ArrayToDataUrl(data: Uint8Array, mimeType: string): string {
    let binaryString = '';

    for (let i = 0; i < data.length; i++) {
      binaryString += String.fromCharCode(data[i]);
    }

    const base64String = btoa(binaryString);

    return `data:${mimeType};base64,${base64String}`;
  }

  isChunked(): boolean {
    return this.pages > 1;
  }

  async onExportPDF(): Promise<void> {
    this.pleaseWaitPdf = true;

    const pdfBytes = await this.createPDF();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${this.data.patternName}.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    this.pleaseWaitPdf = false;
  }
}

class LegendaBead {
  letter: string;
  beadName: string;
  beadColor: string;
  beadPaletteTypeId: number;
  count: number;
}

class BeadGridRow {
  rowHeader: string;
  cells: BeadGridCell[];
}

class BeadGridCell {
  letter: string;
  count: number;
}

export class Point {
    public x: number;
    public y: number;

    public constructor(_x: number = 0, _y: number = 0) {
        this.x = _x;
        this.y = _y;
    }

    public mult(s: number): Point {
        this.x *= s;
        this.y *= s;

        return this;
    }
}
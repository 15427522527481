<div
  mat-dialog-title
  class="modal-title"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <span class="modal-title-label">{{ getTitle() }}</span>
  <button
    class="modal-close-button"
    mat-icon-button
    matTooltip="{{ httpService.getTransation().Close }}"
    (click)="onCancel()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content class="pt-1">
  <mat-form-field class="full-width">
    <input
      matInput
      #nameInput
      placeholder="{{ httpService.getTransation().Name }}"
      [(ngModel)]="pattern.name"
      required
    />
    <mat-error>Name is required</mat-error>
  </mat-form-field>
  <mat-form-field class="full-width">
    <input
      matInput
      placeholder="{{ httpService.getTransation().Description }}"
      [(ngModel)]="pattern.description"
    />
  </mat-form-field>
  <div style="display: flex; flex-direction: row; align-items: center">
    <mat-form-field fxFlex="50">
      <mat-select
        [(value)]="selectedCategory"
        placeholder="{{ httpService.getTransation().Category }}"
        (selectionChange)="onCategorySelected(selectedCategory)"
      >
        <mat-option *ngFor="let c of categories" [value]="c"
          >{{ c.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <button
      mat-icon-button
      title="{{ httpService.getTransation().AddCategory }}"
      (click)="onAddCategory()"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      mat-icon-button
      title="{{ httpService.getTransation().EditCategory }}"
      [disabled]="!selectedCategory || selectedCategory.categoryId === 0"
      (click)="onEditCategory()"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button
      mat-icon-button
      title="{{ httpService.getTransation().DeleteCategory }}"
      [disabled]="!selectedCategory || selectedCategory.categoryId === 0"
      (click)="onDeleteCategory()"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="onSubmit()">
    {{ httpService.getTransation().Ok }}
  </button>
  <button mat-raised-button cdkFocusInitial (click)="onCancel()">
    {{ httpService.getTransation().Cancel }}
  </button>
</div>

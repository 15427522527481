export class PaletteDetailModel {
  paletteDetailId: number;
  paletteId: number;
  paletteBeadId: number;
  name: string;
  description: string;
  paletteTypeId: number;
  color: string;
  count: number;
  validated: number;
}

export class ChunkedPaletteDetailModel {
  startRow: number;
  endRow: number;
  startColumn: number;
  endColumn: number;
  details: PaletteDetailModel[][];
}

export enum PaletteTypeEnum {
  Empty = 0,
  Metallic = 1,
  Unknown = 2,
  Crystal = 3,
  Transparent = 4,
  Opaque = 5,
  Matte = 6
}

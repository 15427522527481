import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { PalettesListComponent } from './palettes-list/palettes-list.component';
import { PatternPalettesListComponent } from './pattern-palettes-list/pattern-palettes-list.component';
import { DelayedChangeDirective } from './directives/delayed-change.directive';
import { BeadCanvasComponent } from './bead-canvas/bead-canvas.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatBadgeModule } from '@angular/material/badge';
import { HttpService } from './services/http-service';
import { MatDialogModule } from '@angular/material/dialog';
import { ReplaceBeadModal } from './modals/replace-bead-modal/replace-bead-modal';
import { TransferImageModal } from './modals/transfer-image-modal/transfer-image-modal';
import { PaletteEditorModal } from './modals/palette-editor-modal/palette-editor-modal';
import { PrintPreviewModal } from './modals/print-preview-modal/print-preview-modal';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PatternExplorerModal } from './modals/pattern-explorer-modal/pattern-explorer-modal';
import { MatTreeModule } from '@angular/material/tree';
import { AddEditPatternModal } from './modals/add-edit-pattern-modal/add-edit-pattern-modal';
import { PromptModal } from './modals/prompt-modal/prompt-modal';
import { ConfirmModal } from './modals/confirm-modal/confirm-modal';
import { MatExpansionModule } from '@angular/material/expansion';
import { ProfileModal } from './modals/profile-modal/profile-modal';
import { TransformPatternModal } from './modals/transform-pattern-modal/transform-pattern-modal';
import {
  SocialLoginModule,
  GoogleLoginProvider,
  FacebookLoginProvider,
  SocialAuthServiceConfig,
} from 'angularx-social-login';
import { LoginModal } from './modals/login-modal/login-modal';
import { LoginHomeComponent } from './login-home/login-home.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SubscriptionsModal } from './modals/subscriptions-modal/subscriptions-modal';
import { ChangePlanDoneModal } from './modals/change-plan-done-modal/change-plan-done-modal';
import { HomeComponent } from './home/home.component';
import { AppGlobalErrorhandler } from './helpers/app-global-error-handler';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationComponent } from './components/notification/notification.component';
import { LocalStorageKeys } from './helpers/constants';

const routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
  },
  { path: 'login', component: LoginHomeComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginHomeComponent,
    HomeComponent,
    PalettesListComponent,
    PatternPalettesListComponent,
    DelayedChangeDirective,
    ToolbarComponent,
    BeadCanvasComponent,
    NotificationComponent,
    ReplaceBeadModal,
    PatternExplorerModal,
    AddEditPatternModal,
    PromptModal,
    ConfirmModal,
    TransferImageModal,
    TransformPatternModal,
    PaletteEditorModal,
    PrintPreviewModal,
    ProfileModal,
    LoginModal,
    SubscriptionsModal,
    ChangePlanDoneModal,
  ],
  entryComponents: [
    ReplaceBeadModal,
    PatternExplorerModal,
    AddEditPatternModal,
    PromptModal,
    ConfirmModal,
    TransferImageModal,
    TransformPatternModal,
    PaletteEditorModal,
    PrintPreviewModal,
    ProfileModal,
    LoginModal,
    SubscriptionsModal,
    ChangePlanDoneModal,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatTooltipModule,
    MatDividerModule,
    MatMenuModule,
    MatTabsModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatCardModule,
    MatListModule,
    MatGridListModule,
    FlexLayoutModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatBadgeModule,
    MatDialogModule,
    MatTableModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    DragDropModule,
    MatTreeModule,
    MatExpansionModule,
    MatSlideToggleModule,
    SocialLoginModule,
  ],
  providers: [
    HttpService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: localStorage.getItem(LocalStorageKeys.AuthProvider) !== null,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '991481757079-md18668odeqpcv9mgp2l33qb204i2jc3.apps.googleusercontent.com'
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('185266806072490'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: ErrorHandler,
      useClass: AppGlobalErrorhandler,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

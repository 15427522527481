<div
  mat-dialog-title
  class="modal-title"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <span class="modal-title-label"
    >{{ httpService.getTransation().PaletteEditor }}</span
  >
  <button
    class="modal-close-button"
    mat-icon-button
    matTooltip="{{ httpService.getTransation().Close }}"
    (click)="onClose()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <table>
    <tr>
      <td colspan="3">
        <table class="full-width">
          <tr>
            <td class="full-width">
              <div class="form-container">
                <mat-form-field>
                  <mat-select
                    placeholder="{{ httpService.getTransation().SelectPaletteToEdit }}"
                    [(value)]="selectedPalette"
                    (selectionChange)="onPaletteSelected(selectedPalette)"
                  >
                    <mat-option *ngFor="let p of palettes" [value]="p"
                      >{{ p.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
            </td>
            <td>
              <button mat-icon-button [matMenuTriggerFor]="menuPalette">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menuPalette="matMenu">
                <button mat-menu-item (click)="addNewPalette()">
                  <mat-icon>add</mat-icon>
                  <span>{{ httpService.getTransation().NewPalette }}</span>
                </button>
                <button
                  mat-menu-item
                  *ngIf="selectedPalette"
                  (click)="deleteSelectedPalette()"
                >
                  <mat-icon>remove</mat-icon>
                  <span>{{ httpService.getTransation().DeletePalette }}</span>
                </button>
              </mat-menu>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td style="vertical-align: top">
        <app-palettes-list
          #paletteListEdit
          [paletteName]="getCurrentPaletteName()"
          [height]="data.paletteListHeight"
          [includePadding]="false"
          [palettesListType]="palettesListTypes.Edit"
          [includeBuiltIn]="false"
          [includeCustom]="true"
          (onRemove)="onRemovePaletteDetail($event)"
          (onPaletteNameChanged)="onPaletteNameChanged($event)"
        >
        </app-palettes-list>
      </td>
      <td
        style="
          vertical-align: top;
          text-align: center;
          width: 50px;
          padding-top: 300px;
        "
      >
        <i class="material-icons"> arrow_back </i>
      </td>
      <td style="vertical-align: top">
        <app-palettes-list
          #paletteListSource
          [palettesListType]="palettesListTypes.AddItemSelection"
          [includeBuiltIn]="true"
          [includeCustom]="false"
          [height]="data.paletteListHeight"
          [includePadding]="false"
          (onAdd)="onAddPaletteDetail($event)"
        >
        </app-palettes-list>
      </td>
    </tr>
  </table>
</div>

import {
  Component,
  ViewChild,
  OnInit,
  HostListener,
  ChangeDetectorRef,
  Inject,
  AfterViewInit,
} from '@angular/core';
import { Utility, BeadTypeEnum, BeadMeasureEnum } from '../helpers/utility';
import {
  BeadCanvas,
  ShiftTypeEnum,
  FlipDirectionEnum,
  EditMode,
} from '../peditor/beadcanvas';
import { PatternPalettesListComponent } from '../pattern-palettes-list/pattern-palettes-list.component';
import { HttpService, UnitOfMeasureEnum } from '../services/http-service';
import { PaletteDetailModel } from '../models/palettes/palette_detail_model';
import { StitchModel, StitchTypeEnum } from '../models/stitchs/stitch_model';
import {
  PatternExplorerModalMode,
  PatternExplorerModal,
} from '../modals/pattern-explorer-modal/pattern-explorer-modal';
import { MatDialog } from '@angular/material/dialog';
import {
  AddEditPatternModal,
  AddEditPatternModalMode,
} from '../modals/add-edit-pattern-modal/add-edit-pattern-modal';
import {
  ToolbarActionFiredTypeEnum,
  ToolbarActionFired,
  ToolbarComponent,
} from '../toolbar/toolbar.component';
import { PrintPreviewModal } from '../modals/print-preview-modal/print-preview-modal';
import { PaletteEditorModal } from '../modals/palette-editor-modal/palette-editor-modal';
import {
  TransferImageModalResult,
  TransferImageModal,
} from '../modals/transfer-image-modal/transfer-image-modal';
import { Observable, timer, Subscription } from 'rxjs';
import { RotateDirectionEnum, Action } from '../peditor/patternmodelcontroller';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  SocialAuthService,
} from 'angularx-social-login';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TransformPatternModal } from '../modals/transform-pattern-modal/transform-pattern-modal';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { SubscriptionsModal } from '../modals/subscriptions-modal/subscriptions-modal';
import { SubscriptionTypeEnum } from '../models/user/user-model';
import { ChangePlanDoneModal } from '../modals/change-plan-done-modal/change-plan-done-modal';
import { PatternModel } from '../models/pattern/pattern-model';
import * as dayjs from 'dayjs';
import { BeadCanvasComponent } from '../bead-canvas/bead-canvas.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationTypeEnum } from '../components/notification/notification.component';
import { Rect } from '../peditor/rect';
import { Title } from '@angular/platform-browser';
import { PalettesListComponent } from '../palettes-list/palettes-list.component';
import { LocalStorageKeys, SUBSCRIPTIONS } from '../helpers/constants';
import { DOCUMENT } from '@angular/common';
import { IntrojsService } from '../introjs.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  stitchs: StitchModel[] = [];
  selectedPaletteDetail: PaletteDetailModel;

  patternListHeight: number;
  paletteListHeight: number;

  rightSidebarOpen: boolean = true;

  windowWidth: number;
  windowHeight: number;

  minSideToolbarWidth = 510;

  currentPattern: PatternModel;

  pleaseWait: boolean = false;

  @ViewChild('patternPaletteList', { static: true })
  private patternPaletteList: PatternPalettesListComponent;

  @ViewChild('paletteList', { static: true })
  private paletteList: PalettesListComponent;

  @ViewChild('beadCanvasComponent', { static: true })
  private beadCanvasComponent: BeadCanvasComponent;

  @ViewChild('appToolbar', { static: true })
  private appToolbar: ToolbarComponent;

  appToolbarHeight: number;

  smallToolbarLeft: number;
  smallToolbarTop: number;

  bcanvas = new BeadCanvas();

  lastTemporaryPatternData: string;

  lastActionId: number = 0;
  checkAutoSave: Observable<number> = timer(0, 1000);
  checkAutoSaveSubscription: Subscription;

  selectedEditMode: EditMode = EditMode.AddBead;
  selectedTabIndex: number = 0;

  hasTouchScreen: boolean;

  constructor(
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    public httpService: HttpService,
    private authService: SocialAuthService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private introService: IntrojsService,
    private deviceService: DeviceDetectorService,
  ) {
    this.hasTouchScreen = Utility.hasTouchScreen();
    this.stitchs.push(<StitchModel>{
      stitchId: StitchTypeEnum.Peyote,
      name: 'Peyote',
    });

    this.stitchs.push(<StitchModel>{
      stitchId: StitchTypeEnum.Brick,
      name: 'Brick',
    });
    this.stitchs.push(<StitchModel>{
      stitchId: StitchTypeEnum.Loom,
      name: 'Loom',
    });

    this.restartAutoSaveTimer();

    if (
      environment.production &&
      (!this.httpService || !this.httpService.loggedIn)
    ) {
      let queryParams = undefined;

      if (
        router.routerState.snapshot.url.includes('session_id') &&
        router.routerState.snapshot.url.includes('price_id')
      ) {
        queryParams = { returnUrl: router.routerState.snapshot.url };
      }

      this.router.navigate(['login'], {
        queryParams,
      } as NavigationExtras);
    }
  }

  setSidebarTabIndex(tabIndex: number) {
    this.selectedTabIndex = tabIndex;
  }

  openCloseRightSidebar(open: boolean) {
    this.rightSidebarOpen = open;
    this.beadCanvasComponent.resizeCanvasContainer();
  }

  toggleRightSidebar() {
    this.rightSidebarOpen = !this.rightSidebarOpen;
    this.beadCanvasComponent.resizeCanvasContainer();
  }

  private restartAutoSaveTimer() {
    if (this.checkAutoSaveSubscription)
      this.checkAutoSaveSubscription.unsubscribe();

    this.checkAutoSaveSubscription = this.checkAutoSave.subscribe(() => {
      if (this.httpService && this.httpService.canSavePattern()) {
        if (this.lastActionId == this.bcanvas.getLastId())
          this.savePatternWithDialog(false, true);

        this.lastActionId = this.bcanvas.getLastId();
      }
    });
  }

  onSelectedEditModeChanged(e: MatButtonToggleChange): void {
    switch (e.value) {
      case 'add-bead':
        this.bcanvas.setEditMode(EditMode.AddBead);
        break;
      case 'fill':
        this.bcanvas.setEditMode(EditMode.Fill);
        break;
      case 'remove-bead':
        this.bcanvas.setEditMode(EditMode.RemoveBead);
        break;
      case 'remove-fill':
        this.bcanvas.setEditMode(EditMode.RemoveFill);
        break;
      case 'pick-bead':
        this.bcanvas.setEditMode(EditMode.PickBead);
        break;
      case 'pan':
        this.bcanvas.setEditMode(EditMode.Pan);
        break;
    }
  }

  public getStitch(): StitchModel {
    for (var i = 0; i < this.stitchs.length; i++)
      if (this.stitchs[i].stitchId == this.bcanvas.getStitchId())
        return this.stitchs[i];

    return this.stitchs[0];
  }

  getPhotoUrl() {
    if (
      this.httpService.socialUser &&
      this.httpService.socialUser.photoUrl &&
      this.httpService.socialUser.photoUrl != ''
    )
      return this.httpService.socialUser.photoUrl;
    else return 'https://www.w3schools.com/howto/img_avatar.png';
  }

  getFullname() {
    if (this.httpService.socialUser)
      return (
        this.httpService.socialUser.firstName +
        ' ' +
        this.httpService.socialUser.lastName +
        ' (' +
        this.getProviderName(this.httpService.socialUser.provider) +
        ')'
      );
    else return 'Anonymous';
  }

  getProviderName(provider: string) {
    if (provider == FacebookLoginProvider.PROVIDER_ID) {
      return this.httpService.getTransation().Facebook;
    } else if (provider == GoogleLoginProvider.PROVIDER_ID) {
      return this.httpService.getTransation().Google;
    }
  }

  async signOut() {
    localStorage.removeItem(LocalStorageKeys.AuthProvider);
    if (this.httpService.loggedIn) {
      await this.authService.signOut();
    }

    this.router.navigateByUrl('login');
  }

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle('Bead Pattern Maker');
    this.route.queryParams.subscribe((params) => {
      if (params.session_id) {
        this.router.navigateByUrl('');
        this.showChangePlanDone();

        const subscription = SUBSCRIPTIONS.find(
          (s) => s.item_id === params.price_id,
        );

        gtag('event', 'purchase', {
          transaction_id: params.session_id,
          value: subscription.price,
          currency: 'USD',
          items: [subscription],
        });
      }
    });

    gtag('event', 'page_view', {
      page_title: 'Home',
      page_location: `${window.location.origin}/home`,
    });

    if (environment.production) {
      this.authService.authState.subscribe(async (user) => {
        if (user) {
          if (user.provider == GoogleLoginProvider.PROVIDER_ID) {
            this.httpService.socialUser = user;
            this.httpService.loggedIn = user != null;

            this.httpService.userId = this.httpService.socialUser.id;
            console.log('this.httpService.userId', this.httpService.userId);
            await this.login();
          }
        }
      });
    } else {
      this.httpService.socialUser = null;
      this.httpService.loggedIn = true;
      // this.httpService.userId = 'fake123456';
      this.httpService.userId = '105456499220003439248';
      // this.httpService.userId = '109150598416818561473';
      // this.httpService.userId = '2404804532983724';
      // this.httpService.userId = '101354069256132896672';
      // this.httpService.userId = '100444400718183972656';
      // this.httpService.userId = '106965579378210901273';
      this.login();
    }

    this.resize();

    this.httpService.setBCanvas(this.bcanvas);

    this.bcanvas.onCurrentBeadChanged.subscribe(
      async (currentBead: PaletteDetailModel) => {
        this.selectedTabIndex = 0;
        this.rightSidebarOpen = true;
        this.beadCanvasComponent.resizeCanvasContainer();

        setTimeout(() => {
          this.patternPaletteList.focusOnPaletteDetail(currentBead);
        }, 300);
      },
    );

    if (this.windowWidth <= 768) {
      this.rightSidebarOpen = false;
      this.beadCanvasComponent.resizeCanvasContainer();
    }
  }

  ngAfterViewInit(): void {
    this.introService.startTour(this, false);
    // this.introService.addHints();
  }

  private async login() {
    await this.httpService.login();
    if (this.httpService.user.saveTemporaryPatternId !== null) {
      let patternModel = await this.httpService.getPattern(
        this.httpService.user.saveTemporaryPatternId,
      );

      if (patternModel != null) {
        patternModel.patternData =
          this.httpService.user.saveTemporaryPatternData;

        if (patternModel.patternData != null) {
          await this.openPattern(patternModel);
          this.httpService.dirtyLastActionId();
        }
      } else {
        let patternModel = <PatternModel>{
          patternId: this.currentPattern ? this.currentPattern.patternId : 0,
          name: '',
          description: '',
          categoryId: 0,
          categoryName: '',
          patternData: this.httpService.user.saveTemporaryPatternData,
          imageFilename: this.httpService.user.imageFilename,
          userId: this.httpService.userId,
          createdOn: null,
          lastUpdateOn: null,
        };

        if (patternModel.patternData != null) {
          await this.openPattern(patternModel);
          this.httpService.dirtyLastActionId();
        }
      }
    }
  }

  private resize() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;

    const smallToolbarVertical = this.windowWidth > this.minSideToolbarWidth;

    this.patternListHeight =
      this.windowHeight - (smallToolbarVertical ? 225 : 250);
    this.paletteListHeight =
      this.windowHeight - (smallToolbarVertical ? 275 : 310);
    this.appToolbarHeight =
      this.windowWidth > this.appToolbar.minMainMenuWidth ? 79 : 43;

    if (smallToolbarVertical) {
      // Vertically aligned
      this.smallToolbarLeft = 0;
      this.smallToolbarTop = this.appToolbarHeight + 10;
    } else {
      // Horizontally aligned
      this.smallToolbarLeft = this.windowWidth / 2 - 300 / 2;
      this.smallToolbarTop = this.windowHeight - 65;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resize();
  }

  public async onToolbarActionFired(actionFired: ToolbarActionFired) {
    switch (actionFired.actionType) {
      case ToolbarActionFiredTypeEnum.New:
        gtag('event', 'new', {});
        if (this.httpService.canSavePattern()) {
          Utility.confirmMessage(
            this.dialog,
            this.httpService.getTransation().Warning,
            this.httpService.getTransation().ConfirmUnsavedPattern,
            async (): Promise<void> => {
              this.newPattern();
            },
          );
        } else {
          this.newPattern();
        }
        break;

      case ToolbarActionFiredTypeEnum.Open:
        gtag('event', 'open', {});
        if (this.httpService.canSavePattern()) {
          Utility.confirmMessage(
            this.dialog,
            this.httpService.getTransation().Warning,
            this.httpService.getTransation().ConfirmUnsavedPattern,
            async (): Promise<void> => {
              this.openPatternWithDialog();
            },
          );
        } else {
          this.openPatternWithDialog();
        }
        break;

      case ToolbarActionFiredTypeEnum.Save:
        gtag('event', 'save', {});
        this.savePatternWithDialog(false, false);
        break;

      case ToolbarActionFiredTypeEnum.SaveAs:
        gtag('event', 'save_as', {});
        this.savePatternWithDialog(true, false);
        break;

      case ToolbarActionFiredTypeEnum.Print:
        gtag('event', 'print', {});
        this.showPrintPattern();
        break;

      case ToolbarActionFiredTypeEnum.ToggleImageVisibility:
        gtag('event', 'toggle_image_visibility', {});
        this.bcanvas.setPatternImageVisible(
          !this.bcanvas.isPatternImageVisible(),
        );
        break;

      case ToolbarActionFiredTypeEnum.ToggleRealisticBeadsEnabled:
        gtag('event', 'toggle_realistic_beads_enabled', {});
        this.bcanvas.setRealisticBeadsEnabled(
          !this.bcanvas.isRealisticBeadsEnabled(),
        );
        break;

      case ToolbarActionFiredTypeEnum.UploadImage:
        gtag('event', 'upload_image', {});
        let files = actionFired.parameters;
        this.uploadFiles(files);
        break;

      case ToolbarActionFiredTypeEnum.TransferImage:
        this.showTransferImage();
        break;

      case ToolbarActionFiredTypeEnum.TransformPattern:
        this.showTransformPattern();
        break;

      case ToolbarActionFiredTypeEnum.PaletteEditor:
        this.showPaletterEditor();
        break;

      case ToolbarActionFiredTypeEnum.ShiftLeft:
        gtag('event', 'shift_left', {});
        this.bcanvas.shiftPattern(ShiftTypeEnum.Left);
        break;

      case ToolbarActionFiredTypeEnum.ShiftRight:
        gtag('event', 'shift_right', {});
        this.bcanvas.shiftPattern(ShiftTypeEnum.Right);
        break;

      case ToolbarActionFiredTypeEnum.ShiftUp:
        gtag('event', 'shift_up', {});
        this.bcanvas.shiftPattern(ShiftTypeEnum.Up);
        break;

      case ToolbarActionFiredTypeEnum.ShiftDown:
        gtag('event', 'shift_down', {});
        this.bcanvas.shiftPattern(ShiftTypeEnum.Down);
        break;

      case ToolbarActionFiredTypeEnum.RotateLeft:
        gtag('event', 'rotate_left', {});
        this.bcanvas.rotatePattern(RotateDirectionEnum.Left);
        break;

      case ToolbarActionFiredTypeEnum.RotateRight:
        gtag('event', 'rotate_right', {});
        this.bcanvas.rotatePattern(RotateDirectionEnum.Right);
        break;

      case ToolbarActionFiredTypeEnum.FlipVertically:
        gtag('event', 'flip_vertically', {});
        this.bcanvas.flipPattern(FlipDirectionEnum.Vertical);
        break;

      case ToolbarActionFiredTypeEnum.FlipHorizontally:
        gtag('event', 'flip_horizontally', {});
        this.bcanvas.flipPattern(FlipDirectionEnum.Horizontal);
        break;

      case ToolbarActionFiredTypeEnum.RotateImageLeft:
        gtag('event', 'rotate_image_left', {});
        await this.httpService.rotateImage(RotateDirectionEnum.Left);
        break;

      case ToolbarActionFiredTypeEnum.RotateImageRight:
        gtag('event', 'rotate_image_right', {});
        await this.httpService.rotateImage(RotateDirectionEnum.Right);
        break;

      case ToolbarActionFiredTypeEnum.ShowSubscription:
        this.showSubscription();
        break;

      case ToolbarActionFiredTypeEnum.ToggleRightSidebar:
        this.toggleRightSidebar();
        break;

      case ToolbarActionFiredTypeEnum.StartWelcomeTour:
        this.introService.startTour(this, true);
        break;

      case ToolbarActionFiredTypeEnum.VideoTutorial:
        let url: string;

        if (this.deviceService.isMobile()) {
          url =
            'https://www.youtube.com/watch?v=Sfu-zG8_i0s&ab_channel=GabrieleFerreri';
        } else {
          url =
            'https://www.youtube.com/watch?v=g-Us6JmvJg0&ab_channel=GabrieleFerreri';
        }

        window.open(url, '_blank');
        break;
    }
  }

  public async newPattern() {
    this.lastActionId = 0;
    this.currentPattern = null;
    this.lastTemporaryPatternData = null;
    this.httpService.user.imageFilename = null;
    this.beadCanvasComponent.resetZoom();
    this.beadCanvasComponent.resetPatternPosition();
    this.beadCanvasComponent.resetRotation();
    this.bcanvas.controller.newPattern();
    this.bcanvas.repaint();
    this.appToolbar.refreshRotatedTooltip();
    this.bcanvas.scrollCanvasContainerToPatternPosition();
    this.bcanvas.controller.resetUndoRedo();
    this.bcanvas.setPatternImage(undefined);
    this.changeDetectorRef.detectChanges();
  }

  public async openPatternWithDialog() {
    const dialogRef = this.dialog.open(PatternExplorerModal, {
      maxWidth: '700px',
      maxHeight: '700px',
      width: '90%',
      height: '90%',
      data: {
        mode: PatternExplorerModalMode.Open,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      let pattern = <PatternModel>result;

      if (pattern) {
        this.openPattern(pattern);
        this.lastTemporaryPatternData = null;
      }
    });
  }

  public async openPattern(pattern: PatternModel) {
    this.currentPattern = pattern;

    let patternBead = this.httpService.deserializePatternBead(
      pattern.patternData,
    );

    var selectedStitch = this.stitchs.find(
      (x) => x.stitchId === patternBead.stitchId,
    );

    if (selectedStitch === undefined) {
      selectedStitch = this.stitchs[0];
    }

    if (this.currentPattern.imageFilename) {
      let patternImage = new Image();
      patternImage.src =
        'Assets/Images/' +
        this.httpService.user.userId +
        '/' +
        this.currentPattern.imageFilename +
        '?' +
        new Date().getTime();
      this.bcanvas.patternImageLoaded = false;
      patternImage.onload = async () => {
        this.bcanvas.patternImageLoaded = true;
      };

      await this.refreshCanvas(pattern, patternImage);
    } else {
      await this.refreshCanvas(pattern, null);
    }
  }

  public async saveTemporaryPattern() {
    let patternData = JSON.stringify(this.bcanvas.getData());

    if (this.lastTemporaryPatternData != patternData) {
      let pattern = <PatternModel>{
        patternId: this.currentPattern ? this.currentPattern.patternId : 0,
        name: '',
        description: '',
        categoryId: 0,
        categoryName: '',
        patternData: patternData,
        userId: this.httpService.userId,
        createdOn: null,
        lastUpdateOn: null,
      };
      this.savePattern(pattern, true);
      this.lastTemporaryPatternData = patternData;
    }
  }

  public async savePatternWithDialog(isSaveAs: boolean, isTemporary: boolean) {
    if (!isTemporary) {
      await this.saveTemporaryPattern();

      let hasName = this.currentPattern && this.currentPattern.name;

      if (await this.checkSubscriptionAvailable(isSaveAs || !hasName, false)) {
        let showDialog = true;

        if (!isSaveAs) {
          if (hasName) {
            this.savePattern(this.currentPattern, false);
            showDialog = false;
          }
        }

        if (showDialog) {
          const dialogRef = this.dialog.open(AddEditPatternModal, {
            width: '510px',
            data: {
              mode: AddEditPatternModalMode.Add,
              pattern: null,
            },
          });

          dialogRef.afterClosed().subscribe(async (result) => {
            let pattern = <PatternModel>result;

            if (pattern) {
              this.savePattern(pattern, false);
            }
          });
        }
      } else {
        this.pleaseWait = true;
        await this.showSubscription();
        this.pleaseWait = false;
      }
    } else {
      await this.saveTemporaryPattern();
    }
  }

  public async checkSubscriptionAvailable(
    isSaveAs: boolean,
    isPrint: boolean,
  ): Promise<boolean> {
    let ok = false;

    if (environment.production) {
      if (
        this.httpService.user.subscriptionType !== SubscriptionTypeEnum.None
      ) {
        const date = this.httpService.user.subscriptionLastDateTime;

        if (date) {
          let expireDate = undefined;

          if (
            this.httpService.user.subscriptionType ===
            SubscriptionTypeEnum.Monthly
          ) {
            expireDate = dayjs(date).add(1, 'month').endOf('day');
          } else if (
            this.httpService.user.subscriptionType ===
            SubscriptionTypeEnum.Yearly
          ) {
            expireDate = dayjs(date).add(1, 'year').endOf('day');
          }

          if (expireDate && expireDate > dayjs()) {
            ok = true;
          }
        }
      } else {
        const patterns = await this.httpService.getPatterns(0, '');

        if ((patterns.length < 3 || !isSaveAs) && !isPrint) {
          ok = true;
        }
      }

      return ok;
    } else {
      return true;
    }
  }

  public async showSubscription() {
    if (this.httpService.user) {
      if (
        this.httpService.user.stripeCustomerId &&
        this.httpService.user.subscriptionType !== SubscriptionTypeEnum.None
      ) {
        await this.showCustomerPortal();
      } else {
        this.dialog.open(SubscriptionsModal, {
          hasBackdrop: false,
          data: {
            homeComponent: this,
            subscriptionType: this.httpService.user.subscriptionType,
          },
        });
        gtag('event', 'view_item_list', {
          item_list_id: 'subscriptions',
          item_list_name: 'Subscriptions',
          items: SUBSCRIPTIONS,
        });
      }
    }
  }

  public async showCustomerPortal() {
    const data = await this.httpService.createCustomerPortalSession();
    // console.log('showCustomerPortal', data);
    window.open(data.redirectUrl, '_blank');
  }

  public async showChangePlanDone() {
    const dialogRef = this.dialog.open(ChangePlanDoneModal, {
      hasBackdrop: false,
      data: {
        homeComponent: this,
      },
    });
  }

  public async savePattern(pattern: PatternModel, isTemporary: boolean) {
    if (!isTemporary) this.pleaseWait = true;

    let data = this.bcanvas.getData();

    await this.httpService.savePattern(pattern, data, isTemporary);

    if (!isTemporary) this.pleaseWait = false;
  }

  public async showPrintPattern() {
    if (await this.checkSubscriptionAvailable(false, true)) {
      let data = this.bcanvas.getData();

      const dialogRef = this.dialog.open(PrintPreviewModal, {
        maxWidth: '90%',
        width:
          data.stitchId === StitchTypeEnum.Brick &&
          data.beadWidth > data.beadHeight
            ? '1030px'
            : '790px',
        height: this.windowHeight - 20 + 'px',
        data: {
          patternName: this.currentPattern
            ? this.currentPattern.name
            : 'Pattern',
          data: data,
          printContentWidth:
            data.stitchId === StitchTypeEnum.Brick &&
            data.beadWidth > data.beadHeight
              ? 930
              : 690,
          printContentHeight: this.windowHeight - 180,
        },
      });

      dialogRef.afterClosed().subscribe(() => {
        // console.log('The dialog was closed');
        // console.log(result);
      });
    } else {
      this.showSubscription();
    }
  }

  public async showPaletterEditor() {
    const dialogRef = this.dialog.open(PaletteEditorModal, {
      minWidth: '720px',
      height: this.windowHeight - 20 + 'px',
      data: {
        paletteListHeight: this.windowHeight - 345,
      },
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  public async showLogin() {
    this.authService.signOut();
  }

  public async uploadFiles(files: any) {
    try {
      this.pleaseWait = true;
      await this.httpService.uploadImage(files);
      Utility.showNotification(
        this._snackBar,
        'Image uploaded!',
        NotificationTypeEnum.Information,
      );
      this.introService.addMoveAndScaleImageHint();
      this.introService.addTransferImageHint();
    } catch {
      Utility.showNotification(
        this._snackBar,
        'An error occurred during upload!',
        NotificationTypeEnum.Error,
      );
    } finally {
      this.pleaseWait = false;
    }
  }

  private checkImageAndPatternOverlap(): boolean {
    var model = this.bcanvas.getModel();

    if (model && model.getPatternImage()) {
      const patternRect = new Rect(
        Math.round(model.getPosition().x),
        Math.round(model.getPosition().y),
        model.getBeadSize().width * model.getCols(),
        model.getBeadSize().height * model.getRows(),
      );

      const imageRect = new Rect(
        Math.round(model.getPatternImage().pos.x),
        Math.round(model.getPatternImage().pos.y),
        Math.round(model.getPatternImage().size.width),
        Math.round(model.getPatternImage().size.height),
      );

      return patternRect.intersectRect(imageRect);
    } else {
      return false;
    }
  }

  public async showTransferImage() {
    const dialogRef = this.dialog.open(TransferImageModal, {
      width: '500px',
      hasBackdrop: false,
      data: {},
    });

    dialogRef.componentInstance.onApplyClicked.subscribe(async (result) => {
      // console.log('The dialog was closed');
      // console.log(result);

      let transferImageResult = <TransferImageModalResult>result;

      // console.log(transferImageResult);

      if (transferImageResult) {
        if (this.checkImageAndPatternOverlap()) {
          this.pleaseWait = true;

          try {
            var model = this.bcanvas.getModel();

            await this.httpService.transferImage(
              transferImageResult.selectedPalette.paletteId,
              transferImageResult.nColors,
              transferImageResult.selectedTransferPreEffect.preEffect,
              transferImageResult.selectedTransferMethod.method,
              transferImageResult.selectedTransferDither.dither,
              model,
            );
          } catch (error) {
            Utility.showNotification(
              this._snackBar,
              'An error occurred during the transfer!',
              NotificationTypeEnum.Error,
            );
          } finally {
            this.pleaseWait = false;
          }
        } else {
          Utility.showNotification(
            this._snackBar,
            'Image and pattern must overlap!',
            NotificationTypeEnum.Error,
          );
        }
      }
    });
  }

  public async showTransformPattern() {
    const dialogRef = this.dialog.open(TransformPatternModal, {
      hasBackdrop: false,
      data: {
        bcanvas: this.bcanvas,
        homeComponent: this,
      },
    });
  }

  async refreshCanvas(
    patternModel: PatternModel,
    patternImage: HTMLImageElement,
  ) {
    const newData = this.httpService.deserializePatternBead(
      patternModel.patternData,
    );
    if (newData.grid.length === 0) {
      this.newPattern();
      return;
    }

    this.bcanvas.setData(newData);

    if (patternImage) {
      this.bcanvas.setPatternImage(patternImage);
    }

    // console.log(
    //   this.bcanvas.getData().posX,
    //   this.bcanvas.getData().posY,
    //   this.bcanvas.getZoomFactor()
    // );

    //console.log(patternModel.patternData);
    this.patternPaletteList.refreshPaletteDetails();
    this.bcanvas.repaint();
    await this.httpService.updateLastActionId();
    this.appToolbar.refreshRotatedTooltip();

    this.bcanvas.scrollCanvasContainerToPatternPosition();
  }

  onActionFired(action: Action) {
    // console.log(action);

    this.patternPaletteList.refreshPaletteDetails();
  }

  onStitchSelected(stitch: StitchModel) {
    this.bcanvas.controller.changeStitch(stitch.stitchId);
    this.bcanvas.repaint();
  }

  selectedPaletteDetailChanged(paletteDetail: PaletteDetailModel) {
    this.selectedPaletteDetail = paletteDetail;
    this.bcanvas.setCurrentBead(this.selectedPaletteDetail);

    // console.log("selectedPaletteDetailChanged");
    // console.log(this.selectedPaletteDetail);
    if (Utility.hasTouchScreen()) {
      this.rightSidebarOpen = false;
      this.beadCanvasComponent.resizeCanvasContainer();
    }
  }

  onPaletteDetailsLoaded() {
    if (
      !this.patternPaletteList.selectedPaletteDetail &&
      !this.paletteList.selectedPaletteDetail &&
      this.paletteList.paletteDetails.length > 0
    ) {
      this.paletteList.selectedPaletteDetail =
        this.paletteList.paletteDetails[0];
      this.paletteList.selectedPaletteDetailChanged.emit(
        this.paletteList.selectedPaletteDetail,
      );
    }
  }

  getColumnsSize() {
    return Utility.convertBeadsToSize(
      this.bcanvas.getStitchId(),
      this.bcanvas.getCols(),
      BeadTypeEnum.MiyukiDelica11,
      BeadMeasureEnum.Width,
      this.httpService.user
        ? this.httpService.user.unitOfMeasure
        : UnitOfMeasureEnum.Inch,
    );
  }

  getRowsSize() {
    return Utility.convertBeadsToSize(
      this.bcanvas.getStitchId(),
      this.bcanvas.getRows(),
      BeadTypeEnum.MiyukiDelica11,
      BeadMeasureEnum.Height,
      this.httpService.user
        ? this.httpService.user.unitOfMeasure
        : UnitOfMeasureEnum.Inch,
    );
  }

  onColsChange(ev: any) {
    const newCol = ev.target.value as number;

    if (newCol > 0) {
      this.bcanvas.setCols(ev.target.value as number);
    }
  }

  onRowsChange(ev: any) {
    const newRow = ev.target.value as number;

    if (newRow > 0) {
      this.bcanvas.setRows(ev.target.value as number);
    }
  }
}

<div
  style="display: flex; flex-direction: row; justify-content: space-between"
  [class]="getContainerClass()"
>
  <div
    style="display: flex; flex-direction: row; align-items: center; gap: 8px"
  >
    <mat-icon [class]="getIconClass()">{{ getIconName() }}</mat-icon>
    <div>{{ data.message }}</div>
  </div>
  <div class="dismiss">
    <button mat-icon-button (click)="snackBarRef.dismiss()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

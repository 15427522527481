import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  PalettesListTypeEnum,
  PalettesListComponent,
} from 'src/app/palettes-list/palettes-list.component';
import { PaletteModel } from 'src/app/models/palettes/palette_model';
import { PaletteDetailModel } from 'src/app/models/palettes/palette_detail_model';
import { HttpService } from 'src/app/services/http-service';
import { DOCUMENT } from '@angular/common';
import { Utility } from 'src/app/helpers/utility';

export interface PaletteEditorModalData {
  paletteListHeight: number;
}

export interface PaletteEditorModalResult {}

@Component({
  selector: 'palette-editor-modal',
  templateUrl: './palette-editor-modal.html',
})
export class PaletteEditorModal implements OnInit {
  palettesListTypes = PalettesListTypeEnum;
  palettes: PaletteModel[];
  selectedPalette: PaletteModel;

  @ViewChild('paletteListEdit', { static: true })
  private paletteListEdit: PalettesListComponent;
  @ViewChild('paletteListSource', { static: true })
  private paletteListSource: PalettesListComponent;

  constructor(
    public httpService: HttpService,
    @Inject(DOCUMENT) private document: Document,
    public dialogRef: MatDialogRef<PaletteEditorModal>,
    @Inject(MAT_DIALOG_DATA) public data: PaletteEditorModalData
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  refreshPalettes(palettes: PaletteModel[]) {
    if (palettes) {
      this.palettes = Utility.getPalettesList(palettes, false, true);
      if (this.palettes.length > 0 && !this.selectedPalette) {
        this.selectedPalette = this.palettes[0];

        this.onPaletteSelected(this.selectedPalette);
      }
    }
  }

  async ngOnInit() {
    this.refreshPalettes(this.httpService.palettes);
    this.httpService.onPalettesRefreshed.subscribe((palettes) => {
      this.refreshPalettes(palettes);
    });

    gtag('event', 'page_view', {
      page_title: 'Palette Editor (Modal)',
      page_location: `${window.location.origin}/palette-editor-modal`,
    });
  }

  getCurrentPaletteName(): string {
    if (this.selectedPalette) return this.selectedPalette.name;
    else return '';
  }

  async addNewPalette(): Promise<void> {
    let palette = new PaletteModel();
    palette.name = this.httpService.getTransation().MyPalette;
    palette.builtIn = false;
    palette.userId = this.httpService.userId;
    palette = await this.httpService.addPalette(palette);
    this.selectedPalette = palette;
    this.onPaletteSelected(this.selectedPalette);
  }

  async onPaletteNameChanged(newPaletteName: string): Promise<void> {
    this.selectedPalette.name = newPaletteName;
    await this.httpService.updatePalette(this.selectedPalette);
  }

  async deleteSelectedPalette(): Promise<void> {
    await this.httpService.removePalette(this.selectedPalette);
    this.selectedPalette = null;
    this.onPaletteSelected(this.selectedPalette);
    this.httpService.refreshPalettes();
  }

  async onPaletteSelected(palette: PaletteModel): Promise<void> {
    this.paletteListEdit.selectedPalette = palette;
    this.paletteListEdit.onPaletteSelected(palette);
    this.paletteListSource.paletteToExclude = palette;
    await this.paletteListSource.refreshPaletteDetails();
  }

  async onAddPaletteDetail(paletteDetail: PaletteDetailModel): Promise<void> {
    if (this.selectedPalette) {
      await this.httpService.addPaletteDetail(
        this.selectedPalette.paletteId,
        paletteDetail.paletteBeadId
      );
      await this.paletteListEdit.refreshPaletteDetails();
      await this.paletteListSource.refreshPaletteDetails();
    }
  }

  async onRemovePaletteDetail(
    paletteDetail: PaletteDetailModel
  ): Promise<void> {
    await this.httpService.removePaletteDetail(paletteDetail.paletteDetailId);
    await this.paletteListEdit.refreshPaletteDetails();
    await this.paletteListSource.refreshPaletteDetails();
    await this.httpService.refreshPalettes();
  }
}

<div class="loading-indicator" *ngIf="loading">
  <mat-progress-spinner
    mode="indeterminate"
    color="primary"
  ></mat-progress-spinner>
</div>

<div
  mat-dialog-title
  class="modal-title"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <span class="modal-title-label">{{ getTitle() }}</span>
  <button
    class="modal-close-button"
    mat-icon-button
    matTooltip="{{ httpService.getTransation().Close }}"
    (click)="onCancel()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content class="pt-1">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <mat-form-field fxFlex="50" style="padding-right: 8px">
      <mat-select
        placeholder="{{ httpService.getTransation().SelectACategory }}"
        [(value)]="selectedCategory"
        (selectionChange)="onCategorySelected(selectedCategory)"
      >
        <mat-option *ngFor="let c of categories" [value]="c"
          >{{ c.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex="50" style="padding-left: 8px">
      <input
        type="text"
        matInput
        placeholder="{{ httpService.getTransation().SearchPattern }}"
        [(ngModel)]="searchFilter"
        appDelayedChange
        (delayedChange)="onSearchDelayedChange($event)"
      />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>
  <mat-radio-group
    class="list-group"
    [(ngModel)]="selectedPattern"
    [style.height]="(modalHeight - 235) + 'px'"
    style="gap: 16px"
  >
    <mat-radio-button
      class="list-radio-button m-0"
      *ngFor="let p of patterns"
      [value]="p"
      (dblclick)="onPatternDoubleClick()"
      style="height: 48px"
    >
      <div class="flex-container-horizontal primary">
        <div
          class="flex-container-vertical pattern-item"
          [style.width]="(windowWidth - 220) + 'px'"
        >
          <strong>{{ p.name }}</strong>
          <small>{{p.categoryName}}</small>
          <small>{{p.description}}</small>
        </div>
        <button
          mat-icon-button
          title="{{ httpService.getTransation().EditPattern }}"
          (click)="onEditPattern(p)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          title="{{ httpService.getTransation().DeletePattern }}"
          (click)="onDeletePattern(p)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </mat-radio-button>
  </mat-radio-group>
</div>
<div mat-dialog-actions align="end" *ngIf="!loading">
  <button
    mat-raised-button
    color="primary"
    [mat-dialog-close]="selectedPattern"
    [disabled]="!selectedPattern"
  >
    {{ httpService.getTransation().Ok }}
  </button>
  <button mat-raised-button cdkFocusInitial (click)="onCancel()">
    {{ httpService.getTransation().Cancel }}
  </button>
</div>

import { Injectable } from '@angular/core';
import * as introJs from 'intro.js/intro.js';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HintSteps, LocalStorageKeys } from './helpers/constants';
import { HomeComponent } from './home/home.component';

@Injectable({
  providedIn: 'root',
})
export class IntrojsService {
  introJS = introJs.default();

  constructor(private deviceService: DeviceDetectorService) {}

  startTour(homeComponent: HomeComponent, forceTour: boolean) {
    if (!localStorage.getItem(LocalStorageKeys.StartWelcomeTour) || forceTour) {
      gtag('event', 'tour_start', {});
      this.introJS.setOptions({
        steps: [
          {
            title: 'Welcome',
            element: document.querySelector('body'),
            intro:
              "<div style='text-align:center'>Welcome to <br />🎉 <b>Bead Pattern Maker</b>! 🎉</div> <br/><hr/><br/>Let me introduce the app.",
          },
          {
            title: 'Menu',
            element: document.querySelector('.main-toolbar'),
            intro: 'Here you can open, save, print and transform your pattern',
          },
          {
            title: 'Toolbar',
            element: document.querySelector('.small-toolbar'),
            intro: 'Select to add, remove or fill beads for your pattern',
          },
          {
            title: 'Profile',
            element: document.querySelector('.profile-button'),
            intro: 'Access to your profile',
          },
          {
            title: 'Toggle Sidebar',
            element: document.querySelector('.toggle-right-sidebar'),
            intro: 'Show / hide the sidebar',
          },
          {
            title: ' Pattern',
            element: document.querySelector('.sidebar'),
            intro: 'Information about your current pattern',
          },
          {
            title: ' Palette',
            element: document.querySelector('.sidebar'),
            intro: 'Pick up a color from the available palettes',
          },
          {
            title: 'Canvas',
            element: document.querySelector('.canvas-container'),
            intro:
              "Here's where you draw your pattern<br /><br /><hr /><br /><div style='text-align:center'>That's it!<br /><br />Enjoy! 🥳</div>",
          },
        ],
      });

      this.introJS.oncomplete(() => {
        localStorage.setItem(LocalStorageKeys.StartWelcomeTour, 'done');
        gtag('event', 'tour_completed', {});
      });

      this.introJS.onexit(function () {
        localStorage.setItem(LocalStorageKeys.StartWelcomeTour, 'done');
      });

      this.introJS.onafterchange((targetElement: HTMLElement) => {
        console.log(this.introJS._currentStep);
        if (targetElement.className.includes('toggle-right-sidebar')) {
          homeComponent.openCloseRightSidebar(true);
        } else if (targetElement.className.includes('sidebar')) {
          if (this.introJS._currentStep === 5) {
            setTimeout(() => {
              homeComponent.setSidebarTabIndex(0);
            }, 100);
          } else if (this.introJS._currentStep === 6) {
            setTimeout(() => {
              homeComponent.setSidebarTabIndex(1);
            }, 100);
          }
        }
      });

      this.introJS.onbeforechange((targetElement: HTMLElement) => {
        if (targetElement.className.includes('canvas-container')) {
          if (this.deviceService.isMobile()) {
            homeComponent.openCloseRightSidebar(false);
          }
        }
      });

      this.introJS.start();
    }
  }

  addHints() {
    this.introJS.addHints();
    this.introJS.hideHints();
    this.addUploadImageHint();

    this.introJS.onhintclose((step: number) => {
      switch (step) {
        case HintSteps.UploadImage:
          localStorage.setItem(LocalStorageKeys.HintUploadImage, 'done');
          break;
        case HintSteps.MoveAndScaleImage:
          localStorage.setItem(LocalStorageKeys.HintMoveAndScaleImage, 'done');
          break;
        case HintSteps.TransferImage:
          localStorage.setItem(LocalStorageKeys.HintTransferImage, 'done');
          break;
      }
    });
  }

  addUploadImageHint() {
    if (!localStorage.getItem(LocalStorageKeys.HintUploadImage)) {
      this.introJS.showHint(0);
    }
  }

  addMoveAndScaleImageHint() {
    if (!localStorage.getItem(LocalStorageKeys.HintMoveAndScaleImage)) {
      this.introJS.showHint(2);
    }
  }

  addTransferImageHint() {
    if (!localStorage.getItem(LocalStorageKeys.HintTransferImage)) {
      this.introJS.showHint(1);
    }
  }
}

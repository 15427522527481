<div
  mat-dialog-title
  class="modal-title"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <span class="modal-title-label">{{ data.title }}</span>
  <button
    class="modal-close-button"
    mat-icon-button
    matTooltip="{{ httpService.getTransation().Close }}"
    (click)="onCancel()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content class="pt-1">
  <mat-form-field class="full-width">
    <input matInput #nameInput placeholder="{{ data.label1 }}" [(ngModel)]="data.text1" required />
    <mat-error>{{data.label1}} is required</mat-error>
  </mat-form-field>
  <mat-form-field *ngIf="data.label2" class="full-width">
    <input matInput placeholder="{{ data.label2 }}" [(ngModel)]="data.text2" />
    
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="onSubmit()">
    {{ httpService.getTransation().Ok }}
  </button>
  <button mat-raised-button cdkFocusInitial (click)="onCancel()">
    {{ httpService.getTransation().Cancel }}
  </button>
</div>

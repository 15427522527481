export class StitchModel {
    stitchId: number;
    name: string;
}

export enum StitchTypeEnum {
    Peyote = 1,
    Brick = 2,
    Loom = 3
}

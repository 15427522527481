<div
  mat-dialog-title
  class="modal-title"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <span class="modal-title-label"
    >{{ httpService.getTransation().TransformPattern }}</span
  >
  <button
    class="modal-close-button"
    mat-icon-button
    matTooltip="{{ httpService.getTransation().Close }}"
    (click)="onClose()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <mat-toolbar
    class="p-5px"
    style="
      flex-wrap: wrap;
      height: fit-content;
      padding-left: 0px;
      padding-right: 0px;
    "
  >
    <button
      mat-icon-button
      matTooltip="{{ httpService.getTransation().ShiftPatternLeft }}"
      (click)="shiftPatternLeft()"
    >
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button
      mat-icon-button
      matTooltip="{{ httpService.getTransation().ShiftPatternRight }}"
      (click)="shiftPatternRight()"
    >
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
    <button
      mat-icon-button
      matTooltip="{{ httpService.getTransation().ShiftPatternUp }}"
      (click)="shiftPatternUp()"
    >
      <mat-icon>keyboard_arrow_up</mat-icon>
    </button>
    <button
      mat-icon-button
      matTooltip="{{ httpService.getTransation().ShiftPatternDown }}"
      (click)="shiftPatternDown()"
    >
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-divider [vertical]="true" class="style-fix"></mat-divider>
    <button
      mat-icon-button
      matTooltip="{{ httpService.getTransation().RotatePatternLeft }}"
      (click)="rotatePatternLeft()"
    >
      <mat-icon>rotate_left</mat-icon>
    </button>
    <button
      mat-icon-button
      matTooltip="{{ httpService.getTransation().RotatePatternRight }}"
      (click)="rotatePatternRight()"
    >
      <mat-icon>rotate_right</mat-icon>
    </button>
    <mat-divider [vertical]="true" class="style-fix"></mat-divider>
    <button
      mat-icon-button
      matTooltip="{{ httpService.getTransation().FlipPatternVertically }}"
      (click)="flipPatternVertically()"
    >
      <mat-icon class="rotate-90">flip</mat-icon>
    </button>
    <button
      mat-icon-button
      matTooltip="{{ httpService.getTransation().FlipPatternHorizontally }}"
      (click)="flipPatternHorizontally()"
    >
      <mat-icon>flip</mat-icon>
    </button>
  </mat-toolbar>
</div>

import { PaletteDetailModel } from "../models/palettes/palette_detail_model";
import { PatternBead } from "../models/pattern/pattern-bead";
import { ShiftTypeEnum } from "./beadcanvas";

export abstract class PatternComponentData {
    public zoom(zoomFactor: number): void { }
    public zoomIn(): void { }
    public zoomOut(): void { }
    public canZoomIn(): boolean { return false; }
    public canZoomOut(): boolean { return false; }
    public setCurrentBead = (bead: PaletteDetailModel): void => { }
    public canUndo(): boolean { return false; }
    public insertImage(patternImage): void { }
    public undo(): void { }
    public redo(): void { }
    public canRedo(): boolean { return false; }
    public setData(pb: PatternBead): void { }
    public getData(): PatternBead { return null; }
    public getRows(): number { return -1; }
    public getCols(): number { return -1; }
    public setRows(r: number): void { }
    public setCols(c: number): void { }
    public getLastId(): number { return -1; }
    public shiftPattern(shiftType: ShiftTypeEnum): void { }
}
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { CategoryModel } from 'src/app/models/pattern/category-model';
import { HttpService } from 'src/app/services/http-service';
import { Utility } from 'src/app/helpers/utility';
import { PromptModal, PromptModalData } from '../prompt-modal/prompt-modal';
import { ConfirmModalData } from '../confirm-modal/confirm-modal';
import { PatternModel } from 'src/app/models/pattern/pattern-model';
import { DOCUMENT } from '@angular/common';

export interface AddEditPatternModalData {
  mode: AddEditPatternModalMode;
  pattern: PatternModel;
}

type AddEditCallback = (data: PromptModalData) => void;

@Component({
  selector: 'add-edit-explorer-modal',
  templateUrl: './add-edit-pattern-modal.html',
})
export class AddEditPatternModal implements OnInit {
  categories: CategoryModel[];
  selectedCategory: CategoryModel;

  pattern: PatternModel;
  formSubmitted = false;
  @ViewChild('nameInput', { static: false }) nameInput: ElementRef;

  constructor(
    public httpService: HttpService,
    @Inject(DOCUMENT) private document: Document,
    public dialogRef: MatDialogRef<AddEditPatternModal>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: AddEditPatternModalData
  ) {}

  async ngOnInit() {
    this.pattern = { ...this.data.pattern };

    await this.refreshCategories();
    this.selectedCategory = this.categories.find(
      (x) => x.categoryId == this.pattern.categoryId
    );

    if (!this.selectedCategory)
      this.selectedCategory = this.httpService.getAllPatternsCategory();

    setTimeout(() => {
      this.nameInput.nativeElement.focus();
    }, 500);

    gtag('event', 'page_view', {
      page_title: 'Add/Edit Pattern (Modal)',
      page_location: `${window.location.origin}/add-edit-pattern-modal`,
    });
  }

  getTitle() {
    if (this.data.mode == AddEditPatternModalMode.Add)
      return this.httpService.getTransation().AddPattern;
    else return this.httpService.getTransation().EditPattern;
  }

  onAddCategory() {
    var addCategoryCallback = async (data: PromptModalData): Promise<void> => {
      let category = await this.httpService.addCategory(<CategoryModel>{
        name: data.text1?.trim() || '',
        description: data.text2?.trim() || '',
      });

      this.categories.push(category);
      this.selectedCategory = category;
      this.onCategorySelected(category);
    };

    this.promptAddEdit(
      this.httpService.getTransation().AddCategory,
      this.httpService.getTransation().Name,
      '',
      this.httpService.getTransation().Description,
      '',
      addCategoryCallback
    );
  }

  onEditCategory() {
    var editCategoryCallback = async (data: PromptModalData): Promise<void> => {
      let index = this.categories.findIndex(
        (x) => x.categoryId == this.selectedCategory.categoryId
      );

      let category = this.categories[index];

      category.name = data.text1;
      category.description = data.text2;
      category = await this.httpService.editCategory(category);

      this.categories[index] = category;
      this.selectedCategory = category;
      this.onCategorySelected(category);
    };

    this.promptAddEdit(
      this.httpService.getTransation().EditCategory,
      this.httpService.getTransation().Name,
      this.selectedCategory.name,
      this.httpService.getTransation().Description,
      this.selectedCategory.description,
      editCategoryCallback
    );
  }

  async onDeleteCategory() {
    var deleteCategoryCallback = async (
      data: ConfirmModalData
    ): Promise<void> => {
      await this.httpService.deleteCategory(this.selectedCategory);
      let index = this.categories.findIndex(
        (x) => x.categoryId == this.selectedCategory.categoryId
      );
      this.categories.splice(index, 1);
      this.selectedCategory = Utility.nonePatternsCategory;
      this.onCategorySelected(this.selectedCategory);
    };

    Utility.confirmMessage(
      this.dialog,
      this.httpService.getTransation().DeleteCategory,
      Utility.formatString(
        this.httpService.getTransation().ConfirmDelete,
        this.selectedCategory.name
      ),
      deleteCategoryCallback
    );
  }

  private promptAddEdit(
    title: string,
    label1: string,
    text1: string,
    label2: string,
    text2: string,
    callback: AddEditCallback
  ) {
    const dialogRef = this.dialog.open(PromptModal, {
      width: '300px',
      data: {
        title: title,
        label1: label1,
        text1: text1,
        label2: label2,
        text2: text2,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      let data = <PromptModalData>result;
      if (data) {
        // console.log(data);
        callback(data);
      }
    });
  }

  public async refreshCategories(): Promise<void> {
    this.categories = [];
    this.categories.push(Utility.nonePatternsCategory);
    this.categories.push(...(await this.httpService.getCategories()));
  }

  onCategorySelected(category: CategoryModel) {
    this.pattern.categoryId = category.categoryId;
    this.pattern.categoryName = category.name;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.nameInput.nativeElement.validity.valid) {
      this.dialogRef.close(this.pattern);
    }
  }
}

export enum AddEditPatternModalMode {
  Add,
  Edit,
}

<input
  #fileUpload
  hidden
  type="file"
  accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
  (change)="uploadImage(fileUpload.files)"
/>

<div
  [style.display]="windowWidth > minMainMenuWidth ? 'block' : 'none'"
  style="background-color: #f5f5f5"
>
  <button mat-button [matMenuTriggerFor]="menuFile">
    {{ httpService.getTransation().File }}
  </button>
  <button mat-button [matMenuTriggerFor]="menuEdit">
    {{ httpService.getTransation().Edit }}
  </button>
  <button mat-button [matMenuTriggerFor]="menuView">
    {{ httpService.getTransation().View }}
  </button>
  <button mat-button [matMenuTriggerFor]="menuImage">
    {{ httpService.getTransation().Image }}
  </button>
  <button mat-button [matMenuTriggerFor]="menuPattern">
    {{ httpService.getTransation().Pattern }}
  </button>
  <button
    mat-button
    [matMenuTriggerFor]="menuTools"
    *ngIf="windowWidth >= 750 && windowHeight >= 650"
  >
    {{ httpService.getTransation().Tools }}
  </button>
  <button mat-button [matMenuTriggerFor]="menuHelp">
    {{ httpService.getTransation().Help }}
  </button>
</div>

<div
  #profileContainer
  class="profile-container"
  [style.height]="windowWidth > minMainMenuWidth ? '63px' : '43px'"
  [style.paddingTop]="windowWidth > minMainMenuWidth ? '16px' : '0px'"
>
  <button
    mat-icon-button
    [matMenuTriggerFor]="menuProfile"
    class="profile-button"
  >
    <img
      [src]="getPhotoUrl()"
      [matTooltip]="getFullname()"
      referrerpolicy="no-referrer"
      style="width: 40px; height: 40px; border-radius: 50%"
    />
  </button>
  <mat-menu #menuProfile="matMenu">
    <div style="width: 280px" fxLayout="column" fxLayoutAlign="center center">
      <div style="margin-top: 10px; font-weight: 500; font-size: 20px">
        {{ httpService.getTransation().AppName + " " + httpService.VERSION }}
      </div>
      <img
        [src]="getPhotoUrl()"
        [matTooltip]="getFullname()"
        style="
          width: 100px;
          height: 100px;
          border-radius: 50%;
          margin-top: 15px;
        "
      />
      <div style="margin-top: 10px; font-weight: 500">
        {{ getFullname() }}
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        style="margin-top: 30px; margin-bottom: 15px"
      >
        <button
          mat-stroked-button
          style="margin-right: 15px"
          (click)="showSubscription()"
        >
          <mat-icon>payment</mat-icon>
          <span>{{ httpService.getTransation().Subscription }}</span>
        </button>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        style="margin-top: 10px; margin-bottom: 15px"
      >
        <button
          mat-stroked-button
          fxFlex="50"
          style="margin-right: 15px"
          (click)="showProfile()"
        >
          <mat-icon>account_box</mat-icon>
          <span>{{ httpService.getTransation().Profile }}</span>
        </button>
        <button
          mat-stroked-button
          fxFlex="50"
          style="margin-left: 15px"
          (click)="signOut()"
        >
          <mat-icon>exit_to_app</mat-icon>
          <span>{{ httpService.getTransation().SignOut }}</span>
        </button>
      </div>
    </div>
  </mat-menu>
  <button
    mat-icon-button
    (click)="toggleRightSidebar()"
    class="toggle-right-sidebar"
  >
    <mat-icon>menu</mat-icon>
  </button>
</div>

<mat-toolbar style="padding-left: 8px; padding-right: 8px">
  <button
    #dotsMenu
    mat-icon-button
    [matMenuTriggerFor]="mainMenu"
    [style.display]="windowWidth <= minMainMenuWidth ? 'block' : 'none'"
  >
    <mat-icon>more_vert</mat-icon>
  </button>

  <button
    mat-icon-button
    [matTooltip]="httpService.getTransation().New"
    (click)="newPattern()"
    *ngIf="windowWidth >= minMediumToolbarWidth"
  >
    <mat-icon>insert_drive_file</mat-icon>
  </button>
  <button
    mat-icon-button
    [matTooltip]="httpService.getTransation().Open"
    (click)="openPattern()"
    *ngIf="windowWidth >= minMediumToolbarWidth"
  >
    <mat-icon>folder_open</mat-icon>
  </button>
  <button
    mat-icon-button
    [matTooltip]="httpService.getTransation().Save"
    (click)="savePattern()"
    [disabled]="!canSavePattern()"
    *ngIf="windowWidth >= minMediumToolbarWidth"
  >
    <mat-icon>save</mat-icon>
  </button>
  <button
    mat-icon-button
    [matTooltip]="httpService.getTransation().SaveAs"
    (click)="saveAsPattern()"
    *ngIf="windowWidth >= minFullToolbarWidth"
  >
    <mat-icon>save_alt</mat-icon>
  </button>
  <button
    mat-icon-button
    [matTooltip]="httpService.getTransation().Print"
    (click)="printPattern()"
    *ngIf="windowWidth >= minFullToolbarWidth"
  >
    <mat-icon>print</mat-icon>
  </button>
  <mat-divider [vertical]="true" class="style-fix"></mat-divider>
  <button
    mat-icon-button
    [matTooltip]="httpService.getTransation().Undo + ' ' + undoShortcut"
    (click)="undo()"
    [disabled]="!canUndo()"
  >
    <mat-icon>undo</mat-icon>
  </button>
  <button
    mat-icon-button
    [matTooltip]="httpService.getTransation().Redo + ' ' + redoShortcut"
    (click)="redo()"
    [disabled]="!canRedo()"
  >
    <mat-icon>redo</mat-icon>
  </button>
  <mat-divider
    [vertical]="true"
    class="style-fix"
    *ngIf="windowWidth < minMediumToolbarWidth"
  ></mat-divider>
  <button
    mat-icon-button
    [matTooltip]="httpService.getTransation().ZoomIn"
    (click)="zoomIn()"
    [disabled]="!canZoomIn()"
    *ngIf="windowWidth >= minSmallToolbarWidth"
  >
    <mat-icon>zoom_in</mat-icon>
  </button>
  <button
    mat-icon-button
    [matTooltip]="httpService.getTransation().ZoomOut"
    (click)="zoomOut()"
    [disabled]="!canZoomOut()"
    *ngIf="windowWidth >= minSmallToolbarWidth"
  >
    <mat-icon>zoom_out</mat-icon>
  </button>
  <!-- <button
    mat-icon-button
    [matTooltip]="httpService.getTransation().ShowHideRealisticBeads"
    (click)="toggleRealisticBeadsEnabled()"
    [disabled]="!canShowRealisticBeads()"
    *ngIf="windowWidth >= minFullToolbarWidth"
  >
    <mat-icon>{{ iconNameRealisticBeadsVisibility }}</mat-icon>
  </button> -->
  <mat-divider
    [vertical]="true"
    class="style-fix"
    *ngIf="windowWidth >= minFullToolbarWidth"
  ></mat-divider>
  <button
    mat-icon-button
    [matTooltip]="httpService.getTransation().InsertImage"
    (click)="fileUpload.click()"
    *ngIf="windowWidth >= minFullToolbarWidth"
    data-hint="Click here if you want to upload your own image and convert it into a pattern"
    data-hint-position="bottom-middle"
  >
    <mat-icon>add_photo_alternate</mat-icon>
  </button>
  <button
    mat-icon-button
    [matTooltip]="httpService.getTransation().RotateImageLeft"
    (click)="rotateImageLeft()"
    [disabled]="!canRotateImage()"
    *ngIf="windowWidth >= minFullToolbarWidth"
  >
    <mat-icon>crop_rotate</mat-icon>
  </button>
  <button
    mat-icon-button
    [matTooltip]="httpService.getTransation().RotateImageRight"
    (click)="rotateImageRight()"
    [disabled]="!canRotateImage()"
    *ngIf="windowWidth >= minFullToolbarWidth"
  >
    <mat-icon class="flip-x">crop_rotate</mat-icon>
  </button>
  <button
    mat-icon-button
    [matTooltip]="httpService.getTransation().ShowHideImage"
    (click)="toggleImageVisibility()"
    [disabled]="!canToggleImageVisibility()"
    *ngIf="windowWidth >= minFullToolbarWidth"
  >
    <mat-icon>{{ iconNameImageVisibility }}</mat-icon>
  </button>
  <button
    mat-icon-button
    [matTooltip]="httpService.getTransation().TransferImage"
    (click)="transferImage()"
    [disabled]="!canTransferImage()"
    *ngIf="windowWidth >= minFullToolbarWidth"
    data-hint="Click here to convert your uploaded image into a pattern"
    data-hint-position="bottom-middle"
  >
    <mat-icon>flash_on</mat-icon>
  </button>
  <mat-divider
    [vertical]="true"
    class="style-fix"
    *ngIf="windowWidth >= minFullToolbarWidth"
  ></mat-divider>
  <button
    mat-icon-button
    [matTooltip]="httpService.getTransation().TransformPattern"
    (click)="showTransformPattern()"
    *ngIf="windowWidth >= minFullToolbarWidth"
  >
    <mat-icon>transform</mat-icon>
  </button>
  <button
    mat-icon-button
    (click)="toggleRotatedPattern()"
    [matTooltip]="currentRotatedPatternTooltip"
    *ngIf="windowWidth >= minFullToolbarWidth"
  >
    <mat-icon [ngClass]="{ 'rotated-90': isRotatedPattern }"
      >line_weight</mat-icon
    >
  </button>
  <mat-divider
    [vertical]="true"
    class="style-fix"
    *ngIf="windowWidth >= minFullToolbarWidth"
  ></mat-divider>
  <button
    mat-icon-button
    [matTooltip]="httpService.getTransation().PaletteEditor"
    (click)="showPaletteEditor()"
    *ngIf="
      windowWidth >= minFullToolbarWidth &&
      windowWidth >= 750 &&
      windowHeight >= 650
    "
  >
    <mat-icon>list_alt</mat-icon>
  </button>
  <mat-divider
    [vertical]="true"
    class="style-fix"
    *ngIf="windowWidth >= minFullToolbarWidth"
  ></mat-divider>
  <button
    mat-icon-button
    [matTooltip]="httpService.getTransation().WelcomeTour"
    (click)="welcomeTour()"
    *ngIf="windowWidth >= minFullToolbarWidth"
  >
    <mat-icon>help_outline</mat-icon>
  </button>
  <button
    mat-icon-button
    [matTooltip]="httpService.getTransation().VideoTutorial"
    (click)="videoTutorial()"
    *ngIf="windowWidth >= minFullToolbarWidth"
  >
    <mat-icon>movie</mat-icon>
  </button>
</mat-toolbar>

<mat-menu #mainMenu="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="menuFile">
    {{ httpService.getTransation().File }}
  </button>
  <button mat-menu-item [matMenuTriggerFor]="menuEdit">
    {{ httpService.getTransation().Edit }}
  </button>
  <button mat-menu-item [matMenuTriggerFor]="menuView">
    {{ httpService.getTransation().View }}
  </button>
  <button mat-menu-item [matMenuTriggerFor]="menuImage">
    {{ httpService.getTransation().Image }}
  </button>
  <button mat-menu-item [matMenuTriggerFor]="menuPattern">
    {{ httpService.getTransation().Pattern }}
  </button>
  <button
    mat-menu-item
    [matMenuTriggerFor]="menuTools"
    *ngIf="windowWidth >= 750 && windowHeight >= 650"
  >
    {{ httpService.getTransation().Tools }}
  </button>
  <button mat-menu-item [matMenuTriggerFor]="menuHelp">
    {{ httpService.getTransation().Help }}
  </button>
</mat-menu>

<mat-menu #menuFile="matMenu">
  <button mat-menu-item (click)="newPattern()">
    <mat-icon>insert_drive_file</mat-icon>
    {{ httpService.getTransation().New }}
  </button>
  <button mat-menu-item (click)="openPattern()">
    <mat-icon>folder_open</mat-icon>
    {{ httpService.getTransation().Open }}
  </button>
  <button mat-menu-item (click)="savePattern()" [disabled]="!canSavePattern()">
    <mat-icon>save</mat-icon>
    {{ httpService.getTransation().Save }}
  </button>
  <button mat-menu-item (click)="saveAsPattern()">
    <mat-icon>save_alt</mat-icon>
    {{ httpService.getTransation().SaveAs }}
  </button>
  <button mat-menu-item (click)="printPattern()">
    <mat-icon>print</mat-icon>
    {{ httpService.getTransation().Print }}
  </button>
</mat-menu>
<mat-menu #menuEdit="matMenu">
  <button mat-menu-item (click)="undo()" [disabled]="!canUndo()">
    <mat-icon>undo</mat-icon>
    {{ httpService.getTransation().Undo + " " + undoShortcut }}
  </button>
  <button mat-menu-item (click)="redo()" [disabled]="!canRedo()">
    <mat-icon>redo</mat-icon>
    {{ httpService.getTransation().Redo + " " + redoShortcut }}
  </button>
</mat-menu>
<mat-menu #menuView="matMenu">
  <button mat-menu-item (click)="zoomIn()" [disabled]="!canZoomIn()">
    <mat-icon>zoom_in</mat-icon>
    {{ httpService.getTransation().ZoomIn }}
  </button>
  <button mat-menu-item (click)="zoomOut()" [disabled]="!canZoomOut()">
    <mat-icon>zoom_out</mat-icon>
    {{ httpService.getTransation().ZoomOut }}
  </button>
  <!-- <button
    mat-menu-item
    (click)="toggleRealisticBeadsEnabled()"
    [disabled]="!canShowRealisticBeads()"
  >
    <mat-icon>{{ iconNameRealisticBeadsVisibility }}</mat-icon>
    {{ httpService.getTransation().ShowHideRealisticBeads }}
  </button> -->
</mat-menu>
<mat-menu #menuImage="matMenu">
  <button mat-menu-item (click)="fileUpload.click()">
    <mat-icon>add_photo_alternate</mat-icon>
    {{ httpService.getTransation().InsertImage }}
  </button>
  <button
    mat-menu-item
    (click)="rotateImageLeft()"
    [disabled]="!canRotateImage()"
  >
    <mat-icon>crop_rotate</mat-icon>
    {{ httpService.getTransation().RotateImageLeft }}
  </button>
  <button
    mat-menu-item
    (click)="rotateImageRight()"
    [disabled]="!canRotateImage()"
  >
    <mat-icon class="flip-x">crop_rotate</mat-icon>
    {{ httpService.getTransation().RotateImageRight }}
  </button>
  <button
    mat-menu-item
    (click)="toggleImageVisibility()"
    [disabled]="!canToggleImageVisibility()"
  >
    <mat-icon>{{ iconNameImageVisibility }}</mat-icon>
    {{ httpService.getTransation().ShowHideImage }}
  </button>
  <button
    mat-menu-item
    (click)="transferImage()"
    [disabled]="!canTransferImage()"
  >
    <mat-icon>flash_on</mat-icon>
    {{ httpService.getTransation().TransferImage }}
  </button>
</mat-menu>
<mat-menu #menuPattern="matMenu">
  <button mat-menu-item (click)="showTransformPattern()">
    <mat-icon>transform</mat-icon>
    {{ httpService.getTransation().TransformPattern }}
  </button>
  <button mat-menu-item [matMenuTriggerFor]="menuRotatedPattern">
    <mat-icon [ngClass]="{ 'rotated-90': isRotatedPattern }"
      >line_weight</mat-icon
    >
    {{ getCurrentRotatedPatternTooltip() }}
  </button>
</mat-menu>
<mat-menu #menuRotatedPattern="matMenu">
  <button mat-menu-item (click)="setRotatedPattern(true)">
    <mat-icon class="rotated-90">line_weight</mat-icon>
    {{ httpService.getTransation().RotatedPatternOn }}
  </button>
  <button mat-menu-item (click)="setRotatedPattern(false)">
    <mat-icon>line_weight</mat-icon>
    {{ httpService.getTransation().RotatedPatternOff }}
  </button>
</mat-menu>
<mat-menu #menuTools="matMenu">
  <button mat-menu-item (click)="showPaletteEditor()">
    <mat-icon>list_alt</mat-icon>
    {{ httpService.getTransation().PaletteEditor }}
  </button>
</mat-menu>
<mat-menu #menuHelp="matMenu">
  <button mat-menu-item (click)="welcomeTour()">
    <mat-icon>help_outline</mat-icon>
    {{ httpService.getTransation().WelcomeTour }}
  </button>
  <button mat-menu-item (click)="videoTutorial()">
    <mat-icon>movie</mat-icon>
    {{ httpService.getTransation().VideoTutorial }}
  </button>
</mat-menu>

import { UnitOfMeasureEnum, LanguageEnum } from 'src/app/services/http-service';
import { TransferImageModalResult } from 'src/app/modals/transfer-image-modal/transfer-image-modal';

export interface UserModel {
  id: number;
  userId: string;
  imageFilename: string;
  saveTemporaryPatternId: number;
  saveTemporaryPatternData: string;
  unitOfMeasure: UnitOfMeasureEnum;
  detectedLanguage: string;
  language: LanguageEnum;
  transferSettings: string;
  subscriptionType: SubscriptionTypeEnum;
  subscriptionLastDateTime: Date;
  stripeCustomerId: string;
}

export enum SubscriptionTypeEnum {
  None = 0,
  Monthly = 1,
  Yearly = 2,
}

<div mat-dialog-title class="modal-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <span class="modal-title-label">{{ data.title }}</span>
    <button class="modal-close-button" mat-icon-button matTooltip="{{ httpService.getTransation().Close }}"
        (click)="onCancel()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div mat-dialog-content class="confirm-context pt-1">
    <label>{{ data.message }}</label>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button color="primary" [mat-dialog-close]="data">{{ httpService.getTransation().Ok }}</button>
    <button mat-raised-button cdkFocusInitial (click)="onCancel()">{{ httpService.getTransation().Cancel }}</button>
</div>
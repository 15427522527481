export class TranslationBase {
  AppName = 'Bead Pattern Maker';
  AppDescription =
    "Bead Pattern Maker is a free beading tool used to create bead patterns easily and intuitively. It's a simple and powerful web tool to bring your imagination into life.";
  File = 'File';
  Edit = 'Edit';
  View = 'View';
  Image = 'Image';
  Help = 'Help';
  About = 'About';
  Warning = 'Warning';
  ConfirmUnsavedPattern =
    'The current pattern is not saved. Are you sure you want to proceed?';
  Tools = 'Tools';
  Name = 'Name';
  Description = 'Description';
  Category = 'Category';
  AddCategory = 'Add Category';
  EditCategory = 'Edit Category';
  DeleteCategory = 'Delete Category';
  Ok = 'Ok';
  Cancel = 'Cancel';
  ConfirmDelete = 'Are you sure you want to delete {0}?';
  AddPattern = 'Add Pattern';
  EditPattern = 'Edit Pattern';
  PaletteEditor = 'Palette Editor';
  SelectPaletteToEdit = 'Select palette to edit';
  NewPalette = 'New Palette';
  DeletePalette = 'Delete Palette';
  MyPalette = 'My Palette';
  SelectACategory = 'Category';
  SearchPattern = 'Pattern';
  DeletePattern = 'Delete Pattern';
  OpenPattern = 'Open pattern...';
  SavePattern = 'Save pattern...';
  PrintPattern = 'Print Pattern';
  BeadsPalette = 'Beads Palette';
  BeadNumber = 'Bead #';
  Count = 'Count';
  Print = 'Print...';
  ExportPDF = 'Export PDF';
  Close = 'Close';
  WordChart = 'Word Chart';
  ReplaceBeadWith = 'Replace Bead with...';
  Apply = 'Apply';
  TransferImage = 'Transfer Image...';
  SelectPalette = 'Select palette...';
  Colors = 'Colors';
  PaletteNameEdit = 'Palette Name (Edit)';
  OnlyFirstNBeadPalettes = 'Only first {0} bead palettes';
  Filter = 'Filter...';
  Add = 'Add';
  Remove = 'Remove';
  New = 'New';
  Open = 'Open';
  Save = 'Save';
  SaveAs = 'Save as...';
  Undo = 'Undo';
  Redo = 'Redo';
  ZoomIn = 'Zoom In';
  ZoomOut = 'Zoom Out';
  InsertImage = 'Insert Image';
  ShiftPatternLeft = 'Shift Pattern Left';
  ShiftPatternRight = 'Shift Pattern Right';
  ShiftPatternUp = 'Shift Pattern Up';
  ShiftPatternDown = 'Shift Pattern Down';
  RotatePatternLeft = 'Rotate Pattern Left';
  RotatePatternRight = 'Rotate Pattern Right';
  FlipPatternVertically = 'Flip Pattern Vertically';
  FlipPatternHorizontally = 'Flip Pattern Horizontally';
  All = 'All';
  AllPatterns = 'All patterns';
  ShowHideImage = 'Show/Hide Image';
  RotateImageLeft = 'Rotate Image Left';
  RotateImageRight = 'Rotate Image Right';
  RotateRight = 'Rotate Right';
  RotateLeft = 'Rotate Left';
  PreEffect = 'Pre Effect';
  Method = 'Method';
  Dither = 'Dither';
  ShowSidebar = 'Show Sidebar';
  HideSidebar = 'Hide Sidebar';
  TransformPattern = 'Transform Pattern';
  AddBead = 'Add Bead';
  Fill = 'Fill';
  RemoveBead = 'Remove Bead';
  RemoveFill = 'Remove Fill';
  PickBead = 'Pick Bead';
  Pan = 'Pan';
  Profile = 'Profile';
  UnitOfMeasure = 'Unit of measure';
  Inch = 'inch';
  Cm = 'cm';
  Mm = 'mm';
  Rows = 'Rows';
  Columns = 'Columns';
  Stitch = 'Stitch';
  Pattern = 'Pattern';
  Palettes = 'Palettes';
  Login = 'Login';
  NotSignedIn = 'Not signed in';
  SignInWith = 'Sign in with';
  LoginWithFacebook = 'Login with Facebook';
  LoginWithGoogle = 'Login with Google';
  RotatedPatternOn = 'Rotated Pattern (On)';
  RotatedPatternOff = 'Rotated Pattern (Off)';
  SignOut = 'Sign out';
  Facebook = 'Facebook';
  Google = 'Google';
  // ShowHideRealisticBeads = 'Show/Hide Realistic Beads';
  Choose = 'CHOOSE';
  ChoosePlan = 'Choose plan';
  ChangePlan = 'Change plan';
  ChangePlanDone =
    'Plan changed. You may have to wait few minutes to see the changes applied.';
  Subscription = 'Subscription';
  FREE = 'FREE';
  MONTHLY = 'MONTHLY';
  YEARLY = 'YEARLY';
  SAVE20 = 'SAVE 20%';
  NoCharge = 'No charge';
  PlanFreeDescription1 = 'Save up to 3 patterns';
  PlanFreeDescription2 = 'No Print / PDF';
  PlanPaidDescription1 = 'Unlimited patterns';
  PlanPaidDescription2 = 'Print / PDF';
  BilledMonthly = 'Billed monthly';
  BilledAnnually = 'Billed annually';
  WelcomeTour = 'Welcome Tour';
  VideoTutorial = 'Video Tutorial';
  Previous = "Previous";
  Next = "Next";
  Page = "Page";
}

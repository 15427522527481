import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http-service';
import {
  ToolbarActionFired,
  ToolbarActionFiredTypeEnum,
} from 'src/app/toolbar/toolbar.component';
import { HomeComponent } from 'src/app/home/home.component';
import { DOCUMENT } from '@angular/common';

export interface TransformPatternModalData {
  homeComponent: HomeComponent;
}

export interface TransformPatternModalResult {}

@Component({
  selector: 'transform-pattern-modal',
  templateUrl: 'transform-pattern-modal.html',
})
export class TransformPatternModal implements OnInit {
  constructor(
    public httpService: HttpService,
    @Inject(DOCUMENT) private document: Document,
    public dialogRef: MatDialogRef<TransformPatternModal>,
    @Inject(MAT_DIALOG_DATA) public data: TransformPatternModalData
  ) {}

  async ngOnInit() {
    gtag('event', 'page_view', {
      page_title: 'Transform Pattern (Modal)',
      page_location: `${window.location.origin}/transform-pattern-modal`,
    });
  }

  shiftPatternLeft(): void {
    this.data.homeComponent.onToolbarActionFired(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.ShiftLeft,
    });
  }

  shiftPatternRight(): void {
    this.data.homeComponent.onToolbarActionFired(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.ShiftRight,
    });
  }

  shiftPatternUp(): void {
    this.data.homeComponent.onToolbarActionFired(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.ShiftUp,
    });
  }

  shiftPatternDown(): void {
    this.data.homeComponent.onToolbarActionFired(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.ShiftDown,
    });
  }

  rotatePatternLeft(): void {
    this.data.homeComponent.onToolbarActionFired(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.RotateLeft,
    });
  }

  rotatePatternRight(): void {
    this.data.homeComponent.onToolbarActionFired(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.RotateRight,
    });
  }

  flipPatternVertically(): void {
    this.data.homeComponent.onToolbarActionFired(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.FlipVertically,
    });
  }

  flipPatternHorizontally(): void {
    this.data.homeComponent.onToolbarActionFired(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.FlipHorizontally,
    });
  }

  rotateLeft() {
    this.data.homeComponent.onToolbarActionFired(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.RotatedPatternOn,
    });
  }

  rotateRight() {
    this.data.homeComponent.onToolbarActionFired(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.RotatedPatternOff,
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }
}

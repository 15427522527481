import { DOCUMENT } from '@angular/common';
import { Component, Inject, EventEmitter, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  PaletteModel,
  TransferMethodModel,
  MethodEnum,
  DitherEnum,
  TransferDitherModel,
  PreEffectEnum,
  TransferPreEffectModel,
} from 'src/app/models/palettes/palette_model';
import { HttpService } from 'src/app/services/http-service';

export interface TransferImageModalData {}

export interface TransferImageModalResult {
  selectedPalette: PaletteModel;
  selectedTransferPreEffect: TransferPreEffectModel;
  selectedTransferMethod: TransferMethodModel;
  selectedTransferDither: TransferDitherModel;
  nColors: number;
}

@Component({
  selector: 'transfer-image-modal',
  templateUrl: 'transfer-image-modal.html',
})
export class TransferImageModal implements OnInit {
  transferPreEffects: TransferPreEffectModel[];
  transferMethods: TransferMethodModel[];
  transferDithers: TransferDitherModel[];
  result = <TransferImageModalResult>{};
  palettes: PaletteModel[];
  onApplyClicked: EventEmitter<TransferImageModalResult> =
    new EventEmitter<TransferImageModalResult>();

  methods = MethodEnum;
  dithers = DitherEnum;

  constructor(
    public httpService: HttpService,
    @Inject(DOCUMENT) private document: Document,
    public dialogRef: MatDialogRef<TransferImageModal>,
    @Inject(MAT_DIALOG_DATA) public data: TransferImageModalData
  ) {}

  refreshPalettes(palettes: PaletteModel[]) {
    if (palettes) {
      this.palettes = palettes;
      if (palettes.length > 0) {
        this.result.selectedPalette = palettes[0];
      }

      if (
        this.httpService.user.transferSettings &&
        this.httpService.user.transferSettings != ''
      ) {
        var settings = JSON.parse(this.httpService.user.transferSettings);

        let selectedPalette = this.palettes.find(
          (x) => x.paletteId == settings?.selectedPalette?.paletteId
        );

        if (!selectedPalette) {
          selectedPalette = this.palettes[0];
        }

        this.result = <TransferImageModalResult>{
          selectedPalette: selectedPalette,
          selectedTransferPreEffect: this.transferPreEffects.find(
            (x) =>
              x.preEffect ===
              (settings.selectedTransferPreEffect?.preEffect ||
                PreEffectEnum.None)
          ),
          selectedTransferMethod: this.transferMethods.find(
            (x) =>
              x.method ===
              (settings.selectedTransferMethod?.method ||
                MethodEnum.WuQuantizer)
          ),
          selectedTransferDither: this.transferDithers.find(
            (x) =>
              x.dither ===
              (settings.selectedTransferDither?.dither || DitherEnum.None)
          ),
          nColors: settings.nColors,
        };
      } else {
        this.result.nColors = 6;
        this.result.selectedTransferPreEffect = this.transferPreEffects.find(
          (x) => x.preEffect == PreEffectEnum.None
        );
        this.result.selectedTransferMethod = this.transferMethods.find(
          (x) => x.method == MethodEnum.WuQuantizer
        );
        this.result.selectedTransferDither = this.transferDithers.find(
          (x) => x.dither == DitherEnum.None
        );
      }
    }
  }

  async ngOnInit() {
    this.fillTransferPreEffects();
    this.fillTransferMethods();
    this.fillDithers();

    this.refreshPalettes(this.httpService.palettes);

    this.httpService.onPalettesRefreshed.subscribe((palettes) => {
      this.palettes = palettes;
      this.refreshPalettes(palettes);
    });

    gtag('event', 'page_view', {
      page_title: 'Transfer Image (Modal)',
      page_location: `${window.location.origin}/transfer-image-modal`,
    });
  }

  public getDitherDisabled() {
    return (
      this.result.selectedTransferMethod?.method !== MethodEnum.WuQuantizer &&
      this.result.selectedTransferMethod?.method !== MethodEnum.OctreeQuantizer
    );
  }

  public onSelectedTransferMethod() {
    this.result.selectedTransferDither =
      this.result.selectedTransferMethod.method !== MethodEnum.WuQuantizer &&
      this.result.selectedTransferMethod.method !== MethodEnum.OctreeQuantizer
        ? this.transferDithers.find((d) => d.dither === DitherEnum.None)
        : this.result.selectedTransferDither;
  }

  private fillTransferPreEffects() {
    let transferPreEffectNames = [
      'None',
      'Black and White',
      'Grayscale',
      'Sepia',
      'Oil Paint',
      'Polaroid',
      'Vignette',
    ];

    let transferPreEffects = [
      PreEffectEnum.None,
      PreEffectEnum.BlackAndWhite,
      PreEffectEnum.Grayscale,
      PreEffectEnum.Sepia,
      PreEffectEnum.OilPaint,
      PreEffectEnum.Polaroid,
      PreEffectEnum.Vignette,
    ];

    this.transferPreEffects = [];

    for (let t = 0; t < transferPreEffectNames.length; t++) {
      this.transferPreEffects.push(<TransferPreEffectModel>{
        name: transferPreEffectNames[t],
        preEffect: transferPreEffects[t],
      });
    }
  }

  private fillTransferMethods() {
    let transferMethodNames = [
      'Median Cut',
      'Generic Reduce Colors',
      'Remove Nearest',
      'Wu Quantizer',
      'Octree Quantizer',
    ];

    let transferMethods = [
      MethodEnum.MedianCut,
      MethodEnum.DontReduceColorsWithLib,
      MethodEnum.RemoveNearest,
      MethodEnum.WuQuantizer,
      MethodEnum.OctreeQuantizer,
    ];

    this.transferMethods = [];

    for (let t = 0; t < transferMethodNames.length; t++) {
      this.transferMethods.push(<TransferMethodModel>{
        name: transferMethodNames[t],
        method: transferMethods[t],
      });
    }
  }

  private fillDithers() {
    let ditherNames = [
      'None',
      'Atkinson',
      'Burkes',
      'Floyd Steinberg',
      'Jarvis Judice Ninke',
      'Sierra 2',
      'Sierra 3',
      'Sierra Lite',
      'Stevenson Arce',
      'Stucki',
    ];

    let dithers = [
      DitherEnum.None,
      DitherEnum.Atkinson,
      DitherEnum.Burkes,
      DitherEnum.FloydSteinberg,
      DitherEnum.JarvisJudiceNinke,
      DitherEnum.Sierra2,
      DitherEnum.Sierra3,
      DitherEnum.SierraLite,
      DitherEnum.StevensonArce,
      DitherEnum.Stucki,
    ];

    this.transferDithers = [];

    for (let t = 0; t < ditherNames.length; t++) {
      this.transferDithers.push(<TransferDitherModel>{
        name: ditherNames[t],
        dither: dithers[t],
      });
    }
  }

  async onApply(): Promise<void> {
    await this.saveTransferSettings();
    this.onApplyClicked.emit(this.result);
    gtag('event', 'transfer_image', {});
  }

  async onClose(): Promise<void> {
    await this.saveTransferSettings();
    this.dialogRef.close();
  }

  async saveTransferSettings() {
    this.httpService.user.transferSettings = JSON.stringify(this.result);
    await this.httpService.updateUserProfile();
  }
}

import { ErrorHandler, Injectable } from '@angular/core';
import { HttpService } from '../services/http-service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class AppGlobalErrorhandler implements ErrorHandler {
  constructor(
    public httpService: HttpService,
    private deviceService: DeviceDetectorService
  ) {}

  async handleError(error: Error) {
    const errorData = {
      userId: `UserId-${this.httpService.userId}`,
      os: this.deviceService.os,
      deviceType: this.deviceService.deviceType,
      device: this.deviceService.device,
      browser: this.deviceService.browser,
      browserVersion: this.deviceService.browser_version,
      orientation: this.deviceService.orientation,
      error: error.stack,
    };

    gtag('event', 'exception', errorData);

    await this.httpService.sendClientError(errorData);
    throw error;
  }
}

<div
  style="
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 8px;
  "
  [ngClass]="{ 'form-container-padding': includePadding }"
>
  <mat-form-field>
    <mat-select
      *ngIf="palettesListType != palettesListTypes.Edit"
      placeholder="{{ httpService.getTransation().SelectPalette }}"
      [(value)]="selectedPalette"
      (selectionChange)="onPaletteSelected($event)"
    >
      <mat-option *ngFor="let p of palettes" [value]="p">{{
        p.name
      }}</mat-option>
    </mat-select>
    <input
      *ngIf="palettesListType == palettesListTypes.Edit"
      matInput
      placeholder="{{ httpService.getTransation().PaletteNameEdit }}"
      appDelayedChange
      (delayedChange)="onPaletteNameDelayedChange($event)"
      [(value)]="paletteName"
    />
  </mat-form-field>
  <mat-form-field>
    <input
      matInput
      type="text"
      autocomplete="off"
      class="form-control"
      placeholder="{{ httpService.getTransation().Filter }}"
      appDelayedChange
      (delayedChange)="onDelayedChange($event)"
      [(ngModel)]="detailsFilter"
    />
  </mat-form-field>
  <div #paletteList class="form-container">
    <mat-label *ngIf="morePaletteDetails" style="font-size: small">
      {{
        formatString(
          httpService.getTransation().OnlyFirstNBeadPalettes,
          detailsPageSize.toString()
        )
      }}
    </mat-label>
    <mat-radio-group
      *ngIf="palettesListType == palettesListTypes.SingleSelection"
      class="list-group mb-0"
      [(ngModel)]="selectedPaletteDetail"
      (change)="onChange()"
    >
      <mat-radio-button
        class="list-radio-button m-0"
        *ngFor="let pd of paletteDetails"
        [value]="pd"
      >
        <div class="flex-container-horizontal primary">
          <div
            [class]="getBeadClasses(pd.paletteTypeId)"
            [ngStyle]="{ 'background-color': '#' + pd.color }"
          ></div>
          <div class="flex-container-vertical pattern-bead-item">
            <strong class="bead-name mt-5px">{{ pd.name }}</strong>
            <small
              class="bead-description text-ellipsis"
              [matTooltip]="pd.description"
              >{{ pd.description }}</small
            >
          </div>
        </div>
      </mat-radio-button>
    </mat-radio-group>
    <mat-list
      *ngIf="palettesListType != palettesListTypes.SingleSelection"
      role="list"
      class="list-group"
    >
      <mat-list-item
        role="listitem"
        *ngFor="let pd of paletteDetails"
        class="bead-list-item"
      >
        <div class="flex-container-horizontal primary">
          <div
            [class]="getBeadClasses(pd.paletteTypeId)"
            [ngStyle]="{ 'background-color': '#' + pd.color }"
          ></div>
          <div class="flex-container-vertical pattern-bead-item-edit">
            <strong class="bead-name mt-5px">{{ pd.name }}</strong>
            <small
              class="bead-description text-ellipsis"
              [matTooltip]="pd.description"
              >{{ pd.description }}</small
            >
          </div>
          <button
            *ngIf="palettesListType == palettesListTypes.AddItemSelection"
            mat-icon-button
            matTooltip="{{ httpService.getTransation().Add }}"
            (click)="onAddPaletteDetail(pd)"
          >
            <mat-icon>add</mat-icon>
          </button>
          <button
            *ngIf="palettesListType == palettesListTypes.Edit"
            mat-icon-button
            matTooltip="{{ httpService.getTransation().Remove }}"
            (click)="onRemovePaletteDetail(pd)"
          >
            <mat-icon>remove</mat-icon>
          </button>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http-service';
import {
  SocialAuthService,
  GoogleLoginProvider,
} from 'angularx-social-login';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { LocalStorageKeys } from 'src/app/helpers/constants';

export interface LoginModalData {}

export interface LoginModalResult {}

@Component({
  selector: 'login-modal',
  templateUrl: 'login-modal.html',
  styleUrls: ['login-modal.css'],
})
export class LoginModal implements OnInit {
  providersReady: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public httpService: HttpService,
    @Inject(DOCUMENT) private document: Document,
    private authService: SocialAuthService,
    public dialogRef: MatDialogRef<LoginModal>,
    @Inject(MAT_DIALOG_DATA) public data: LoginModalData
  ) {}

  async ngOnInit() {
    gtag('event', 'page_view', {
      page_title: 'Login (Modal)',
      page_location: `${window.location.origin}/login-modal`,
    });

    this.authService.authState.subscribe((user) => {
      if (user) {
        localStorage.setItem(LocalStorageKeys.AuthProvider, user.provider);
        this.dialogRef.close();
        this.httpService.loggedIn = true;
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.router.navigateByUrl(returnUrl);
        gtag('event', 'login', {
          method: user.provider,
        });
      }
    });

    this.authService.initState.subscribe((providersReady) => {
      this.providersReady = providersReady;
    });
  }

  signInWithGoogle(): void {
    this.authService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((d) => console.log(d))
      .catch((error) => {
        console.log(error);
      });
  }

  signOut(): void {
    this.authService.signOut();
  }
}

<div
  mat-dialog-title
  class="modal-title"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <span class="modal-title-label"
    >{{ httpService.getTransation().TransferImage }}</span
  >
  <button
    class="modal-close-button"
    mat-icon-button
    matTooltip="{{ httpService.getTransation().Close }}"
    (click)="onClose()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <div class="form-container">
    <mat-form-field>
      <mat-select
        placeholder="{{ httpService.getTransation().SelectPalette }}"
        [(value)]="result.selectedPalette"
      >
        <mat-option *ngFor="let p of palettes" [value]="p"
          >{{ p.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        type="number"
        placeholder="{{ httpService.getTransation().Colors }}"
        autocomplete="off"
        class="form-control"
        min="2"
        max="100"
        [(ngModel)]="result.nColors"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-select
        placeholder="{{ httpService.getTransation().PreEffect }}"
        [(value)]="result.selectedTransferPreEffect"
      >
        <mat-option *ngFor="let pe of transferPreEffects" [value]="pe"
          >{{ pe.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select
        placeholder="{{ httpService.getTransation().Method }}"
        [(value)]="result.selectedTransferMethod"
        (selectionChange)="onSelectedTransferMethod()"
      >
        <mat-option *ngFor="let tm of transferMethods" [value]="tm"
          >{{ tm.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select
        placeholder="{{ httpService.getTransation().Dither }}"
        [(value)]="result.selectedTransferDither"
        [disabled]="getDitherDisabled()"
      >
        <mat-option *ngFor="let d of transferDithers" [value]="d"
          >{{ d.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button
    mat-raised-button
    color="primary"
    (click)="onApply()"
    [disabled]="result.nColors<1"
  >
    {{ httpService.getTransation().Apply }}
  </button>
  <button mat-raised-button cdkFocusInitial (click)="onClose()">
    {{ httpService.getTransation().Close }}
  </button>
</div>

import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http-service';
import { HomeComponent } from 'src/app/home/home.component';
import { loadStripe } from '@stripe/stripe-js';
import { SubscriptionTypeEnum } from 'src/app/models/user/user-model';
import { environment } from 'src/environments/environment';
import { SUBSCRIPTIONS } from 'src/app/helpers/constants';
import { DOCUMENT } from '@angular/common';

export interface SubscriptionsModalData {
  homeComponent: HomeComponent;
  subscriptionType: SubscriptionTypeEnum;
}

export interface SubscriptionsModalResult {}

@Component({
  selector: 'subscriptions-modal',
  templateUrl: 'subscriptions-modal.html',
})
export class SubscriptionsModal implements OnInit {
  subscriptionTypes = SubscriptionTypeEnum;
  itemsDirection: string;

  constructor(
    public httpService: HttpService,
    @Inject(DOCUMENT) private document: Document,
    public dialogRef: MatDialogRef<SubscriptionsModal>,
    @Inject(MAT_DIALOG_DATA) public data: SubscriptionsModalData
  ) {}

  async ngOnInit() {
    gtag('event', 'page_view', {
      page_title: 'Subscriptions (Modal)',
      page_location: `${window.location.origin}/subscriptions-modal`,
    });
    this.resize();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.resize();
  }

  private resize() {
    console.log('resize', window.innerWidth);
    if (window.innerWidth < 600) {
      this.itemsDirection = 'column';
    } else {
      this.itemsDirection = 'row';
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  async onClickSubscription(subscriptionType: SubscriptionTypeEnum) {
    // console.log('onClickSubscription', subscriptionType);

    const stripe = await loadStripe(environment.stripeKey);

    const data = await this.httpService.createCheckoutSession(subscriptionType);

    const subscription = SUBSCRIPTIONS[subscriptionType - 1];

    const instance = this;

    gtag('event', 'begin_checkout', {
      currency: 'USD',
      value: subscription.price,
      items: [subscription],
      event_callback: function () {
        // Call Stripe.js method to redirect to the new Checkout page
        stripe
          .redirectToCheckout({ sessionId: data.sessionId })
          .then(instance.handleResult);
      },
    });
  }

  handleResult(handleResult: any) {
    console.log('handleResult', handleResult);
  }
}

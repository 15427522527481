import { Point } from './point';

export class Rect {
  public x: number;
  public y: number;
  public width: number;
  public height: number;

  public constructor(
    _x: number = 0,
    _y: number = 0,
    _width: number = 0,
    _height: number = 0
  ) {
    this.x = _x;
    this.y = _y;
    this.width = _width;
    this.height = _height;
  }

  public contains(p: Point): boolean {
    return (
      this.x <= p.x &&
      this.y <= p.y &&
      this.x + this.width >= p.x &&
      this.y + this.height >= p.y
    );
  }

  public intersectRect(otherRect: Rect) {
    return !(
      otherRect.x > this.x + this.width ||
      otherRect.x + otherRect.width < this.x ||
      otherRect.y > this.y + this.height ||
      otherRect.y + otherRect.height < this.y
    );
  }

  public mult(s: number): Rect {
    this.x *= s;
    this.y *= s;
    this.width *= s;
    this.height *= s;

    return this;
  }
}

import { BeadPos } from './beadpos';
import { PatternImage } from './patternimage';
import { Point } from './point';
import { Size } from './size';
import { PaletteDetailModel } from '../models/palettes/palette_detail_model';
import { StitchTypeEnum } from '../models/stitchs/stitch_model';
import { EMPTY_BEAD } from '../helpers/constants';

export class PatternBeadModel {
  private stitchId: number;
  private pos: Point;
  private grid: PaletteDetailModel[][];
  private beadSize: Size = new Size(27, 21); // e.g. 1.6x1.5 - 10x7
  private image: PatternImage;
  private rotation = 0;

  private changeListeners: Array<() => void> = [];

  constructor(rows: number, cols: number) {
    this.stitchId = StitchTypeEnum.Brick;
    this.initGrid(rows, cols);
    this.grid = new Array(rows);

    for (let i = 0; i < rows; i++)
      this.grid[i] = new Array(cols).fill(EMPTY_BEAD);

    this.calcInitialPatternPosition();
  }

  public initGrid(rows: number, cols: number) {
    this.grid = new Array(rows);

    for (let i = 0; i < rows; i++)
      this.grid[i] = new Array(cols).fill(EMPTY_BEAD);
  }

  public initDefaultGrid() {
    this.setStitchId(StitchTypeEnum.Brick);
    this.initGrid(DefaultBeadPatternRows, DefaultBeadPatternCols);
  }

  private calcInitialPatternPosition(): void {
    this.pos = new Point(
      -((this.getCols() + 0.5) * this.beadSize.width) / 2,
      60,
    );
  }

  public getScaledBeadSize = (s: number): Size => {
    return this.beadSize.scalarProduct(s);
  };

  public getBeadSize = (): Size => {
    return this.beadSize;
  };

  public setBeadSize(beadSize: Size): void {
    this.beadSize = beadSize;
  }

  public getRows(): number {
    if (this.grid.length > 0) {
      return this.grid.length;
    } else {
      return 1;
    }
  }

  public getCols(): number {
    if (this.grid.length > 0) {
      return this.grid[0].length;
    } else {
      return 1;
    }
  }

  public getBeadAt(r: number, c: number): PaletteDetailModel {
    return this.grid[r][c];
  }

  public setBeadAt(bead: PaletteDetailModel, r: number, c: number) {
    this.grid[r][c] = bead;
    this.fireModelChanged();
  }

  public addModelChangeListener(mcl: () => void): void {
    this.changeListeners.push(mcl);
  }

  public setPatternImage = (pi: PatternImage): void => {
    this.image = pi;
    this.fireModelChanged();
  };

  public updatePatternImage = (
    pi: PatternImage,
    p: Point = null,
    s: Size = null,
  ): void => {
    if (p) pi.pos = p;
    if (s) pi.size = s;
    this.fireModelChanged();
  };

  public getPatternImage = (): PatternImage => {
    return this.image;
  };

  private fireModelChanged(): void {
    for (let mcl of this.changeListeners) mcl();
  }

  public setStitchId(stitchId: number): void {
    this.stitchId = stitchId;
  }

  public getStitchId(): number {
    return this.stitchId;
  }

  public getPosition(): Point {
    return this.pos;
  }

  public setPosition(pos: Point): void {
    this.pos = pos;
  }

  public getGrid(): PaletteDetailModel[][] {
    return this.grid;
  }

  public setGrid(grid: PaletteDetailModel[][]): void {
    this.grid = grid;
  }

  public addRows(rows: PaletteDetailModel[][]): void {
    for (let i = 0; i < rows.length; i++) this.grid.push(rows[i]);
  }

  public removeRows(num: number): PaletteDetailModel[][] {
    let rr: PaletteDetailModel[][] = new Array(num);

    for (let i = num - 1; i >= 0; i--) {
      rr[i] = this.grid.pop();
    }

    return rr;
  }

  public addCols(cols: PaletteDetailModel[][]): void {
    for (let i = 0; i < this.grid.length; i++) {
      if (i < cols.length) {
        for (let j = 0; j < cols[i].length; j++) {
          this.grid[i].push(cols[i][j]);
        }
      }
    }
  }

  public removeCols(cn: number): PaletteDetailModel[][] {
    let cols: PaletteDetailModel[][] = new Array(this.getCols());
    for (let i = 0; i < this.getRows(); i++) {
      cols[i] = new Array(cn);
      for (let j = cn - 1; j >= 0; j--) cols[i][j] = this.grid[i].pop();
    }

    return cols;
  }

  public getRotation(): number {
    return this.rotation;
  }

  public setRotation(newRot: number) {
    this.rotation = newRot;
  }
}

export const DefaultBeadPatternRows = 50;
export const DefaultBeadPatternCols = 30;

import {
  Component,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { HttpService } from '../services/http-service';
import { MatDialog } from '@angular/material/dialog';
import { BeadCanvas, EditMode } from '../peditor/beadcanvas';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
} from 'angularx-social-login';
import { ProfileModal } from '../modals/profile-modal/profile-modal';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LocalStorageKeys } from '../helpers/constants';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css'],
})
export class ToolbarComponent {
  @Input()
  windowWidth: number;
  @Input()
  windowHeight: number;

  @Input()
  bcanvas: BeadCanvas;
  @Output()
  toolbarActionFired: EventEmitter<ToolbarActionFired> =
    new EventEmitter<ToolbarActionFired>();

  @ViewChild('fileUpload', { static: true })
  private fileUpload: ElementRef;

  @ViewChild('dotsMenu', { static: true })
  private dotsMenu: ElementRef;

  editModes = EditMode;

  iconNameImageVisibility: string = 'visibility';
  iconNameRealisticBeadsVisibility: string = 'label_off';

  currentRotatedPatternTooltip: string = '';
  isRotatedPattern: boolean = false;

  minMainMenuWidth = 430;

  minFullToolbarWidth = 510;
  minMediumToolbarWidth = 430;
  minSmallToolbarWidth = 330;

  undoShortcut = '';
  redoShortcut = '';

  constructor(
    private router: Router,
    public httpService: HttpService,
    private authService: SocialAuthService,
    public dialog: MatDialog,
    private deviceService: DeviceDetectorService
  ) {
    if (this.deviceService.isDesktop()) {
      this.undoShortcut = '(Cmd/Ctrl + Z)';
      this.redoShortcut = '(Ctrl + Y / Cmd + Shift + Z)';
    }
  }

  async ngOnInit(): Promise<void> {
    const nativeButton = (this.dotsMenu as any)._elementRef
      .nativeElement as HTMLElement;
    if (this.windowWidth <= this.minMainMenuWidth) {
      nativeButton.setAttribute(
        'data-hint',
        '<b>Image => Insert Image</b> to upload your own image.<br/><br/><hr/><br/><b>Image => Transfer Image</b> to convert uploaded image into a pattern<br/><br/>'
      );
      nativeButton.setAttribute('data-hint-position', 'bottom-middle');
    }
  }

  public refreshRotatedTooltip() {
    this.isRotatedPattern = this.bcanvas.isRotatedPattern();
    this.currentRotatedPatternTooltip = this.getCurrentRotatedPatternTooltip();
  }

  async newPattern() {
    this.toolbarActionFired.emit(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.New,
    });
  }

  async openPattern() {
    this.toolbarActionFired.emit(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.Open,
    });
  }

  async savePattern() {
    this.toolbarActionFired.emit(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.Save,
    });
  }

  canSavePattern(): boolean {
    return this.httpService && this.httpService.canSavePattern();
  }

  async saveAsPattern() {
    this.toolbarActionFired.emit(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.SaveAs,
    });
  }

  async printPattern() {
    this.toolbarActionFired.emit(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.Print,
    });
  }

  async uploadImage(files) {
    this.toolbarActionFired.emit(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.UploadImage,
      parameters: files,
    });

    this.fileUpload.nativeElement.value = '';
  }

  setEditMode(editMode: EditMode) {
    this.bcanvas.setEditMode(editMode);
  }

  getCurrentEditModeIcon(): string {
    switch (this.bcanvas.getEditMode()) {
      case EditMode.AddBead:
        return 'edit';
      case EditMode.Fill:
        return 'format_paint';
      case EditMode.RemoveBead:
        return 'clear';
      case EditMode.RemoveFill:
        return 'format_clear';
      case EditMode.PickBead:
        return 'colorize';
      case EditMode.Pan:
        return 'pan_tool';
    }

    return '';
  }

  getCurrentEditModeTooltip(): string {
    switch (this.bcanvas.getEditMode()) {
      case EditMode.AddBead:
        return this.httpService.getTransation().AddBead;
      case EditMode.Fill:
        return this.httpService.getTransation().Fill;
      case EditMode.RemoveBead:
        return this.httpService.getTransation().RemoveBead;
      case EditMode.RemoveFill:
        return this.httpService.getTransation().RemoveFill;
      case EditMode.PickBead:
        return this.httpService.getTransation().PickBead;
      case EditMode.Pan:
        return this.httpService.getTransation().Pan;
    }

    return '';
  }

  getCurrentRotatedPatternTooltip(): string {
    if (this.bcanvas.isRotatedPattern()) {
      return this.httpService.translation.RotatedPatternOn;
    } else {
      return this.httpService.translation.RotatedPatternOff;
    }
  }

  toggleRotatedPattern() {
    this.bcanvas.rotatedPattern(!this.bcanvas.isRotatedPattern());
    this.refreshRotatedTooltip();
  }

  setRotatedPattern(rotated: boolean) {
    this.bcanvas.rotatedPattern(rotated);
  }

  canToggleImageVisibility() {
    return this.bcanvas.getPatternImage();
  }

  isImageVisible() {
    return this.iconNameImageVisibility == 'visibility';
  }

  rotateImageLeft() {
    if (this.bcanvas) {
      this.bcanvas.rotateImageLeft();
    }
  }

  rotateImageRight() {
    if (this.bcanvas) {
      this.bcanvas.rotateImageRight();
    }
  }

  refreshIconNameImageVisibility(): string {
    if (this.bcanvas.getModel().getPatternImage()) {
      if (this.bcanvas.getModel().getPatternImage().visible) {
        return (this.iconNameImageVisibility = 'visibility');
      } else {
        return (this.iconNameImageVisibility = 'visibility_off');
      }
    } else {
      return (this.iconNameImageVisibility = 'visibility');
    }
  }

  toggleImageVisibility() {
    this.toolbarActionFired.emit(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.ToggleImageVisibility,
    });
    this.refreshIconNameImageVisibility();
  }

  refreshIconNameRealisticBeadsVisibility(): string {
    if (this.bcanvas.isRealisticBeadsEnabled()) {
      return (this.iconNameRealisticBeadsVisibility = 'label');
    } else {
      return (this.iconNameRealisticBeadsVisibility = 'label_off');
    }
  }

  toggleRealisticBeadsEnabled() {
    this.toolbarActionFired.emit(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.ToggleRealisticBeadsEnabled,
    });
    this.refreshIconNameRealisticBeadsVisibility();
  }

  showPaletteEditor(): void {
    this.toolbarActionFired.emit(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.PaletteEditor,
    });
  }

  showSubscription(): void {
    this.toolbarActionFired.emit(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.ShowSubscription,
    });
  }

  canTransferImage(): boolean {
    return this.bcanvas.getPatternImage() !== undefined;
  }

  canRotateImage(): boolean {
    return (
      this.bcanvas.getPatternImage() !== undefined && this.isImageVisible()
    );
  }

  async transferImage(): Promise<void> {
    this.toolbarActionFired.emit(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.TransferImage,
    });
  }

  showTransformPattern(): void {
    this.toolbarActionFired.emit(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.TransformPattern,
    });
  }

  canUndo(): boolean {
    return this.bcanvas && this.bcanvas.canUndo();
  }

  undo(): void {
    if (this.bcanvas) {
      this.bcanvas.undo();
    }
  }

  canRedo(): boolean {
    return this.bcanvas && this.bcanvas.canRedo();
  }

  redo(): void {
    if (this.bcanvas) {
      this.bcanvas.redo();
    }
  }

  canZoomIn(): boolean {
    return this.bcanvas && this.bcanvas.canZoomIn();
  }

  zoomIn(): void {
    if (this.bcanvas) {
      this.bcanvas.zoomIn();
    }
  }

  canZoomOut(): boolean {
    return this.bcanvas && this.bcanvas.canZoomOut();
  }

  zoomOut(): void {
    if (this.bcanvas) {
      this.bcanvas.zoomOut();
    }
  }

  canShowRealisticBeads(): boolean {
    return this.bcanvas && this.bcanvas.canShowRealisticBeads();
  }

  toggleRightSidebar(): void {
    this.toolbarActionFired.emit(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.ToggleRightSidebar,
    });
  }

  getPhotoUrl() {
    if (
      this.httpService.socialUser &&
      this.httpService.socialUser.photoUrl &&
      this.httpService.socialUser.photoUrl != ''
    ) {
      return this.httpService.socialUser.photoUrl;
    } else {
      return 'https://www.w3schools.com/howto/img_avatar.png';
    }
  }

  getFullname() {
    if (this.httpService.socialUser)
      return (
        this.httpService.socialUser.firstName +
        ' ' +
        this.httpService.socialUser.lastName +
        ' (' +
        this.getProviderName(this.httpService.socialUser.provider) +
        ')'
      );
    else return 'Anonymous';
  }

  getProviderName(provider: string) {
    if (provider == FacebookLoginProvider.PROVIDER_ID) {
      return this.httpService.getTransation().Facebook;
    } else if (provider == GoogleLoginProvider.PROVIDER_ID) {
      return this.httpService.getTransation().Google;
    }
  }

  public async showProfile() {
    const dialogRef = this.dialog.open(ProfileModal, {
      width: '90%',
      maxWidth: '250px',
      data: {
        unitOfMeasure: this.httpService.user.unitOfMeasure,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        this.httpService.user.unitOfMeasure = result.unitOfMeasure;
        await this.httpService.updateUserProfile();
      }
    });
  }

  async signOut() {
    localStorage.removeItem(LocalStorageKeys.AuthProvider);
    if (this.httpService.loggedIn) {
      await this.authService.signOut();
    }

    this.router.navigateByUrl('login');
  }

  welcomeTour() {
    this.toolbarActionFired.emit(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.StartWelcomeTour,
    });
  }

  videoTutorial() {
    this.toolbarActionFired.emit(<ToolbarActionFired>{
      actionType: ToolbarActionFiredTypeEnum.VideoTutorial,
    });
  }
}

export class ToolbarActionFired {
  actionType: ToolbarActionFiredTypeEnum;
  parameters: any;
}

export enum ToolbarActionFiredTypeEnum {
  New,
  Open,
  Save,
  SaveAs,
  Print,
  PaletteEditor,
  UploadImage,
  TransferImage,
  TransformPattern,
  ToggleImageVisibility,
  ToggleRealisticBeadsEnabled,
  ShiftLeft,
  ShiftRight,
  ShiftUp,
  ShiftDown,
  RotateLeft,
  RotateRight,
  FlipVertically,
  FlipHorizontally,
  RotateImageLeft,
  RotateImageRight,
  RotatedPatternOn,
  RotatedPatternOff,
  ShowSubscription,
  ToggleRightSidebar,
  StartWelcomeTour,
  VideoTutorial,
}

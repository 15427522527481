import { PaletteTypeEnum } from '../models/palettes/palette_detail_model';
import { CategoryModel } from '../models/pattern/category-model';
import {
  ConfirmModalData,
  ConfirmModal,
} from '../modals/confirm-modal/confirm-modal';
import { MatDialog } from '@angular/material/dialog';
import { UnitOfMeasureEnum } from '../services/http-service';
import { StitchTypeEnum } from '../models/stitchs/stitch_model';
import { RealBeadImageModel } from '../models/palettes/real_bead_image_model';
import {
  NotificationComponent,
  NotificationTypeEnum,
} from '../components/notification/notification.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaletteModel } from '../models/palettes/palette_model';

type ConfirmCallback = (data: ConfirmModalData) => void;

export class Utility {
  static nonePatternsCategory = <CategoryModel>{
    categoryId: 0,
    userId: null,
    name: '',
    description: '',
    createdOn: null,
    lastUpdateOn: null,
  };

  static convertBeadsToSize(
    stitchTypeId: StitchTypeEnum,
    nBeads: number,
    beadType: BeadTypeEnum,
    beadMeasure: BeadMeasureEnum,
    unitOfMeasure: UnitOfMeasureEnum
  ): string {
    let beadSize;
    let beadUnitOfMeasure;

    const millimetersToCentimetersRatio = 0.1;
    const millimetersToInchRatio = 0.03937007874;

    switch (beadType) {
      case BeadTypeEnum.MiyukiDelica11:
        switch (stitchTypeId) {
          case StitchTypeEnum.Brick:
            beadSize = new BeadSize(1.7587, 1.35);
            break;

          case StitchTypeEnum.Peyote:
            beadSize = new BeadSize(1.35, 1.7587);
            break;

          case StitchTypeEnum.Loom:
            beadSize = new BeadSize(1.356, 1.75);
            break;
        }
        break;
    }

    if (unitOfMeasure == UnitOfMeasureEnum.Cm) {
      beadUnitOfMeasure = 'cm';
    } else if (unitOfMeasure == UnitOfMeasureEnum.Mm) {
      beadUnitOfMeasure = 'mm';
    } else {
      beadUnitOfMeasure = 'in';
    }

    let ratio: number;

    switch (unitOfMeasure) {
      case UnitOfMeasureEnum.Cm:
        ratio = millimetersToCentimetersRatio;
        break;
      case UnitOfMeasureEnum.Mm:
        ratio = 1;
        break;

      case UnitOfMeasureEnum.Inch:
      default:
        ratio = millimetersToInchRatio;
        break;
    }

    if (beadMeasure == BeadMeasureEnum.Width)
      return (beadSize.width * nBeads * ratio).toFixed(2) + beadUnitOfMeasure;
    else
      return (beadSize.height * nBeads * ratio).toFixed(2) + beadUnitOfMeasure;
  }

  static getBeadClasses(paletteTypeId: number): string {
    let retVal = 'bead-image-palette ';

    switch (paletteTypeId) {
      case PaletteTypeEnum.Metallic:
        retVal += 'bead-metallic';
        break;

      case 3:
        retVal += 'bead-crystal';
        break;

      case 4:
        retVal += 'bead-transparent';
        break;

      case 5:
        retVal += 'bead-opaque';
        break;

      case 6:
        retVal += 'bead-matte';
        break;

      default:
        retVal += 'bead-matte';
        break;
    }

    return retVal;
  }

  static async delay(ms: number) {
    await new Promise<void>((resolve) => setTimeout(() => resolve(), ms)).then(
      () => console.log('fired')
    );
  }

  static confirmMessage(
    dialog: MatDialog,
    title: string,
    message: string,
    callback: ConfirmCallback
  ) {
    const dialogRef = dialog.open(ConfirmModal, {
      width: '500px',
      data: {
        title: title,
        message: message,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      let data = <ConfirmModalData>result;
      if (data) {
        // console.log(data);
        callback(data);
      }
    });
  }

  static formatString(str: string, ...val: string[]) {
    for (let index = 0; index < val.length; index++) {
      str = str.replace(`{${index}}`, val[index]);
    }

    return str;
  }

  static getRealBeadPosition(name: string): number[] {
    for (
      let nRow = 0;
      nRow < RealBeadImageModel.realBeadImages.length;
      nRow++
    ) {
      var row = RealBeadImageModel.realBeadImages[nRow];

      for (let nCol = 0; nCol < row.length; nCol++) {
        var beadName = row[nCol];

        if (beadName == name) {
          return [
            nCol * (RealBeadImageModel.width + RealBeadImageModel.padding),
            nRow * (RealBeadImageModel.height + RealBeadImageModel.padding),
          ];
        }
      }
    }

    return null;
  }

  static hasTouchScreen(): boolean {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

  static showNotification(
    snackBar: MatSnackBar,
    message: string,
    type: NotificationTypeEnum
  ): void {
    let className = '';

    switch (type) {
      case NotificationTypeEnum.Information:
        className = 'information-snackbar';
        break;
      case NotificationTypeEnum.Warning:
        className = 'warning-snackbar';
        break;
      case NotificationTypeEnum.Error:
        className = 'error-snackbar';
        break;
    }

    snackBar.openFromComponent(NotificationComponent, {
      duration: 5 * 1000,
      data: {
        message: message,
        type: type,
      },
      horizontalPosition: 'right',
      panelClass: [className],
    });
  }

  static traceLog(message: string): void {
    console.log(message);
    const stack = new Error().stack;
    const stackLines = stack.split('\n');
    stackLines.splice(0, 2);
    const last3Functions = stackLines.slice(0, 3).join('\n');
    console.log(last3Functions);
  }

  static getPalettesList(
    palettes: PaletteModel[],
    includeBuiltIn: boolean,
    includeCustom: boolean
  ): PaletteModel[] {
    return (
      palettes?.filter(
        (p) =>
          p.builtIn == includeBuiltIn ||
          (!p.builtIn && includeCustom)
      ) || []
    );
  }
}

class BeadSize {
  width: number;
  height: number;

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }
}

export enum BeadTypeEnum {
  MiyukiDelica11,
}

export enum BeadMeasureEnum {
  Width,
  Height,
}

<mat-radio-group #paletteList class="list-group mb-0" [(ngModel)]="selectedPaletteDetail" (change)="onChange()">
  <mat-radio-button class="list-radio-button m-0" id="palette-item-{{i}}"
    *ngFor="let pd of paletteDetails; let i = index" [value]="pd">
    <div class="flex-container-horizontal primary">
      <div [class]="getBeadClasses(pd.paletteTypeId)" [ngStyle]="{'background-color': '#' + pd.color}">
      </div>
      <div class="flex-container-vertical pattern-bead-item">
        <span class="mt-3px">
          <strong class="bead-name">{{ pd.name }}</strong><strong
            class="bead-count">{{ ' (' + pd.count + ')' }}</strong>
        </span>
        <small class="bead-description text-ellipsis" [matTooltip]="pd.description">{{ pd.description }}</small>
      </div>
      <button mat-icon-button [matMenuTriggerFor]="menuPatternBead">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menuPatternBead="matMenu">
        <button mat-menu-item (click)="openReplaceBeadModal(pd)">
          <mat-icon>find_replace</mat-icon>
          <span>{{ httpService.getTransation().ReplaceBeadWith }}</span>
        </button>
      </mat-menu>
    </div>
  </mat-radio-button>
</mat-radio-group>
import {
  Component,
  Output,
  EventEmitter,
  ViewChild,
  Input,
  ElementRef,
  HostListener,
} from '@angular/core';
import { BeadCanvas } from '../peditor/beadcanvas';
import { PaletteDetailModel } from '../models/palettes/palette_detail_model';
import { Utility } from '../helpers/utility';
import { MatDialog } from '@angular/material/dialog';
import { ReplaceBeadModal } from '../modals/replace-bead-modal/replace-bead-modal';
import { HttpService } from '../services/http-service';

@Component({
  selector: 'app-pattern-palettes-list',
  templateUrl: './pattern-palettes-list.component.html',
  styleUrls: ['./pattern-palettes-list.component.css'],
})
export class PatternPalettesListComponent {
  @ViewChild('paletteList', { static: true })
  private paletteList: ElementRef<HTMLCanvasElement>;

  @Input()
  height: number;

  @Input()
  bcanvas: BeadCanvas;

  @Output()
  selectedPaletteDetailChanged: EventEmitter<PaletteDetailModel> =
    new EventEmitter<PaletteDetailModel>();

  selectedPaletteDetail: PaletteDetailModel;
  paletteDetails: PaletteDetailModel[] = [];

  windowWidth: number;
  windowHeight: number;

  constructor(public httpService: HttpService, public dialog: MatDialog) {}

  async ngOnInit() {
    this.refreshPaletteDetails();

    this.resize();

    this.bcanvas.onCurrentBeadChanged.subscribe(
      async (currentBead: PaletteDetailModel) => {
        this.selectedPaletteDetail = this.paletteDetails.find(
          (x) => x.paletteDetailId == currentBead.paletteDetailId
        );
      }
    );
  }

  private resize() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.paletteList.nativeElement.style.height = this.height + 'px';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resize();
  }

  openReplaceBeadModal(sourceBead: PaletteDetailModel): void {
    const dialogRef = this.dialog.open(ReplaceBeadModal, {
      width: '500px',
      data: {
        paletteListHeight: this.windowHeight - 325,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log('The dialog was closed');
      // console.log(result);

      let targetBead = <PaletteDetailModel>result;

      if (sourceBead && targetBead) {
        this.replaceBead(sourceBead, targetBead);
      }
    });
  }

  replaceBead(
    sourceBead: PaletteDetailModel,
    targetBead: PaletteDetailModel
  ): void {
    this.bcanvas.controller.replaceBeads(sourceBead, targetBead);
    this.refreshPaletteDetails();
    this.bcanvas.repaint();
  }

  getBeadClasses(paletteTypeId: number): string {
    return Utility.getBeadClasses(paletteTypeId);
  }

  public async refreshPaletteDetails(): Promise<void> {
    if (this.bcanvas.getModel() != null) {
      this.paletteDetails = [];

      let dPaletteDetails = new Map<string, PaletteDetailModel>();

      let grid = this.bcanvas.getData().grid;

      for (let nRow = 0; nRow < grid.length; nRow++) {
        for (let nCol = 0; nCol < grid[0].length; nCol++) {
          let pd = grid[nRow][nCol];

          if (pd && pd.paletteDetailId != -1) {
            let existingPd: PaletteDetailModel;

            if (!dPaletteDetails.has(pd.color)) {
              existingPd = <PaletteDetailModel>{
                paletteId: pd.paletteId,
                paletteDetailId: pd.paletteDetailId,
                paletteBeadId: pd.paletteBeadId,
                paletteTypeId: pd.paletteTypeId,
                name: pd.name,
                description: pd.description,
                color: pd.color,
                count: 1,
              };
              dPaletteDetails.set(pd.color, existingPd);
            } else {
              existingPd = dPaletteDetails.get(pd.color);
              existingPd.count++;
            }

            if (this.selectedPaletteDetail) {
              if (
                existingPd.paletteDetailId ==
                this.selectedPaletteDetail.paletteDetailId
              ) {
                this.selectedPaletteDetail = existingPd;
              }
            }

            this.paletteDetails = Array.from(dPaletteDetails.values());
            this.paletteDetails.sort((a, b) => (a.name > b.name ? 1 : -1));
          }
        }
      }
    }
  }

  focusOnPaletteDetail(paletteDetail: PaletteDetailModel) {
    let idx = this.paletteDetails.findIndex(
      (pd) => pd.paletteDetailId == paletteDetail.paletteDetailId
    );

    const itemToScrollTo = document.getElementById('palette-item-' + idx);
    // null check to ensure that the element actually exists
    if (itemToScrollTo) {
      itemToScrollTo.scrollIntoView(true);
    }
  }

  onChange() {
    this.selectedPaletteDetailChanged.emit(this.selectedPaletteDetail);
  }
}

import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService, UnitOfMeasureEnum } from 'src/app/services/http-service';
import { ToolbarComponent } from 'src/app/toolbar/toolbar.component';

export interface ProfileModalData {
  unitOfMeasure: UnitOfMeasureEnum;
}

export interface SettingsModalResult {
  unitOfMeasure: UnitOfMeasureEnum;
}

@Component({
  selector: 'profile-modal',
  templateUrl: 'profile-modal.html',
  styleUrls: ['profile-modal.css'],
})
export class ProfileModal implements OnInit {
  unitOfMeasure: UnitOfMeasureEnum;
  unitOfMeasures = UnitOfMeasureEnum;

  constructor(
    public httpService: HttpService,
    public dialogRef: MatDialogRef<ProfileModal>,
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: ProfileModalData
  ) {
    this.unitOfMeasure = data.unitOfMeasure;
  }

  async ngOnInit() {
    gtag('event', 'page_view', {
      page_title: 'Profile (Modal)',
      page_location: `${window.location.origin}/profile-modal`,
    });
  }

  onSave(): void {
    this.dialogRef.close({
      unitOfMeasure: this.unitOfMeasure,
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }
}

import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: 'input[appDelayedChange]',

})
export class DelayedChangeDirective {
    @Output()
    delayedChange: EventEmitter<string> = new EventEmitter<string>();

    filterTimeout: any;

    constructor(private el: ElementRef) { }

    @HostListener('keydown') onKeyDown() {
        clearTimeout(this.filterTimeout);
        this.filterTimeout = setTimeout(async () => {
            this.delayedChange.emit(this.el.nativeElement.value);
        }, 500);
    }
}
import { environment } from 'src/environments/environment';
import { PaletteDetailModel } from '../models/palettes/palette_detail_model';

export const SUBSCRIPTIONS = [
  {
    item_id: environment.stripeKey.startsWith('pk_live_')
      ? 'price_1IeZoRBjrdGenLaHkb0Rg9Rl'
      : 'price_1IeZf3BjrdGenLaH3SGtHuiq',
    item_name: 'Monthly',
    currency: 'USD',
    index: 0,
    price: 5.0,
    quantity: 1,
  },
  {
    item_id: environment.stripeKey.startsWith('pk_live_')
      ? 'price_1IeZoQBjrdGenLaHA2EU3lTk'
      : 'price_1IeZf3BjrdGenLaHNWCzpoU5',
    item_name: 'Yearly',
    currency: 'USD',
    index: 0,
    price: 50.0,
    quantity: 1,
  },
];

export enum LocalStorageKeys {
  AuthProvider = 'auth_provider',
  StartWelcomeTour = 'start_tour',
  HintUploadImage = 'hint-upload-image',
  HintMoveAndScaleImage = 'hint-move-and-scale-image',
  HintTransferImage = 'hint-transfer-image',
}

export enum HintSteps {
  UploadImage = 0,
  TransferImage = 1,
  MoveAndScaleImage = 2,
}

export const DEFAULT_ZOOM_FACTOR = 0.35;

export const EMPTY_BEAD: PaletteDetailModel = <PaletteDetailModel>{
  paletteId: -1,
  paletteDetailId: -1,
  paletteBeadId: -1,
  name: 'empty',
  description: '',
  paletteTypeId: 0,
  color: '000000',
  count: 0,
};

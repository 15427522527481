export class Size {
    public width: number;
    public height: number;
    
    constructor(w: number = 0, h: number = 0) {
        this.width = w;
        this.height = h;
    }
    
    public scalarProduct = (s: number): Size => {
        return new Size(this.width * s, this.height * s);
    }

    public mult(s: number): Size {
        this.width *= s;
        this.height *= s;

        return this;
    }
}
import { Injectable, Inject, EventEmitter } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { PatternBeadModel } from '../peditor/patternbeadmodel';
import { BeadCanvas } from '../peditor/beadcanvas';
import { PatternBead } from '../models/pattern/pattern-bead';
import { PaletteDetailModel } from '../models/palettes/palette_detail_model';
import {
  PaletteModel,
  MethodEnum,
  DitherEnum,
  PreEffectEnum,
} from '../models/palettes/palette_model';
import { CategoryModel } from '../models/pattern/category-model';
import { TranslationBase } from '../models/translations/translations';
import { Size } from '../peditor/size';
import { RotateDirectionEnum } from '../peditor/patternmodelcontroller';
import { SubscriptionTypeEnum, UserModel } from '../models/user/user-model';
import { SocialUser } from 'angularx-social-login';
import { Guid } from 'guid-typescript';
import { PatternModel } from '../models/pattern/pattern-model';
import { ClientErrorModel } from '../models/general/client-error';
import { EMPTY_BEAD } from '../helpers/constants';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  VERSION: string = '1.0.1';
  userId: string;
  imageFilename: string;
  bcanvas: BeadCanvas;
  lastActionId = 0;
  progress: number;
  user: UserModel;
  socialUser: SocialUser;
  loggedIn: boolean;
  translation: TranslationBase = null;
  imageUrl: string = '';
  palettes: PaletteModel[];
  onPalettesRefreshed: EventEmitter<PaletteModel[]> = new EventEmitter<
    PaletteModel[]
  >();

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
  ) {
    this.translation = new TranslationBase();
  }

  getAllPatternsCategory(): CategoryModel {
    return <CategoryModel>{
      categoryId: 0,
      userId: null,
      name: this.getTransation().All,
      description: this.getTransation().AllPatterns,
      createdOn: null,
      lastUpdateOn: null,
    };
  }

  getTransation(): TranslationBase {
    return this.translation;
  }

  setBCanvas(bcanvas: BeadCanvas) {
    this.bcanvas = bcanvas;
    this.updateLastActionId();
  }

  dirtyLastActionId() {
    this.lastActionId = -1;
  }

  updateLastActionId() {
    this.lastActionId = this.bcanvas.getLastId();
    // console.log("lastActionId => " + this.lastActionId);
  }

  async login() {
    this.user = await this.http
      .post<UserModel>(this.baseUrl + 'user/login', {
        userId: this.userId,
        detectedLanguage: navigator.language,
      })
      .toPromise();

    this.translation = new TranslationBase();
    this.refreshPalettes();
  }

  async updateUserProfile() {
    this.user = await this.http
      .post<UserModel>(this.baseUrl + 'user/updateuserprofile', {
        userId: this.userId,
        unitOfMeasure: this.user.unitOfMeasure,
        language: this.user.language,
        transferSettings: this.user.transferSettings,
      })
      .toPromise();
  }

  /*
   * General utils for managing cookies in Typescript.
   */
  // setCookie(name: string, val: string) {
  //   const date = new Date();
  //   const value = val;

  //   // Set it expire in 10 years
  //   date.setTime(date.getTime() + (10 * 365 * 24 * 60 * 60 * 1000));

  //   // Set it
  //   document.cookie = name + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
  // }

  // getCookie(name: string) {
  //   const value = "; " + document.cookie;
  //   const parts = value.split("; " + name + "=");

  //   if (parts.length == 2) {
  //     return parts.pop().split(";").shift();
  //   }
  // }

  deleteCookie(name: string) {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

    // Set it
    document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/';
  }

  async rotateImage(rotateDirection: RotateDirectionEnum) {
    await this.http
      .post<boolean>(this.baseUrl + 'pattern/rotateimage', {
        rotateDirection: rotateDirection,
        imageFilename: this.user.imageFilename,
      })
      .toPromise();

    var pi = this.bcanvas.getModel().getPatternImage();

    if (pi) {
      this.bcanvas.getModel().getPatternImage().size = new Size(
        pi.size.height,
        pi.size.width,
      );
      this.refreshImage();
    }
  }

  async getPattern(patternId: number): Promise<PatternModel> {
    let patternModel = await this.http
      .post<PatternModel>(this.baseUrl + 'pattern/getpattern', {
        PatternId: patternId,
        UserId: this.userId,
      })
      .toPromise();

    return patternModel;
  }

  async deletePattern(pattern: PatternModel) {
    await this.http
      .post<boolean>(this.baseUrl + 'pattern/delete', pattern)
      .toPromise();
  }

  async addCategory(category: CategoryModel): Promise<CategoryModel> {
    category.userId = this.userId;
    return await this.http
      .post<CategoryModel>(this.baseUrl + 'pattern/addcategory', category)
      .toPromise();
  }

  async editCategory(category: CategoryModel): Promise<CategoryModel> {
    return await this.http
      .post<CategoryModel>(this.baseUrl + 'pattern/editcategory', category)
      .toPromise();
  }

  async deleteCategory(category: CategoryModel): Promise<void> {
    await this.http
      .post<boolean>(this.baseUrl + 'pattern/deletecategory', category)
      .toPromise();
  }

  async getPatterns(
    categoryId: number,
    searchFilter: string,
  ): Promise<PatternModel[]> {
    return await this.http
      .post<PatternModel[]>(this.baseUrl + 'pattern/getpatterns', {
        UserId: this.userId,
        CategoryId: categoryId,
        SearchFilter: searchFilter,
      })
      .toPromise();
  }

  async updatePattern(pattern: PatternModel) {
    return await this.http
      .post<PatternModel>(this.baseUrl + 'pattern/updatepattern', {
        UserId: this.userId,
        PatternId: pattern.patternId,
        Name: pattern.name?.trim() || '',
        Description: pattern.description?.trim() || '',
        CategoryId: pattern.categoryId,
      })
      .toPromise();
  }

  async savePattern(
    pattern: PatternModel,
    patternBead: PatternBead,
    isTemporary: boolean,
  ) {
    await this.http
      .post<PatternModel>(this.baseUrl + 'pattern/savepattern', {
        PatternId: pattern.patternId,
        Name: pattern.name?.trim() || '',
        Description: pattern.description?.trim() || '',
        CategoryId: pattern.categoryId,
        UserId: this.userId,
        PatternData: this.serializePatternBead(patternBead), //JSON.stringify(patternBead),
        IsTemporary: isTemporary,
        ImageFilename: this.user.imageFilename,
      })
      .toPromise();

    if (!isTemporary) this.updateLastActionId();
  }

  deserializePatternBead(patternData: string): PatternBead {
    let patternBead = new PatternBead();

    let data = JSON.parse(patternData);

    patternBead.stitchId = data.stitchId;
    patternBead.posX = data.posX;
    patternBead.posY = data.posY;
    patternBead.beadWidth = data.beadWidth;
    patternBead.beadHeight = data.beadHeight;
    patternBead.image = data.image;
    patternBead.grid = [];
    patternBead.rotation = data.rotation;

    let grid = data.grid;
    let paletteDetails = <PaletteDetailModel[]>data.paletteDetails;

    for (let nRow = 0; nRow < grid.length; nRow++) {
      let row: PaletteDetailModel[] = [];

      for (let nCol = 0; nCol < grid[0].length; nCol++) {
        let paletteBeadId = grid[nRow][nCol];

        let pd: PaletteDetailModel;

        if (paletteBeadId !== -1) {
          pd = paletteDetails.find((x) => x.paletteBeadId === paletteBeadId);
        } else {
          pd = EMPTY_BEAD;
        }

        row.push(pd);
      }

      patternBead.grid.push(row);
    }

    return patternBead;
  }

  serializePatternBead(patternBead: PatternBead): string {
    let paletteDetails: PaletteDetailModel[] = [];
    let grid = [];

    for (let nRow = 0; nRow < patternBead.grid.length; nRow++) {
      const gridRow = patternBead.grid[nRow];

      let row = [];
      for (let nCol = 0; nCol < gridRow.length; nCol++) {
        let pd = patternBead.grid[nRow][nCol];

        if (pd) {
          row.push(pd.paletteBeadId);

          if (
            paletteDetails.findIndex(
              (x) => x.paletteBeadId === pd.paletteBeadId,
            ) === -1
          ) {
            paletteDetails.push(pd);
          }
        }
      }

      grid.push(row);
    }

    let data = {
      stitchId: patternBead.stitchId,
      posX: patternBead.posX,
      posY: patternBead.posY,
      beadWidth: patternBead.beadWidth,
      beadHeight: patternBead.beadHeight,
      paletteDetails: paletteDetails,
      image: patternBead.image,
      grid: grid,
      rotation: patternBead.rotation,
    };

    return JSON.stringify(data);
  }

  async getCategories(): Promise<CategoryModel[]> {
    return await this.http
      .post<CategoryModel[]>(this.baseUrl + 'pattern/getcategories', {
        userId: this.userId,
      })
      .toPromise();
  }

  async refreshPalettes(): Promise<void> {
    this.palettes = await this.getPalettes(true);
    this.onPalettesRefreshed.emit(this.palettes);
  }

  async getPalettes(includeBuiltIn: boolean): Promise<PaletteModel[]> {
    return await this.http
      .post<PaletteModel[]>(this.baseUrl + 'palette/getpalettes', {
        includeBuiltIn,
        userId: this.userId,
      })
      .toPromise();
  }

  async getPaletteDetails(
    paletteId: number,
    filter: string,
    pageSize: number,
    paletteIdToExclude: number,
  ): Promise<PaletteDetailModel[]> {
    return await this.http
      .post<PaletteDetailModel[]>(this.baseUrl + 'palette/details', <
        GetPaletteDetailsRequest
      >{
        paletteId: paletteId,
        filter: filter,
        pageSize: pageSize + 1,
        paletteIdToExclude: paletteIdToExclude,
      })
      .toPromise();
  }

  canSavePattern(): boolean {
    if (this.bcanvas) return this.lastActionId != this.bcanvas.getLastId();
    else return false;
  }

  async uploadImage(files) {
    if (files.length === 0) return;

    const formData = new FormData();

    this.imageFilename = Guid.create().toString().split('-').join('');

    formData.append('userId', this.userId);
    formData.append('imageFilename', this.imageFilename);

    for (let file of files) {
      formData.append(file.name, file);
    }

    const uploadReq = new HttpRequest(
      'POST',
      `pattern/uploadfile`,
      formData,
      {},
    );

    let event = await this.http.request(uploadReq).toPromise();

    if (event.type === HttpEventType.Response) {
      if (event.status == 200) {
        let result = <UploadImageResult>event.body;
        this.user.imageFilename = result.imageFilename;
        this.imageUrl = result.url;
        this.refreshImage();
      }
    }
  }

  refreshImage() {
    let patternImage = new Image();
    patternImage.src = this.imageUrl + '?v=' + new Date().getTime();
    patternImage.onload = () => {
      this.bcanvas.patternImageLoaded = true;
      this.bcanvas.insertImage(patternImage);
      this.bcanvas.repaint();
    };
  }

  async transferImage(
    paletteId: number,
    nColors: number,
    preEffect: PreEffectEnum,
    method: MethodEnum,
    dither: DitherEnum,
    model: PatternBeadModel,
  ) {
    let patternData = await this.http
      .post<string>(this.baseUrl + 'pattern/transferimage', {
        stitchId: model.getStitchId(),
        paletteId: paletteId,
        userId: this.userId,
        imageFilename: this.user.imageFilename,
        nColors: nColors,
        preEffect: preEffect,
        method: method,
        dither: dither,
        beadWidth: model.getBeadSize().width,
        beadHeight: model.getBeadSize().height,
        cols: model.getCols(),
        rows: model.getRows(),
        patternXOffs: Math.round(model.getPosition().x),
        patternYOffs: Math.round(model.getPosition().y),
        imgXOffs: Math.round(model.getPatternImage().pos.x),
        imgYOffs: Math.round(model.getPatternImage().pos.y),
        imgWidth: Math.round(model.getPatternImage().size.width),
        imgHeight: Math.round(model.getPatternImage().size.height),
        rot: model.getPatternImage().rot,
      })
      .toPromise();

    let patternBead = this.deserializePatternBead(JSON.stringify(patternData));
    this.bcanvas.controller.transferImage(patternBead.grid);
    this.bcanvas.repaint();
  }

  async addPalette(palette: PaletteModel): Promise<PaletteModel> {
    const newPaletter = await this.http
      .post<PaletteModel>(this.baseUrl + 'palette/add', palette)
      .toPromise();

    this.palettes.push(newPaletter);
    this.onPalettesRefreshed.emit(this.palettes);
    return newPaletter;
  }

  async updatePalette(palette: PaletteModel) {
    return await this.http
      .post<boolean>(this.baseUrl + 'palette/update', palette)
      .toPromise();
  }

  async removePalette(palette: PaletteModel) {
    await this.http
      .post<boolean>(this.baseUrl + 'palette/remove', palette)
      .toPromise();

    this.palettes.splice(
      this.palettes.findIndex((p) => p.paletteId == palette.paletteId),
      1,
    );
    this.onPalettesRefreshed.emit(this.palettes);
  }

  async addPaletteDetail(paletteId: number, paletteBeadId: number) {
    await this.http
      .post<boolean>(this.baseUrl + 'palette/details/add', {
        paletteId: paletteId,
        paletteBeadId: paletteBeadId,
      })
      .toPromise();
  }

  async removePaletteDetail(paletteDetailId: number) {
    await this.http
      .post<boolean>(this.baseUrl + 'palette/details/remove', {
        paletteDetailId: paletteDetailId,
      })
      .toPromise();
  }

  async createCheckoutSession(subscriptionType: SubscriptionTypeEnum) {
    return await this.http
      .post<any>(this.baseUrl + 'payments/create-checkout-session', {
        userId: this.userId,
        baseUrl: this.baseUrl,
        subscriptionType: subscriptionType,
      })
      .toPromise();
  }

  async createCustomerPortalSession() {
    return await this.http
      .post<any>(this.baseUrl + 'payments/create-customer-portal-session', {
        customerId: this.user.stripeCustomerId,
        baseUrl: this.baseUrl,
      })
      .toPromise();
  }

  async sendClientError(clientError: ClientErrorModel) {
    return await this.http
      .post<any>(this.baseUrl + 'user/send-client-error', clientError)
      .toPromise();
  }
}

interface UploadImageResult {
  message: string;
  imageFilename: string;
  url: string;
}

export interface GetPaletteDetailsRequest {
  paletteId: number;
  filter: string;
  pageSize: number;
  paletteIdToExclude: number;
}

export enum UnitOfMeasureEnum {
  Inch = 1,
  Cm = 2,
  Mm = 3,
}

export enum LanguageEnum {
  English = 1,
  Italian = 2,
}

import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http-service';

export interface ConfirmModalData {
  title: string;
  message: string;
}

@Component({
  selector: 'confirm-modal',
  templateUrl: './confirm-modal.html',
  styles: ['./confirm-modal.css'],
})
export class ConfirmModal implements OnInit {
  constructor(
    public httpService: HttpService,
    @Inject(DOCUMENT) private document: Document,
    public dialogRef: MatDialogRef<ConfirmModal>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModalData
  ) {}

  async ngOnInit() {}

  onCancel(): void {
    this.dialogRef.close();
  }
}

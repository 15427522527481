import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http-service';

export interface PromptModalData {
  title: string;
  label1: string;
  text1: string;
  label2: string;
  text2: string;
}

@Component({
  selector: 'prompt-modal',
  templateUrl: './prompt-modal.html',
})
export class PromptModal implements OnInit {
  formSubmitted = false;
  @ViewChild('nameInput', { static: false }) nameInput: ElementRef;

  constructor(
    public httpService: HttpService,
    @Inject(DOCUMENT) private document: Document,
    public dialogRef: MatDialogRef<PromptModal>,
    @Inject(MAT_DIALOG_DATA) public data: PromptModalData
  ) {}

  async ngOnInit() {
    setTimeout(() => {
      this.nameInput.nativeElement.focus();
    }, 500);

    gtag('event', 'page_view', {
      page_title: 'Prompt (Modal)',
      page_location: `${window.location.origin}/prompt-modal`,
    });
  }

  onSubmit() {
    this.formSubmitted = true;
    if (this.nameInput.nativeElement.validity.valid) {
      this.dialogRef.close(this.data);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}

export class PaletteModel {
  paletteId: number;
  name: string;
  builtIn: boolean;
  userId: string;
}

export class TransferPreEffectModel {
  name: string;
  preEffect: PreEffectEnum;
}

export class TransferMethodModel {
  name: string;
  method: MethodEnum;
}

export class TransferDitherModel {
  name: string;
  dither: DitherEnum;
}

export enum PreEffectEnum {
  None,
  BlackAndWhite,
  Grayscale,
  Sepia,
  OilPaint,
  Polaroid,
  Vignette,
}

export enum MethodEnum {
  MedianCut,
  DontReduceColorsWithLib,
  RemoveNearest,
  WuQuantizer,
  OctreeQuantizer,
}

export enum DitherEnum {
  None,
  Atkinson,
  Burkes,
  FloydSteinberg,
  JarvisJudiceNinke,
  Sierra2,
  Sierra3,
  SierraLite,
  StevensonArce,
  Stucki,
}

import { Component, Inject, Input } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
})
export class NotificationComponent {
  notificationTypes = NotificationTypeEnum;

  constructor(
    public snackBarRef: MatSnackBarRef<NotificationComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: NotificationData
  ) {}

  public getIconClass() {
    switch (this.data.type) {
      case NotificationTypeEnum.Information:
        return 'information';
      case NotificationTypeEnum.Warning:
        return 'warning';
      case NotificationTypeEnum.Error:
        return 'error';
      default:
        return 'information';
    }
  }

  public getIconName() {
    switch (this.data.type) {
      case NotificationTypeEnum.Information:
        return 'info_outline';
      case NotificationTypeEnum.Warning:
        return 'warning_outline';
      case NotificationTypeEnum.Error:
        return 'error_outline';
      default:
        return 'info';
    }
  }

  public getContainerClass() {
    switch (this.data.type) {
      case NotificationTypeEnum.Information:
        return 'information_container';
      case NotificationTypeEnum.Warning:
        return 'warning_container';
      case NotificationTypeEnum.Error:
        return 'error_container';
      default:
        return 'information_container';
    }
  }
}

export interface NotificationData {
  message: string;
  type: NotificationTypeEnum;
}

export enum NotificationTypeEnum {
  Information,
  Warning,
  Error,
}

import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { HomeComponent } from '../home/home.component';
import { BeadCanvas } from '../peditor/beadcanvas';
import { Action } from '../peditor/patternmodelcontroller';
import { Point } from '../peditor/point';
import { Size } from '../peditor/size';
import { DEFAULT_ZOOM_FACTOR } from '../helpers/constants';

@Component({
  selector: 'app-bead-canvas',
  templateUrl: './bead-canvas.component.html',
})
export class BeadCanvasComponent implements OnInit {
  @ViewChild('canvasContainer', { static: true })
  private canvasContainer: ElementRef<HTMLCanvasElement>;

  @ViewChild('canvas', { static: true })
  private canvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('beadSelImg', { static: true })
  private beadSelImg: ElementRef<HTMLImageElement>;

  @ViewChild('beadEmptyImg', { static: true })
  private beadEmptyImg: ElementRef<HTMLImageElement>;

  @ViewChild('beadMetallicImg', { static: true })
  private beadMetallicImg: ElementRef<HTMLImageElement>;

  @ViewChild('beadUnknownImg', { static: true })
  private beadUnknownImg: ElementRef<HTMLImageElement>;

  @ViewChild('beadCrystalImg', { static: true })
  private beadCrystalImg: ElementRef<HTMLImageElement>;

  @ViewChild('beadTransparentImg', { static: true })
  private beadTransparentImg: ElementRef<HTMLImageElement>;

  @ViewChild('beadOpaqueImg', { static: true })
  private beadOpaqueImg: ElementRef<HTMLImageElement>;

  @ViewChild('beadMatteImg', { static: true })
  private beadMatteImg: ElementRef<HTMLImageElement>;

  @ViewChild('realBeadImagesImg', { static: true })
  private realBeadImagesImg: ElementRef<HTMLImageElement>;

  public innerWidth: any;

  @Output()
  onActionFired: EventEmitter<Action> = new EventEmitter<Action>();

  public _bcanvas: BeadCanvas;

  @Input()
  set bcanvas(bc: BeadCanvas) {
    this._bcanvas = bc;
  }

  @Input()
  home: HomeComponent;

  public maxWindowSize: number = 2000;

  constructor() {}

  async ngOnInit() {
    var beadTypeImages: HTMLImageElement[] = new Array(
      this.beadEmptyImg.nativeElement,
      this.beadMetallicImg.nativeElement,
      this.beadUnknownImg.nativeElement,
      this.beadCrystalImg.nativeElement,
      this.beadTransparentImg.nativeElement,
      this.beadOpaqueImg.nativeElement,
      this.beadMatteImg.nativeElement,
    );

    this._bcanvas.initCanvas(
      this.canvas.nativeElement,
      this.beadSelImg.nativeElement,
      beadTypeImages,
      this.realBeadImagesImg.nativeElement,
      this.onActionFired,
    );

    this.resizeCanvasContainer();
  }

  public resetPatternPosition = (): void => {
    const zoomFactor = this.getBeadCanvas().getZoomFactor();
    const patternHandleHeight = this.getBeadCanvas().patternHandleHeight;

    this._bcanvas
      .getModel()
      .setPosition(
        new Point(
          (-1000 + 100) / zoomFactor,
          patternHandleHeight + 50 / zoomFactor,
        ),
      );
  };

  public resetRotation = (): void => {
    this._bcanvas.getModel().setRotation(0);
    this._bcanvas.rotatedPattern(false);
  };

  public resetZoom = (): void => {
    this._bcanvas.zoom(DEFAULT_ZOOM_FACTOR);
  };

  resizeCanvasContainer() {
    if (this.home) {
      this.canvasContainer.nativeElement.style.width =
        window.innerWidth - (this.home.rightSidebarOpen ? 320 : 0) + 'px';
      this.canvasContainer.nativeElement.style.height =
        window.innerHeight - this.home.appToolbarHeight + 'px';

      const max = Math.max(window.innerWidth, window.innerHeight);
      if (max > this.maxWindowSize) {
        this.maxWindowSize = max;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeCanvasContainer();
  }

  public getBeadCanvas(): BeadCanvas {
    return this._bcanvas;
  }
}

<div
  mat-dialog-title
  class="modal-title"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <span class="modal-title-label"
    >{{ httpService.getTransation().ChoosePlan }}</span
  >
  <button
    class="modal-close-button"
    mat-icon-button
    matTooltip="{{ httpService.getTransation().Close }}"
    (click)="onClose()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div
  mat-dialog-content
  style="
    max-width: 700px;
    max-height: 500px !important;
    overflow: auto !important;
  "
>
  <div [fxLayout]="itemsDirection" fxLayoutAlign="space-around center">
    <div
      class="subscription-item"
      [ngClass]="{ 'subscription-item-selected' : data.subscriptionType === subscriptionTypes.Yearly }"
    >
      <div class="subscription-best-value">
        {{ httpService.getTransation().SAVE20 }}
      </div>
      <div class="subscription-title">
        {{ httpService.getTransation().YEARLY }}
      </div>
      <div class="subscription-price">$50</div>
      <div class="subscription-subtitle">
        {{ httpService.getTransation().BilledAnnually }}
      </div>
      <div class="subscription-description">
        {{ httpService.getTransation().PlanPaidDescription1 }}
      </div>
      <div class="subscription-description2">
        {{ httpService.getTransation().PlanPaidDescription2 }}
      </div>
      <button
        mat-raised-button
        class="subscription-button"
        [disabled]="data.subscriptionType === subscriptionTypes.Yearly"
        color="warn"
        [mat-dialog-close]="data"
        (click)="onClickSubscription(subscriptionTypes.Yearly)"
      >
        {{ httpService.getTransation().Choose }}
      </button>
    </div>

    <div
      class="subscription-item"
      [ngClass]="{ 'subscription-item-selected' : data.subscriptionType === subscriptionTypes.Monthly }"
    >
      <div class="subscription-no-best-value"></div>
      <div class="subscription-title">
        {{ httpService.getTransation().MONTHLY }}
      </div>
      <div class="subscription-price">$5</div>
      <div class="subscription-subtitle">
        {{ httpService.getTransation().BilledMonthly }}
      </div>
      <div class="subscription-description">
        {{ httpService.getTransation().PlanPaidDescription1 }}
      </div>
      <div class="subscription-description2">
        {{ httpService.getTransation().PlanPaidDescription2 }}
      </div>
      <button
        mat-raised-button
        class="subscription-button"
        [disabled]="data.subscriptionType   === subscriptionTypes.Monthly"
        color="primary"
        [mat-dialog-close]="data"
        (click)="onClickSubscription(subscriptionTypes.Monthly)"
      >
        {{ httpService.getTransation().Choose }}
      </button>
    </div>
    <div
      class="subscription-item"
      [ngClass]="{ 'subscription-item-selected' : data.subscriptionType === subscriptionTypes.None }"
    >
      <div class="subscription-no-best-value"></div>
      <div class="subscription-title">
        {{ httpService.getTransation().FREE }}
      </div>
      <div class="subscription-price">$0</div>
      <div class="subscription-subtitle">
        {{ httpService.getTransation().NoCharge }}
      </div>
      <div class="subscription-description">
        {{ httpService.getTransation().PlanFreeDescription1 }}
      </div>
      <div class="subscription-description2">
        {{ httpService.getTransation().PlanFreeDescription2 }}
      </div>
      <button
        mat-raised-button
        class="subscription-button"
        [disabled]="data.subscriptionType === subscriptionTypes.None"
        color="primary"
        [mat-dialog-close]="data"
        (click)="onClickSubscription(subscriptionTypes.None)"
      >
        {{ httpService.getTransation().Choose }}
      </button>
    </div>
    <div></div>
  </div>
</div>

import { EventEmitter } from '@angular/core';
import { Point } from './point';
import { Size } from './size';
import { Rect } from './rect';
import { BeadPos } from './beadpos';
import {
  PatternBeadModel,
  DefaultBeadPatternRows,
  DefaultBeadPatternCols,
} from './patternbeadmodel';
import { PatternComponentData } from './patterncomponentdata';
import { PatternImage, Rotation } from './patternimage';
import {
  PatternModelController,
  Action,
  RotateDirectionEnum,
} from './patternmodelcontroller';
import { PaletteDetailModel } from '../models/palettes/palette_detail_model';
import { PatternBead } from '../models/pattern/pattern-bead';
import { StitchTypeEnum } from '../models/stitchs/stitch_model';
import { RealBeadImageModel } from '../models/palettes/real_bead_image_model';
import { Utility } from '../helpers/utility';
import { PatternBGImage } from '../models/pattern/pattern-bg-image';
import { DEFAULT_ZOOM_FACTOR, EMPTY_BEAD } from '../helpers/constants';

export class BeadCanvas extends PatternComponentData {
  private zoomFactor: number = DEFAULT_ZOOM_FACTOR;
  private orgCanvasSize: Point;
  private minZoomFactor: number = 0.1;
  private maxZoomFactor: number = 5;
  private realisticBeadsEnabled: boolean = false;
  public canvasStyle: string = 'canvasstyle';

  private bgColor: string = 'ivory';

  // current bead under the mouse cursor
  private selBead: BeadPos;

  private canvas: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D;
  private pcanvas: HTMLCanvasElement;
  private pctx: CanvasRenderingContext2D;

  private model: PatternBeadModel = new PatternBeadModel(
    DefaultBeadPatternRows,
    DefaultBeadPatternCols,
  );
  public controller: PatternModelController;

  private selImage;
  HTMLImageElement;
  private beadTypeImages: HTMLImageElement[];
  private realBeadImagesImg: HTMLImageElement;

  private patternImage: HTMLImageElement;
  public patternImageLoaded: boolean = false;

  private actionMode: ActionMode = ActionMode.Nothing;
  private mouseDownPoint: Point;
  private ds: Point = new Point();
  private imageHandle: PIHandle;

  public patternHandleHeight = 30;

  private editMode: EditMode = EditMode.AddBead;

  private rotated: boolean = false;

  private currentBead: PaletteDetailModel = <PaletteDetailModel>{
    paletteId: -1,
    paletteDetailId: -1,
    paletteBeadId: -1,
    name: 'empty',
    description: '',
    paletteTypeId: 0,
    color: '000000',
    count: 0,
  };

  public onCurrentBeadChanged: EventEmitter<PaletteDetailModel> =
    new EventEmitter<PaletteDetailModel>();

  public constructor() {
    super();
  }

  public initCanvas = (
    canvas: HTMLCanvasElement,
    selImage: HTMLImageElement,
    beadTypeImages: HTMLImageElement[],
    realBeadImagesImg: HTMLImageElement,
    onActionFired: EventEmitter<Action>,
  ): void => {
    this.canvas = canvas;
    this.controller = new PatternModelController(this.model, onActionFired);
    this.selImage = selImage;
    this.beadTypeImages = beadTypeImages;
    this.realBeadImagesImg = realBeadImagesImg;
    this.ctx = this.canvas.getContext('2d');
    this.orgCanvasSize = new Point(this.canvas.width, this.canvas.height);
    this.createPatternCanvas();

    this.addListeners();
    this.scrollToPoint(new Point(0, -this.canvas.height));
    this.initImages();
    this.recalcCanvasSize();
    this.repaint();
  };

  private createPatternCanvas() {
    this.pcanvas = document.createElement('canvas');
    this.pcanvas.width = 200;
    this.pcanvas.height = 200;
    this.pctx = this.pcanvas.getContext('2d');
  }

  private addListeners(): void {
    this.canvas.addEventListener('click', this.onMouseClick);
    this.canvas.addEventListener('mousemove', this.onMouseMove);
    this.canvas.addEventListener('touchmove', this.onMouseMove);
    this.canvas.addEventListener('mousedown', this.onMouseDown);
    this.canvas.addEventListener('touchstart', this.onMouseDown);
    this.canvas.addEventListener('mouseup', this.onMouseUp);
    this.canvas.addEventListener('touchend', this.onMouseUp);
    this.canvas.addEventListener('mousewheel', this.onMouseWheel);
    this.canvas.addEventListener('DOMMouseScroll', this.onMouseWheel);
    this.canvas.addEventListener('mouseleave', this.onMouseLeave);
    this.canvas.addEventListener('resize', this.repaint);
    window.addEventListener('keydown', this.onKeyDown);
  }

  /**
   * Simple undo / redo
   * cmd/ctrl + z -> undo
   * cmd/ctrl + shift + z -> redo
   * cmd/ctrl + y -> redo
   */
  private onKeyDown = (e: KeyboardEvent): void => {
    switch (e.keyCode) {
      case 90:
        if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
          this.redo();
        } else if (e.ctrlKey || e.metaKey) {
          this.undo();
        }
        break;
      case 89:
        if (e.ctrlKey || e.metaKey) {
          this.redo();
        }
        break;
    }
  };

  private onMouseDown = (e: MouseEvent): void => {
    const mousePos = this.getMousePos(e);
    this.mouseDownPoint = this.mousePosToCanvas(mousePos.x, mousePos.y);
    this.beginDragging();
  };

  private onMouseUp = (e: MouseEvent): void => {
    const mousePos = this.getMousePos(e);
    let p: Point = this.mousePosToCanvas(mousePos.x, mousePos.y);
    this.endDragging(p);
  };

  private onMouseLeave = (e: MouseEvent): void => {
    const mousePos = this.getMousePos(e);
    let p: Point = this.mousePosToCanvas(mousePos.x, mousePos.y);
    this.endDragging(p);
  };

  private onMouseWheel = (e: Event): void => {
    const keyboardEvent = e as KeyboardEvent;
    const wheelEvent = e as WheelEvent;

    if (keyboardEvent.metaKey) {
      if (wheelEvent.deltaY > 0) {
        this.zoomOut();
      } else {
        this.zoomIn();
      }
    }
  };

  private getActionModePos(p: Point): ActionMode {
    if (this.editMode !== EditMode.Pan) {
      if (this.getHandleRect().contains(p) && this.isBedDraggingEnabled()) {
        return ActionMode.DragBed;
      } else if (
        this.model.getPatternImage() &&
        this.checkPIHandle(p) &&
        this.isImageResizingEnabled()
      ) {
        return ActionMode.ScaleImage;
      } else if (this.viewToModel(p.x, p.y)) {
        return ActionMode.Nothing;
      } else if (
        this.model.getPatternImage() &&
        this.getPatternImageRect().contains(p) &&
        this.isImageDraggingEnabled()
      ) {
        return ActionMode.DragImage;
      } else {
        return ActionMode.Nothing;
      }
    } else {
      return ActionMode.Nothing;
    }
  }

  private beginDragging(): void {
    this.actionMode = this.getActionModePos(this.mouseDownPoint);
  }

  setRealisticBeadsEnabled(enabled: boolean) {
    this.realisticBeadsEnabled = enabled;
    this.repaint();
  }

  isRealisticBeadsEnabled() {
    return this.realisticBeadsEnabled;
  }

  private isBedDraggingEnabled(): boolean {
    return this.model.getRotation() == 0;
  }

  private isImageResizingEnabled(): boolean {
    return this.model.getRotation() == 0;
  }

  private isImageDraggingEnabled(): boolean {
    return this.model.getRotation() == 0;
  }

  private checkPIHandle(p: Point): boolean {
    let r: Rect = this.getPatternImageRect();

    this.imageHandle = null;
    let hr: Rect = this.getPIHandleRect(r, PIHandle.Top);
    if (hr.contains(p)) {
      this.imageHandle = PIHandle.Top;
    }

    hr = this.getPIHandleRect(r, PIHandle.Bottom);
    if (hr.contains(p)) {
      this.imageHandle = PIHandle.Bottom;
    }

    hr = this.getPIHandleRect(r, PIHandle.Left);
    if (hr.contains(p)) {
      this.imageHandle = PIHandle.Left;
    }

    hr = this.getPIHandleRect(r, PIHandle.Right);
    if (hr.contains(p)) {
      this.imageHandle = PIHandle.Right;
    }

    return this.imageHandle != null;
  }

  private getPatternImageRect(): Rect {
    let pi: PatternImage = this.model.getPatternImage();
    if (!pi) return new Rect();

    let x: number = pi.pos.x * this.zoomFactor + this.canvas.width / 2;
    let y: number = pi.pos.y * this.zoomFactor;
    let w: number = pi.size.width * this.zoomFactor;
    let h: number = pi.size.height * this.zoomFactor;

    let r: Rect = new Rect(x, y, w, h);

    return r;
  }

  private endDragging(at: Point): void {
    if (
      this.actionMode != ActionMode.Nothing &&
      (at.x != this.mouseDownPoint.x || at.y != this.mouseDownPoint.y)
    ) {
      this.finishActionMode();
    }
    this.actionMode = ActionMode.Nothing;
    this.controller.endCompositeAction();
  }

  private finishActionMode() {
    switch (this.actionMode) {
      case ActionMode.DragBed:
        this.controller.moveBed(this.ds.mult(1 / this.zoomFactor));
        break;
      case ActionMode.DragImage:
        this.controller.movePatternImage(this.ds.mult(1 / this.zoomFactor));
        break;
      case ActionMode.ScaleImage:
        this.doScalePatternImage();
        break;
    }
  }

  private doScalePatternImage() {
    let r: Rect = new Rect();
    this.updatePIRectToScale(r);
    let f: number = 1 / this.zoomFactor;
    this.controller.scalePatternImage(
      new Point(r.x, r.y).mult(f),
      new Size(r.width, r.height).mult(f),
    );
  }

  private initImages(): void {
    this.selImage.onload = this.repaint;
  }

  public setPatternImage = (patternImage: HTMLImageElement): void => {
    if (!patternImage) {
      this.patternImage = undefined;
      this.model.setPatternImage(undefined);
      return;
    }

    this.patternImage = patternImage;

    if (this.model.getPatternImage()) {
      this.model.getPatternImage().image = patternImage;
    }

    this.patternImage.onload = () => {
      this.patternImageLoaded = true;
      this.repaint;
    };
  };

  public setCurrentBead = (bead: PaletteDetailModel): void => {
    // console.log("bead color: " + bead.color);
    this.currentBead = bead;
  };

  public setPatternImageVisible(visible: boolean): void {
    var pi: PatternImage = this.getModel().getPatternImage();
    if (pi && visible != pi.visible) {
      this.controller.setPatterImageVisible(visible);
      this.paint();
    }
  }

  public isPatternImageVisible(): boolean {
    var pi: PatternImage = this.getModel().getPatternImage();
    if (pi) {
      return pi.visible;
    } else {
      return false;
    }
  }

  public getPatternImage = (): HTMLImageElement => {
    return this.patternImage;
  };

  public shiftPattern(shiftType: ShiftTypeEnum): void {
    switch (shiftType) {
      case ShiftTypeEnum.Left:
        this.controller.shiftPattern(1, 0);
        break;

      case ShiftTypeEnum.Right:
        this.controller.shiftPattern(-1, 0);
        break;

      case ShiftTypeEnum.Up:
        this.controller.shiftPattern(0, 1);
        break;

      case ShiftTypeEnum.Down:
        this.controller.shiftPattern(0, -1);
        break;
    }

    this.repaint();
  }

  public rotatePattern(rotateDirection: RotateDirectionEnum) {
    this.controller.rotatePattern(rotateDirection);
    this.repaint();
  }

  public flipPattern(flipDirection: FlipDirectionEnum) {
    this.controller.flipPattern(flipDirection);
    this.repaint();
  }

  private scrollToPoint(p: Point): void {
    let sx: number =
      (this.canvas.width - this.canvas.parentElement.clientWidth) / 2 + p.x;
    let sy: number =
      (this.canvas.height - this.canvas.parentElement.clientHeight) / 2 + p.y;
    this.canvas.parentElement.scrollTo(sx, sy);
  }

  public repaint = (): void => {
    this.drawBed();
    this.paint();
  };

  public paint = (): void => {
    this.clearAll();
    this.ctx.save();
    var p = this.calcPatternPos();
    var roffs = this.getOffsetOnRot(p);
    this.ctx.rotate((this.model.getRotation() * Math.PI) / 180);
    this.ctx.translate(roffs.x, roffs.y);
    this.drawPatternImage();

    this.ctx.drawImage(this.pcanvas, p.x, p.y);

    this.drawSelBead();
    this.ctx.restore();
  };

  private getOffsetOnRot(p: Point): Point {
    switch (this.model.getRotation()) {
      case 90:
        return new Point(-p.x + p.y, -p.x - p.y - this.pcanvas.height);
      case 180:
      case -180:
        return new Point(
          -2 * p.x - this.pcanvas.width,
          -2 * p.y - this.pcanvas.height,
        );
      case 270:
      case -90:
        return new Point(-p.x - p.y - this.pcanvas.width, p.x - p.y);
    }

    return new Point(0, 0);
  }

  private calcPatternPos(): Point {
    var x =
      this.canvas.width / 2 + this.model.getPosition().x * this.zoomFactor;
    var y =
      this.model.getPosition().y * this.zoomFactor - this.patternHandleHeight;
    if (this.actionMode == ActionMode.DragBed) {
      x += this.ds.x;
      y += this.ds.y;
    }

    return new Point(x, y);
  }

  private drawBed(): void {
    this.calcPCanvasSize();
    //        this.ctx.save();
    //        if (this.actionMode == ActionMode.DragBed)
    //            this.ctx.translate(this.ds.x, this.ds.y);
    this.drawHandle();
    this.drawBrickGrid();
    //        this.drawSelBead();
    //        this.ctx.restore();
  }

  public getPatternSize(): Size {
    return new Size(this.pcanvas.width, this.pcanvas.height);
  }

  private calcPCanvasSize() {
    let bs = this.getBeadSize();

    this.pcanvas.width = (this.model.getCols() + 1) * bs.width;
    this.pcanvas.height =
      (this.model.getRows() + 1) * bs.height + this.patternHandleHeight;
  }

  private clearAll(): void {
    this.ctx.fillStyle = this.bgColor;
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
  }

  private drawPatternImage() {
    if (
      this.patternImageLoaded &&
      this.model.getPatternImage() &&
      this.model.getPatternImage().image
    ) {
      let pi: PatternImage = this.model.getPatternImage();

      if (!pi) return;

      if (!pi.visible) return;

      this.ctx.save();
      if (this.actionMode == ActionMode.DragImage) {
        this.ctx.translate(this.ds.x, this.ds.y);
      }

      let r: Rect = this.getPatternImageRect();

      if (this.actionMode == ActionMode.ScaleImage) {
        this.updatePIRectToScale(r);
      }

      let mins = this.getBeadSize();
      if (r.width < mins.width) {
        r.width = mins.width;
      }
      if (r.height < mins.height) {
        r.height = mins.height;
      }

      if (this.isImageResizingEnabled()) {
        this.drawPatternImageBorder(r);
        this.drawPatternImageHandles(r);
      }
      this.drawRotPatternImage(r);
      this.ctx.restore();
    }
  }

  private drawRotPatternImage(r: Rect) {
    if (this.model.getPatternImage()) {
      this.ctx.save();
      let w = r.width;
      let h = r.height;
      switch (this.model.getPatternImage().rot) {
        case Rotation.Rot_0:
          this.ctx.translate(r.x, r.y);
          break;
        case Rotation.Rot_90:
          w = r.height;
          h = r.width;
          this.ctx.translate(r.x + r.width, r.y);
          this.ctx.rotate((90 * Math.PI) / 180);
          break;
        case Rotation.Rot_180:
          this.ctx.translate(r.x + r.width, r.y + r.height);
          this.ctx.rotate(Math.PI);
          break;
        case Rotation.Rot_270:
          w = r.height;
          h = r.width;
          this.ctx.translate(r.x, r.y + r.height);
          this.ctx.rotate((270 * Math.PI) / 180);
          break;
      }
      this.ctx.drawImage(this.model.getPatternImage().image, 0, 0, w, h);
      this.ctx.restore();
    }
  }

  private updatePIRectToScale(r: Rect) {
    let pi: PatternImage = this.model.getPatternImage();

    if (pi && pi.image) {
      let f: number = pi.image.height / pi.image.width;
      if (pi.rot == Rotation.Rot_270 || pi.rot == Rotation.Rot_90)
        f = pi.image.width / pi.image.height;

      switch (this.imageHandle) {
        case PIHandle.Right:
          r.width += this.ds.x;
          r.height += this.ds.x * f;
          break;
        case PIHandle.Bottom:
          r.height += this.ds.y;
          r.width += this.ds.y / f;
          break;
        case PIHandle.Left:
          r.x += this.ds.x;
          r.width -= this.ds.x;
          r.height -= this.ds.x * f;
          break;
        case PIHandle.Top:
          r.y += this.ds.y;
          r.height -= this.ds.y;
          r.width -= this.ds.y / f;
      }
    }
  }

  private drawPatternImageBorder(r: Rect): void {
    this.ctx.strokeStyle = 'DimGray';
    this.ctx.strokeRect(r.x, r.y, r.width, r.height);
  }

  private drawPatternImageHandles(r: Rect): void {
    this.ctx.fillStyle = 'grey';
    let hr: Rect = this.getPIHandleRect(r, PIHandle.Top);
    this.ctx.fillRect(hr.x, hr.y, hr.width, hr.height);
    hr = this.getPIHandleRect(r, PIHandle.Bottom);
    this.ctx.fillRect(hr.x, hr.y, hr.width, hr.height);
    hr = this.getPIHandleRect(r, PIHandle.Left);
    this.ctx.fillRect(hr.x, hr.y, hr.width, hr.height);
    hr = this.getPIHandleRect(r, PIHandle.Right);
    this.ctx.fillRect(hr.x, hr.y, hr.width, hr.height);
  }

  private getPIHandleRect(r: Rect, h: PIHandle): Rect {
    let hs: number = 20;

    let hr: Rect = new Rect(
      r.x + (r.width - hs) / 2,
      r.y + (r.height - hs) / 2,
      hs,
      hs,
    );

    switch (h) {
      case PIHandle.Top:
        hr.y = r.y - hs / 2;
        break;
      case PIHandle.Bottom:
        hr.y = r.y + r.height - hs / 2;
        break;
      case PIHandle.Left:
        hr.x = r.x - hs / 2;
        break;
      case PIHandle.Right:
        hr.x = r.x + r.width - hs / 2;
        break;
    }

    return hr;
  }

  private drawHandle(): void {
    let r: Rect = this.getHandleRect();
    this.pctx.save();
    this.setHandleDrawStyle(r);
    this.pctx.fillRect(0, 0, r.width, r.height);
    this.pctx.strokeRect(0, 0, r.width, r.height);
    this.pctx.restore();
  }

  private getHandleRect(): Rect {
    let h: number = this.patternHandleHeight;
    let w: number = this.model.getCols() * this.getBeadSize().width;
    let x: number =
      this.canvas.width / 2 + this.model.getPosition().x * this.zoomFactor;
    let y: number = this.model.getPosition().y * this.zoomFactor - h;

    if (this.model.getStitchId() == StitchTypeEnum.Brick) {
      w += 0.5 * this.getBeadSize().width;
    }

    return new Rect(x, y, w, h);
  }

  private setHandleDrawStyle(r: Rect) {
    let cg: CanvasGradient = this.ctx.createLinearGradient(
      r.width / 2,
      0,
      r.width / 2,
      r.height,
    );
    if (this.isBedDraggingEnabled()) {
      cg.addColorStop(0, '#c6b4b4');
      cg.addColorStop(0.5, 'white');
      cg.addColorStop(1, '#c6b4b4');
    } else {
      cg.addColorStop(0, '#f69494');
      cg.addColorStop(0.5, 'white');
      cg.addColorStop(1, '#f69494');
    }
    this.pctx.fillStyle = cg;
    this.pctx.strokeStyle = '#c6b4b4';
  }

  private drawBrickGrid(): void {
    for (let r = 0; r < this.model.getRows(); r++)
      for (let c = 0; c < this.model.getCols(); c++) this.drawBead(r, c);
  }

  private drawBead(r: number, c: number): void {
    let bs: Size = this.getBeadSize();
    let x = this.getLocalXFor(r, c);
    let y = this.patternHandleHeight + this.getLocalYFor(r, c);

    let b: PaletteDetailModel = this.model.getBeadAt(r, c);
    if (b && b.paletteTypeId != 0) {
      this.pctx.fillStyle = '#' + b.color;
      this.fillRoundRect(
        this.pctx,
        x,
        y,
        bs.width,
        bs.height,
        4 * this.zoomFactor,
      );
      //console.log("paletteTypeId => " + b.paletteTypeId + ", beadTypeImages.length => " + this.beadTypeImages.length + ", r => " + r + ", c => " + c);

      var realBeadPosition: number[] = null;

      if (this.realisticBeadsEnabled) {
        realBeadPosition = Utility.getRealBeadPosition(b.name);
      }

      if (realBeadPosition != null)
        this.drawBeadImage(
          this.realBeadImagesImg,
          realBeadPosition[0],
          realBeadPosition[1],
          RealBeadImageModel.width,
          RealBeadImageModel.height,
          x,
          y,
          bs.width,
          bs.height,
        );
      else
        this.drawBeadImage(
          this.beadTypeImages[b.paletteTypeId],
          0,
          0,
          bs.width,
          bs.height,
          x,
          y,
          bs.width,
          bs.height,
        );
    } else {
      this.pctx.clearRect(x, y, bs.width, bs.height);
      this.pctx.strokeStyle = '#749FC0';
      this.pctx.lineWidth = 1.5;
      this.drawRoundRect(this.pctx, x, y, bs.width, bs.height, 0);
    }
  }

  private drawBeadImage(
    img: HTMLImageElement,
    sx: number,
    sy: number,
    sw: number,
    sh: number,
    x: number,
    y: number,
    w: number,
    h: number,
  ) {
    if (this.model.getStitchId() == StitchTypeEnum.Peyote) {
      this.pctx.save();
      this.pctx.translate(x + w, y);
      this.pctx.rotate((90 * Math.PI) / 180);
      this.pctx.drawImage(img, sx, sy, sw, sh, 0, 0, h, w);
      this.pctx.restore();
    } else {
      this.pctx.drawImage(img, sx, sy, sw, sh, x, y, w, h);
    }
  }

  private drawSelBead(): void {
    if (null == this.selBead || this.actionMode != ActionMode.Nothing) {
      this.canvasStyle = 'canvasstyle cursor-normal';
      return;
    }

    let bs: Size = this.getBeadSize();

    let x = this.getXFor(this.selBead.row, this.selBead.col);
    let y = this.getYForRow(this.selBead.row, this.selBead.col);
    let offs = 5 * this.zoomFactor;

    this.ctx.drawImage(
      this.selImage,
      x - offs,
      y - offs,
      bs.width + 2 * offs,
      bs.height + 2 * offs,
    );

    if (this.getEditMode() === EditMode.PickBead) {
      this.canvasStyle = 'canvasstyle cursor-pick';
    } else {
      this.canvasStyle = 'canvasstyle cursor-draw';
    }
  }

  private getXFor(r: number, c: number): number {
    let x = this.canvas.width / 2 + this.getLocalXFor(r, c);

    return this.model.getPosition().x * this.zoomFactor + x;
  }

  private getYForRow(r: number, c: number): number {
    return (
      this.model.getPosition().y * this.zoomFactor + this.getLocalYFor(r, c)
    );
  }

  private viewToModel(x: number, y: number): BeadPos {
    let p = this.getReverseRotViewToModelPoint(new Point(x, y));

    switch (this.model.getStitchId()) {
      case StitchTypeEnum.Brick:
        return this.viewToModelBrick(p.x, p.y);
      case StitchTypeEnum.Peyote:
        return this.viewToModelPeyote(p.x, p.y);
      default:
        return this.viewToModelPeyote(p.x, p.y);
    }
  }

  private getReverseRotViewToModelPoint(vp: Point): Point {
    let pp = this.calcPatternPos();
    let dmp = new Point(vp.x - pp.x, vp.y - pp.y);

    let p = new Point(vp.x, vp.y);
    switch (this.model.getRotation()) {
      case 90:
      case -270:
        p.x = pp.x + dmp.y;
        p.y = pp.y + this.pcanvas.height - dmp.x;
        break;
      case 180:
        p.x = pp.x + this.pcanvas.width - dmp.x;
        p.y = pp.y + this.pcanvas.height - dmp.y;
        break;
      case 270:
      case -90:
        p.x = pp.x + this.pcanvas.width - dmp.y;
        p.y = pp.y + dmp.x;
    }

    return p;
  }

  private viewToModelBrick(x: number, y: number): BeadPos {
    let bs = this.getBeadSize();
    let yoff: number = y - this.model.getPosition().y * this.zoomFactor;
    if (yoff < 0) return null;
    if (yoff > this.model.getRows() * bs.height) return null;

    let row: number = Math.floor(yoff / bs.height);

    let xoff = this.canvas.width / 2;
    xoff =
      Math.floor(x - xoff - this.getXOffs(Math.floor(row))) -
      this.model.getPosition().x * this.zoomFactor;
    let col = Math.floor(xoff / bs.width);

    if (
      col < 0 ||
      row < 0 ||
      col >= this.model.getCols() ||
      row >= this.model.getRows()
    )
      return null;

    return new BeadPos(row, col);
  }

  private viewToModelPeyote(x: number, y: number): BeadPos {
    let bs = this.getBeadSize();

    let xoff: number =
      x - this.model.getPosition().x * this.zoomFactor - this.canvas.width / 2;
    if (xoff < 0) return null;
    if (xoff > this.model.getCols() * bs.width) return null;

    let col = Math.floor(xoff / bs.width);

    let yoff =
      Math.floor(y - this.getYOffs(Math.floor(col))) -
      this.model.getPosition().y * this.zoomFactor;
    let row = Math.floor(yoff / bs.height);

    if (
      col < 0 ||
      row < 0 ||
      col >= this.model.getCols() ||
      row >= this.model.getRows()
    )
      return null;

    return new BeadPos(row, col);
  }

  private getLocalXFor(r: number, c: number): number {
    let bs: Size = this.getBeadSize();
    let locx = c * bs.width;

    if (this.model.getStitchId() == StitchTypeEnum.Brick) {
      let boff: number = bs.width / 2;
      locx += r % 2 == 1 ? 0 : boff;
    }

    return locx;
  }

  private getXOffs(r: number): number {
    let xoffs = 0;

    if (this.model.getStitchId() === StitchTypeEnum.Brick) {
      let bs: Size = this.getBeadSize();
      let boff: number = bs.width / 2;
      xoffs = r % 2 == 1 ? 0 : boff;
    }

    return xoffs;
  }

  private getLocalYFor(r: number, c: number): number {
    let bs: Size = this.getBeadSize();
    let locy = r * bs.height;

    if (this.model.getStitchId() === StitchTypeEnum.Peyote) {
      let boff: number = bs.height / 2;
      locy +=
        c % 2 == 0
          ? this.model.getCols() % 2 == 0
            ? boff
            : 0
          : this.model.getCols() % 2 == 0
            ? 0
            : boff;
    }

    return locy;
  }

  private getYOffs(c: number): number {
    let yoffs = 0;

    if (this.model.getStitchId() == StitchTypeEnum.Peyote) {
      let bs: Size = this.getBeadSize();
      let boff: number = bs.height / 2;
      yoffs =
        c % 2 == 0
          ? this.model.getCols() % 2 == 0
            ? boff
            : 0
          : this.model.getCols() % 2 == 0
            ? 0
            : boff;
    }

    return yoffs;
  }

  private getBeadSize(): Size {
    let s = this.model.getScaledBeadSize(this.zoomFactor);
    switch (this.model.getStitchId()) {
      case StitchTypeEnum.Peyote:
        return new Size(s.height, s.width);
      case StitchTypeEnum.Loom:
        return new Size(s.height, s.width);
      case StitchTypeEnum.Brick:
        return s;
      default:
        return s;
    }
  }

  private onMouseClick = (e: MouseEvent) => {
    this.doActionAt(this.mousePosToCanvas(e.x, e.y));
    this.repaint();
  };

  private getMousePos = (e: Event): Point => {
    const mouseX = (e as TouchEvent).changedTouches
      ? (e as TouchEvent).changedTouches[0].pageX
      : (e as MouseEvent).clientX;
    const mouseY = (e as TouchEvent).changedTouches
      ? (e as TouchEvent).changedTouches[0].pageY
      : (e as MouseEvent).clientY;

    return new Point(mouseX, mouseY);
  };

  private getTouchOrMousePressed = (
    e: MouseEvent | TouchEvent,
  ): MouseOrTouchPressed => {
    if (e instanceof MouseEvent) {
      switch ((e as MouseEvent).buttons) {
        case 1:
          return MouseOrTouchPressed.Left;
        case 2:
        case 3:
          return MouseOrTouchPressed.Right;
        default:
          return MouseOrTouchPressed.None;
      }
    } else {
      return (e as TouchEvent).touches.length > 0
        ? MouseOrTouchPressed.Left
        : MouseOrTouchPressed.None;
    }
  };

  private mousePosToCanvas(x: number, y: number): Point {
    let bcr = this.canvas.getBoundingClientRect();
    return new Point(x - bcr.left, y - bcr.top);
  }

  private doActionAt(p: Point): void {
    if (!this.currentBead) return;
    // current view action is set bead at(x, y)
    let pos: BeadPos = this.viewToModel(p.x, p.y);
    if (pos) {
      switch (this.editMode) {
        case EditMode.AddBead:
          this.setBeadAt(this.currentBead, pos);
          break;
        case EditMode.Fill:
          this.fillBeadAt(this.currentBead, pos);
          break;
        case EditMode.RemoveBead:
          this.setBeadAt(EMPTY_BEAD, pos);
          break;
        case EditMode.RemoveFill:
          this.fillBeadAt(EMPTY_BEAD, pos);
          break;
        case EditMode.PickBead:
          this.pickBeadAt(pos);
          break;
      }
    }
  }

  private pickBeadAt(pos: BeadPos) {
    let bead = this.model.getBeadAt(pos.row, pos.col);
    if (bead != EMPTY_BEAD) {
      this.currentBead = bead;
      this.onCurrentBeadChanged.emit(this.currentBead);
    }
  }

  private setBeadAt(bead: PaletteDetailModel, pos: BeadPos) {
    this.controller.setBeadAt(bead, pos);
  }

  private fillBeadAt(bead: PaletteDetailModel, pos: BeadPos) {
    var orgBead = this.model.getBeadAt(pos.row, pos.col);
    if (orgBead && bead && orgBead.color == bead.color) {
      console.log('new and old beads are equal!!!');
    } else {
      if (bead) {
        this.controller.fill(bead, pos);
      }
    }
  }

  private onMouseMove = (e: MouseEvent) => {
    const mousePos = this.getMousePos(e);
    let p: Point = this.mousePosToCanvas(mousePos.x, mousePos.y);

    if (this.actionMode == ActionMode.Nothing) {
      if (
        this.editMode === EditMode.AddBead ||
        this.editMode === EditMode.RemoveBead
      ) {
        this.freeDraw(e);
      }
    } else {
      this.updateDS(p);
    }

    this.selBead = this.viewToModel(p.x, p.y);
    this.paint();

    if (this.actionMode === ActionMode.Nothing) {
      const currentActionMode = this.getActionModePos(p);

      if (
        currentActionMode === ActionMode.DragBed ||
        currentActionMode === ActionMode.DragImage
      ) {
        this.canvasStyle = 'canvasstyle cursor-grab';
      } else if (currentActionMode === ActionMode.ScaleImage) {
        let r: Rect = this.getPatternImageRect();
        let handleTop: Rect = this.getPIHandleRect(r, PIHandle.Top);
        let handleBottom: Rect = this.getPIHandleRect(r, PIHandle.Bottom);
        let handleLeft: Rect = this.getPIHandleRect(r, PIHandle.Left);
        let handleRight: Rect = this.getPIHandleRect(r, PIHandle.Right);

        if (handleTop.contains(p) || handleBottom.contains(p)) {
          this.canvasStyle = 'canvasstyle cursor-ns-resize';
        } else if (handleLeft.contains(p) || handleRight.contains(p)) {
          this.canvasStyle = 'canvasstyle cursor-ew-resize';
        }
      }
    } else {
      if (
        this.actionMode === ActionMode.DragBed ||
        this.actionMode === ActionMode.DragImage
      ) {
        this.canvasStyle = 'canvasstyle cursor-grabbing';
      } else if (this.actionMode === ActionMode.ScaleImage) {
        let r: Rect = this.getPatternImageRect();
        let handleTop: Rect = this.getPIHandleRect(r, PIHandle.Top);
        let handleBottom: Rect = this.getPIHandleRect(r, PIHandle.Bottom);
        let handleLeft: Rect = this.getPIHandleRect(r, PIHandle.Left);
        let handleRight: Rect = this.getPIHandleRect(r, PIHandle.Right);

        if (handleTop.contains(p) || handleBottom.contains(p)) {
          this.canvasStyle = 'canvasstyle cursor-ns-resize';
        } else if (handleLeft.contains(p) || handleRight.contains(p)) {
          this.canvasStyle = 'canvasstyle cursor-ew-resize';
        }
      }
    }

    if (this.editMode !== EditMode.Pan) {
      e.preventDefault();
    }
  };

  private freeDraw(e: MouseEvent): boolean {
    const mousePos = this.getMousePos(e);
    let p: Point = this.mousePosToCanvas(mousePos.x, mousePos.y);
    const touchOrMousePressed = this.getTouchOrMousePressed(e);

    this.controller.startCompositeAction();
    let pos: BeadPos = this.viewToModel(p.x, p.y);

    let bead = this.currentBead;
    if (
      touchOrMousePressed === MouseOrTouchPressed.Right ||
      this.editMode == EditMode.RemoveBead
    ) {
      bead = EMPTY_BEAD;
    }

    if (
      pos &&
      (touchOrMousePressed === MouseOrTouchPressed.Left ||
        touchOrMousePressed === MouseOrTouchPressed.Right)
    ) {
      this.setBeadAt(bead, pos);
      this.drawBead(pos.row, pos.col);
      this.paint();
      return true;
    }

    return false;
  }

  private updateDS(p: Point): void {
    this.ds.x = p.x - this.mouseDownPoint.x;
    this.ds.y = p.y - this.mouseDownPoint.y;
  }

  public getZoomFactor(): number {
    return this.zoomFactor;
  }

  public zoom(newZoomFactor: number = 1): void {
    this.zoomFactor = Math.min(
      this.maxZoomFactor,
      Math.max(this.minZoomFactor, newZoomFactor),
    );
    this.recalcCanvasSize();
  }

  private recalcCanvasSize() {
    let mins = this.getMinCanvasSize();
    this.canvas.width = Math.max(
      this.orgCanvasSize.x * this.zoomFactor,
      mins.width,
    );
    this.canvas.height = Math.max(
      this.orgCanvasSize.y * this.zoomFactor,
      mins.height,
    );
  }

  private getMinCanvasSize(): Size {
    let bcr = this.canvas.getBoundingClientRect();
    let bs = this.getBeadSize();
    let max = Math.max(bs.width, bs.height);
    let mps = new Size(
      this.model.getCols() * max + 200,
      this.model.getRows() * max + 200,
    );
    let msize = new Size(
      Math.max(bcr.width, mps.width),
      Math.max(bcr.height, mps.height),
    );

    return msize;
  }

  public scrollCanvasContainerToPatternPosition() {
    const canvasContainer = this.canvas.parentElement;
    const canvasHalfMaxSize = 1000; //this.getCanvasSize().width;
    const pos = this.getModel().getPosition();
    const zoomFactor = this.getZoomFactor();
    const canvasContainerSize = new Size(
      canvasContainer.clientWidth,
      canvasContainer.clientHeight,
    );
    const patternSize = this.getPatternSize();

    const scrollToPos = new Point(
      (pos.x + patternSize.width / 2) * zoomFactor -
        canvasContainerSize.width / 2 +
        canvasHalfMaxSize,
      pos.y * zoomFactor - this.patternHandleHeight - 50,
    );

    canvasContainer.scrollTo({
      left: scrollToPos.x,
      top: scrollToPos.y,
    });
  }

  public zoomIn(): void {
    this.zoom(this.zoomFactor + 0.1);
    this.repaint();
    this.scrollCanvasContainerToPatternPosition();
  }

  public zoomOut(): void {
    this.zoom(this.zoomFactor - 0.1);
    this.repaint();
    this.scrollCanvasContainerToPatternPosition();
  }

  private fillRoundRect(
    c: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number,
  ) {
    this.roundRect(c, x, y, width, height, radius);
    c.fill();
  }

  private drawRoundRect(
    c: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number,
  ) {
    this.roundRect(c, x, y, width, height, radius);
    c.stroke();
  }

  private roundRect(
    c: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number,
  ) {
    if (width < 2 * radius) radius = width / 2;
    if (height < 2 * radius) radius = height / 2;
    c.beginPath();
    c.moveTo(x + radius, y);
    c.arcTo(x + width, y, x + width, y + height, radius);
    c.arcTo(x + width, y + height, x, y + height, radius);
    c.arcTo(x, y + height, x, y, radius);
    c.arcTo(x, y, x + width, y, radius);
    c.closePath();
  }

  public getModel(): PatternBeadModel {
    return this.model;
  }

  public canZoomIn(): boolean {
    return this.zoomFactor < this.maxZoomFactor;
  }

  public canZoomOut(): boolean {
    return this.zoomFactor > this.minZoomFactor;
  }

  public canShowRealisticBeads() {
    return true;
  }

  public canUndo(): boolean {
    return this.controller.canUndo();
  }

  public undo(): void {
    this.controller.undo();
    this.repaint();
  }

  public canRedo(): boolean {
    return this.controller.canRedo();
  }

  public redo(): void {
    this.controller.redo();
    this.repaint();
  }

  public getLastId(): number {
    if (this.controller != null) return this.controller.getLastId();
    else return 0;
  }

  public insertImage(patternImage): void {
    this.patternImage = patternImage;
    this.controller.insertImage(this.patternImage);
    this.repaint();
  }

  public getCanvasSize(): Size {
    return new Size(this.canvas.width, this.canvas.height);
  }

  private checkAndRecalculatePatternPosition(pb: PatternBead): Point {
    const canvasMaxSize = this.canvas.width;
    const halfCanvasMaxSize = this.canvas.width / 2;
    const pos = new Point(pb.posX, pb.posY).mult(this.zoomFactor);
    this.calcPCanvasSize();

    if (pos.x < -halfCanvasMaxSize) {
      pos.x = -halfCanvasMaxSize + 100;
    } else if (pos.x + this.pcanvas.width > halfCanvasMaxSize) {
      pos.x = halfCanvasMaxSize - this.pcanvas.width;
    }

    if (pos.y < this.patternHandleHeight) {
      pos.y = this.patternHandleHeight;
    } else if (
      pos.y + this.pcanvas.height + this.patternHandleHeight >
      halfCanvasMaxSize
    ) {
      pos.y = canvasMaxSize - this.pcanvas.height + this.patternHandleHeight;
    }

    pos.mult(1 / this.zoomFactor);

    return pos;
  }

  public setData(pb: PatternBead): void {
    this.model.setStitchId(pb.stitchId);
    this.model.setGrid(pb.grid);
    this.model.setBeadSize(new Size(pb.beadWidth, pb.beadHeight));
    this.model.setPatternImage(this.parsePatternImage(pb.image));
    this.model.setRotation(pb.rotation || 0);
    this.rotated = pb.rotation !== 0;

    const pos = this.checkAndRecalculatePatternPosition(pb);
    this.model.setPosition(new Point(pos.x, pos.y));
    this.controller.resetUndoRedo();
  }

  private parsePatternImage(pbgi: PatternBGImage): PatternImage {
    if (pbgi)
      return new PatternImage(
        this.patternImage,
        new Point(pbgi.posX, pbgi.posY),
        new Size(pbgi.width, pbgi.height),
        pbgi.visible,
        pbgi.rot,
      );

    return null;
  }

  public getData(): PatternBead {
    let bgimg: PatternBGImage = null;

    if (this.model.getPatternImage())
      bgimg = <PatternBGImage>{
        posX: this.model.getPatternImage().pos.x,
        posY: this.model.getPatternImage().pos.y,
        width: this.model.getPatternImage().size.width,
        height: this.model.getPatternImage().size.height,
        visible: this.model.getPatternImage().visible,
        rot: this.model.getPatternImage().rot,
      };

    return <PatternBead>{
      stitchId: this.model.getStitchId(),
      posX: this.model.getPosition().x,
      posY: this.model.getPosition().y,
      grid: this.model.getGrid(),
      beadWidth: this.model.getBeadSize().width,
      beadHeight: this.model.getBeadSize().height,
      image: bgimg,
      rotation: this.model.getRotation(),
    };
  }

  public getRows(): number {
    return this.model.getRows();
  }

  public getCols(): number {
    return this.model.getCols();
  }

  public setRows(r: number): void {
    if (r > this.model.getRows())
      this.controller.addRows(r - this.model.getRows());
    else if (r < this.model.getRows())
      this.controller.removeRows(this.model.getRows() - r);

    this.recalcCanvasSize();
    this.repaint();
  }

  public setCols(c: number): void {
    if (c > this.model.getCols())
      this.controller.addCols(c - this.model.getCols());
    else if (c < this.model.getCols())
      this.controller.removeCols(this.model.getCols() - c);

    this.recalcCanvasSize();
    this.repaint();
  }

  public getEditMode(): EditMode {
    return this.editMode;
  }

  public setEditMode(editMode: EditMode) {
    this.editMode = editMode;
  }

  public getStitchId(): number {
    return this.model.getStitchId();
  }

  public rotateImageLeft() {
    this.controller.rotateImage(RotateDirectionEnum.Left);
    this.repaint();
  }

  public rotateImageRight() {
    this.controller.rotateImage(RotateDirectionEnum.Right);
    this.repaint();
  }

  public isRotatedPattern(): boolean {
    return this.rotated;
  }

  public rotatedPattern(rotated: boolean) {
    this.rotated = rotated;
    this.controller.rotatedPattern(rotated);
    this.repaint();
  }
}

enum ActionMode {
  Nothing,
  DragBed,
  DragImage,
  ScaleImage,
}

enum PIHandle {
  Top,
  Bottom,
  Left,
  Right,
}

export enum EditMode {
  AddBead,
  Fill,
  RemoveBead,
  RemoveFill,
  PickBead,
  Pan,
}

export enum ShiftTypeEnum {
  Left,
  Right,
  Up,
  Down,
}

export enum FlipDirectionEnum {
  Vertical,
  Horizontal,
}

export enum MouseOrTouchPressed {
  None,
  Left,
  Right,
}

<div
  mat-dialog-title
  class="modal-title"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <span class="modal-title-label"
    >{{ httpService.getTransation().PrintPattern }}</span
  >
  <button
    class="modal-close-button"
    mat-icon-button
    matTooltip="{{ httpService.getTransation().Close }}"
    (click)="onClose()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="loading-indicator" *ngIf="pleaseWaitPdf || pleaseWaitLoad">
  <mat-progress-spinner
    mode="indeterminate"
    color="primary"
  ></mat-progress-spinner>
</div>
<div>
  <div
    class="print-content-container"
    [ngStyle]="{ 'width' : data.printContentWidth.toString() + 'px', 'height' : data.printContentHeight.toString() + 'px' }"
  >
    <div *ngIf="!pleaseWaitLoad" id="printContent" class="print-content">
      <h5>{{ httpService.getTransation().BeadsPalette }}</h5>
      <div id="legendaContent" class="legenda-flex-container">
        <div *ngFor="let bc of beadsCount" class="legenda-grid-row">
          <div
            [class]="getBeadClasses(bc.beadPaletteTypeId, '')"
            [ngStyle]="{'background-color': '#' + bc.beadColor}"
          ></div>
          <div class="text-center">
            {{ httpService.getTransation().BeadNumber }}: <b>{{ bc.letter}}</b>
          </div>
          <div class="text-center"><b>{{ bc.beadName}}</b></div>
          <div class="text-center">
            {{ httpService.getTransation().Count }}: <b>{{ bc.count}}</b>
          </div>
        </div>
      </div>
      <div id="beadsGridContent" style="display: flex; flex-direction: column">
        <div *ngIf="isChunked()" style="text-align: center; font-weight: bold">
          Pattern page: {{page}} of {{pages}} (row: {{chunkedBeadsGrid[page -
          1].startRow+1}} - {{chunkedBeadsGrid[page-1].endRow+1}}, column:
          {{chunkedBeadsGrid[page - 1].startColumn+1}} -
          {{chunkedBeadsGrid[page-1].endColumn+1}})
        </div>
        <div
          #beadGridContainer
          class="bead-grid-container"
          *ngIf="showPatternPreview"
        >
          <div
            *ngFor="let r of chunkedBeadsGrid[page - 1].details; index as rowIndex"
            [ngStyle]="{'margin-left': getRowLeftMargin(rowIndex) + 'px;'}"
            [class]="getBeadGridRowClass()"
          >
            <div
              *ngFor="let c of r; index as colIndex"
              [class]="getBeadGridCellClass()"
              [ngStyle]="getBeadGridCellStyle(colIndex, c)"
            >
              {{ getBeadLetter(page - 1, rowIndex, colIndex) }}
            </div>
          </div>
        </div>
      </div>

      <div
        id="wordChartContent"
        class="bead-grid-list-container"
        *ngIf="showWordChart"
      >
        <h5 class="word-chart-title">
          {{ httpService.getTransation().WordChart }}
        </h5>
        <table>
          <tr
            *ngFor="let bgRow of beadsGridList; index as rowIndex"
            class="bead-grid-list-item"
          >
            <td class="bead-grid-list-item-row-header">
              <i>{{ bgRow.rowHeader }}</i>
            </td>
            <td class="bead-grid-list-item-row-cells">
              <div fxLayout="row wrap">
                <div
                  class="bead-grid-list-item-row-cell"
                  *ngFor="let bgCell of bgRow.cells"
                >
                  <b>{{ bgCell.letter}}</b
                  ><small>({{ bgCell.count.toString() }})</small>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <div
    style="
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    "
  >
    <div *ngIf="!isChunked()"></div>
    <div
      *ngIf="isChunked()"
      style="display: flex; flex-direction: row; align-items: center"
    >
      <button
        mat-raised-button
        color="primary"
        class="modal-button"
        (click)="onPreviousPage()"
        style="width: 80px"
      >
        {{ httpService.getTransation().Previous }}
      </button>
      <div>{{httpService.getTransation().Page}} {{page}} of {{pages}}</div>
      <button
        mat-raised-button
        color="primary"
        class="modal-button"
        (click)="onNextPage()"
        style="width: 80px"
      >
        {{ httpService.getTransation().Next }}
      </button>
    </div>
    <div style="display: flex; flex-direction: row; align-items: center">
      <mat-slide-toggle [(ngModel)]="showWordChart" [checked]="true">
        Include word chart
      </mat-slide-toggle>
      <button
        mat-raised-button
        color="warn"
        class="modal-button"
        (click)="onExportPDF()"
      >
        {{ httpService.getTransation().ExportPDF }}
      </button>
    </div>
  </div>
</div>
<div
  mat-dialog-title
  class="modal-title"
  cdkDrag
  cdkDragRootElement=".cdk-overlay-pane"
  cdkDragHandle
>
  <span class="modal-title-label"
    >{{ httpService.getTransation().Profile }}</span
  >
  <button
    class="modal-close-button"
    mat-icon-button
    matTooltip="{{ httpService.getTransation().Close }}"
    (click)="onClose()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <label id="unit-of-measure-label"
    >{{ httpService.getTransation().UnitOfMeasure }}</label
  >
  <mat-radio-group
    aria-labelledby="unit-of-measure-label"
    class="profile-radio-group"
    [(ngModel)]="unitOfMeasure"
  >
    <mat-radio-button class="profile-radio-button" [value]="unitOfMeasures.Inch"
      >{{ httpService.getTransation().Inch }}
    </mat-radio-button>
    <mat-radio-button class="profile-radio-button" [value]="unitOfMeasures.Cm"
      >{{ httpService.getTransation().Cm }}
    </mat-radio-button>
    <mat-radio-button class="profile-radio-button" [value]="unitOfMeasures.Mm"
      >{{ httpService.getTransation().Mm }}
    </mat-radio-button>
  </mat-radio-group>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="onSave()">
    {{ httpService.getTransation().Save }}
  </button>
  <button mat-raised-button cdkFocusInitial (click)="onClose()">
    {{ httpService.getTransation().Close }}
  </button>
</div>

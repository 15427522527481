import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http-service';
import { HomeComponent } from 'src/app/home/home.component';
import { DOCUMENT } from '@angular/common';

export interface ChangePlanDoneData {
  homeComponent: HomeComponent;
}

export interface ChangePlanDoneResult {}

@Component({
  selector: 'change-plan-done-modal',
  templateUrl: 'change-plan-done-modal.html',
})
export class ChangePlanDoneModal implements OnInit {
  constructor(
    public httpService: HttpService,
    public dialogRef: MatDialogRef<ChangePlanDoneModal>,
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: ChangePlanDoneData
  ) {}

  async ngOnInit() {
    gtag('event', 'page_view', {
      page_title: 'Change Plan Done (Modal)',
      page_location: `${window.location.origin}/change-plan-done-modal`,
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }
}

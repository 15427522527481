<div class="loading-indicator" *ngIf="pleaseWait">
  <mat-progress-spinner
    mode="indeterminate"
    color="primary"
  ></mat-progress-spinner>
</div>

<app-toolbar
  #appToolbar
  [windowWidth]="windowWidth"
  [windowHeight]="windowHeight"
  [bcanvas]="bcanvas"
  (toolbarActionFired)="onToolbarActionFired($event)"
  class="layout-max-width main-toolbar"
  data-testid="main-toolbar"
>
</app-toolbar>

<div
  style="position: fixed; z-index: 2; padding-left: 0px"
  [style.left]="smallToolbarLeft + 'px'"
  [style.top]="smallToolbarTop + 'px'"
>
  <mat-button-toggle-group
    #smallToolbar
    [value]="selectedEditMode"
    vertical="true"
    (change)="onSelectedEditModeChanged($event)"
    style="margin-left: 8px; position: fixed"
    [style.flexDirection]="windowWidth > minSideToolbarWidth ? 'column' : 'row'"
    data-testid="small-toolbar"
    class="small-toolbar"
  >
    <mat-button-toggle
      value="add-bead"
      checked
      matTooltip="{{ httpService.getTransation().AddBead }}"
    >
      <mat-icon>edit</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle
      value="fill"
      matTooltip="{{ httpService.getTransation().Fill }}"
    >
      <mat-icon>format_paint</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle
      value="remove-bead"
      matTooltip="{{ httpService.getTransation().RemoveBead }}"
    >
      <mat-icon>clear</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle
      value="remove-fill"
      matTooltip="{{ httpService.getTransation().RemoveFill }}"
    >
      <mat-icon>format_clear</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle
      value="pick-bead"
      matTooltip="{{ httpService.getTransation().PickBead }}"
    >
      <mat-icon>colorize</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle
      *ngIf="hasTouchScreen"
      value="pan"
      matTooltip="{{ httpService.getTransation().Pan }}"
    >
      <mat-icon>pan_tool</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
</div>

<mat-sidenav-container>
  <mat-sidenav-content layout-fill style="overflow: hidden">
    <app-bead-canvas
      #beadCanvasComponent
      [home]="this"
      [bcanvas]="bcanvas"
      (onActionFired)="onActionFired($event)"
    ></app-bead-canvas>
  </mat-sidenav-content>
  <mat-sidenav
    #rightSidebar
    mode="side"
    position="end"
    [opened]="rightSidebarOpen"
    style="width: 321px"
    class="sidebar"
  >
    <mat-tab-group [(selectedIndex)]="selectedTabIndex">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="sidebar-icon">texture</mat-icon>
          <span class="sidebar-title">{{
            httpService.getTransation().Pattern
          }}</span>
        </ng-template>
        <div
          style="
            display: flex;
            flex-direction: column;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 8px;
          "
        >
          <div fxFlexFill>
            <mat-form-field fxFlex="40" style="padding-right: 8px">
              <mat-select
                [placeholder]="httpService.getTransation().Stitch"
                [value]="getStitch()"
                (selectionChange)="onStitchSelected($event.value)"
              >
                <mat-option *ngFor="let s of stitchs" [value]="s">{{
                  s.name
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="30" style="padding-right: 8px">
              <input
                matInput
                [placeholder]="httpService.getTransation().Columns"
                type="number"
                min="5"
                max="500"
                [value]="bcanvas.getCols()"
                (change)="onColsChange($event)"
              />
              <mat-hint align="end">{{ getColumnsSize() }}</mat-hint>
            </mat-form-field>
            <mat-form-field fxFlex="30">
              <input
                matInput
                [placeholder]="httpService.getTransation().Rows"
                type="number"
                min="5"
                max="500"
                [value]="bcanvas.getRows()"
                (change)="onRowsChange($event)"
              />
              <mat-hint align="end">{{ getRowsSize() }}</mat-hint>
            </mat-form-field>
          </div>
          <app-pattern-palettes-list
            #patternPaletteList
            [bcanvas]="bcanvas"
            (selectedPaletteDetailChanged)="
              selectedPaletteDetailChanged($event)
            "
            [height]="patternListHeight"
          >
          </app-pattern-palettes-list>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="sidebar-icon">palette</mat-icon>
          <span class="sidebar-title">{{
            httpService.getTransation().Palettes
          }}</span>
        </ng-template>
        <app-palettes-list
          #paletteList
          (selectedPaletteDetailChanged)="selectedPaletteDetailChanged($event)"
          (onPaletteDetailsLoaded)="onPaletteDetailsLoaded()"
          [height]="paletteListHeight"
          [includePadding]="true"
          [includeBuiltIn]="true"
          [includeCustom]="true"
        >
        </app-palettes-list>
      </mat-tab>
    </mat-tab-group>
  </mat-sidenav>
</mat-sidenav-container>

<div mat-dialog-title class="modal-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <span class="modal-title-label">{{ httpService.getTransation().ChangePlan }}</span>
  <button class="modal-close-button" mat-icon-button matTooltip="{{ httpService.getTransation().Close }}"
    (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content style="width: 500px;">
  <div>{{ httpService.getTransation().ChangePlanDone }}</div>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="primary" [mat-dialog-close]="data">{{ httpService.getTransation().Ok }}</button>
</div>
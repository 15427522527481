import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { PaletteDetailModel } from 'src/app/models/palettes/palette_detail_model';
import { HttpService } from 'src/app/services/http-service';

export interface ReplaceBeadModalData {
  paletteListHeight: number;

}

@Component({
  selector: 'replace-bead-modal',
  templateUrl: 'replace-bead-modal.html',
})
export class ReplaceBeadModal implements OnInit {
  paletteListHeight: number;
  selectedPaletteDetail: PaletteDetailModel;

  constructor(
    public httpService: HttpService,
    @Inject(DOCUMENT) private document: Document,
    public dialogRef: MatDialogRef<ReplaceBeadModal>,
    @Inject(MAT_DIALOG_DATA) public data: ReplaceBeadModalData
  ) {
    this.paletteListHeight = data.paletteListHeight;
  }

  async ngOnInit() {
    gtag('event', 'page_view', {
      page_title: 'Replace Bead (Modal)',
      page_location: `${window.location.origin}/replace-bead-modal`,
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  selectedPaletteDetailChanged(paletteDetail: PaletteDetailModel) {
    this.selectedPaletteDetail = paletteDetail;
  }
}

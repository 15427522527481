import { Component, Inject, OnInit } from '@angular/core';
import { HttpService } from '../services/http-service';
import { MatDialog } from '@angular/material/dialog';
import { LoginModal } from '../modals/login-modal/login-modal';
import { SocialAuthService } from 'angularx-social-login';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-login-home',
  templateUrl: './login-home.component.html',
  styleUrls: ['./login-home.component.css'],
})
export class LoginHomeComponent implements OnInit {
  constructor(
    private titleService: Title,
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private router: Router,
    public httpService: HttpService,
    public dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.titleService.setTitle('Login');

    gtag('event', 'page_view', {
      page_title: 'Login',
      page_location: `${window.location.origin}/login`,
    });

    if (environment.production) {
      this.login();
    } else {
      const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
      this.router.navigateByUrl(returnUrl);
    }
  }

  login() {
    const dialogRef = this.dialog.open(LoginModal, {
      minWidth: '300px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(async (result) => {});
  }
}
